/* eslint-disable */

window.artprice_app.controller('MarketplaceShowCtrl', [
  '$http',
  '$rootScope',
  '$scope',
  '$timeout',
  '$sessionStorage',
  'Classified',
  'Classifiedoffer',
  'Currency',
  '$artpHttpErrorHandler',
  'Customer',
  function ($http, $rootScope, $scope, $timeout, $sessionStorage, Classified, Classifiedoffer, Currency, $artpHttpErrorHandler, Customer) {
    $scope.display_shipping_mode;
    $scope.show_button = true;

    $scope.initShow = function (hb) {
      if (parseInt(hb, 10) === 1) {
        return ($scope.show_button = false);
      }
    };

    $scope.retrieve = function (helper) {
      if (!$scope.retrieved) {
        $scope.retrieved = {};
      }
      if (!$scope.retrieved[helper]) {
        $scope.retrieved[helper] = [];
        Classified.retrieve({ helper }, data => angular.extend($scope.retrieved[helper], data), $artpHttpErrorHandler);
      }
      return $scope.retrieved[helper];
    };

    $scope.selectCurrency = function (currency) {
      $scope.currency = currency;
      return Customer.update_preferred_currency({ currency });
    };

    $scope.selectUniteTo = function (unite) {
      if (unite === 'cm' || unite === 'in') {
        $scope.uniteTo = unite;
        return Customer.update_preferred_dimensions_unit({ dimension_unit: unite });
      }
    };

    $scope.isShippingPriceValid = shippingPrice => Number.isNaN(parseFloat(shippingPrice, 10));

    $scope.accept_sellers_cgv = () => $http.get('/marketplace/');

    $scope.moneyChangeCurrency = function (currency, direction) {
      if (direction === 'from') {
        return ($scope.currencyFrom = currency);
      } else {
        return ($scope.currencyTo = currency);
      }
    };

    $scope.moneyChange = (amount, from, to) => Currency.moneyChange({ from, to, amount }, data => ($scope.moneyChanged = data.result), $artpHttpErrorHandler);

    $scope.toggle_all_customer_offers = function (id) {
      if (!$scope.display_offers_for_customer) {
        $scope.display_offers_for_customer = {};
      }
      if ($scope.display_offers_for_customer[id]) {
        return delete $scope.display_offers_for_customer[id];
      } else {
        return ($scope.display_offers_for_customer[id] = true);
      }
    };

    $scope.init_offer_for_customer = function (idclassified) {
      $scope.offer_for_customer = { loading: true };
      return Classifiedoffer.offer_for_customer(
        { id: idclassified },
        function (data) {
          angular.extend($scope.offer_for_customer, data);
          return ($scope.offer_for_customer.loading = false);
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.initializeFormValidator = () =>
      $timeout(function () {
        $scope.form_offer['checkcgv'].$validators.cgvNotChecked = (modelValue, viewValue) => viewValue === true;
        return ($scope.form_offer.price_offer.$validators.offerLessThanCurrentOffer = (modelValue, viewValue) => parseInt(viewValue) > parseInt($scope.offer_for_customer.current_price));
      });

    $scope.make_offer = function (idclassified, event, confirm_message, str_currency) {
      $scope.form_offer.$setSubmitted();
      if ($scope.form_offer.$valid && (!$scope.form_offer.checkcgv || $scope.form_offer.checkcgv.$viewValue) && confirm(confirm_message + ' ' + $scope.price_offer + ' ' + str_currency)) {
        $scope.offer_for_customer = { loading: true };
        Classifiedoffer.make_offer(
          { id: idclassified, price_offer: $scope.price_offer },
          function (data) {
            angular.extend($scope.offer_for_customer, data);
            $scope.offer_for_customer.loading = false;
            $scope.show_button = true;
            return $scope.addAlert($scope.offer_for_customer.message);
          },
          function (data) {
            $scope.show_button = true;
            return $scope.addAlert({ msg: data, type: 'danger' });
          },
          $artpHttpErrorHandler,
        );
      }

      return event.preventDefault();
    };
  },
]);
