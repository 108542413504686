import angular from 'angular';

angular.module('artprice_app').factory('Estimate', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/estimate/:action/:id/',
      {
        method: 'get',
        format: 'json',
      },
      {
        show: {
          method: 'get',
          params: { action: 'show' },
        },
        retrieve: {
          method: 'GET',
          isArray: true,
          params: { action: 'retrieve' },
        },
      },
    ),
]);
