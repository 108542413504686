import angular from 'angular';

angular.module('common_app').factory('Currency', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/currency/:action/:id/',
      {
        method: 'get',
        format: 'json',
      },
      {
        moneyChange: {
          method: 'get',
          params: { action: 'money_change' },
        },
      },
    ),
]);
