/* eslint-disable */
import angular from 'angular';

angular.module('common_app').directive('validateField', () => ({
  replace: true,
  transclude: true,

  template: `<span class=\"form-control-artprice label label-danger\" ng-show=\"form[idInput].$invalid  && (!form[idInput].$pristine || form.$submitted)\"> \
<span ng-if=\"form[idInput].$error.email\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.max && !max\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.max && max\" ng-bind=\"'javascripts.locales.m28' | i18next | strReplace:'%count%':max\"></span> \
<span ng-if=\"form[idInput].$error.min && !min\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.min && min\" ng-bind=\"'javascripts.locales.m29' | i18next | strReplace:'%count%':min\"></span> \
<span ng-if=\"form[idInput].$error.minlength\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.number\" ng-bind=\"('javascripts.locales.m6' | i18next) + ('javascripts.locales.m26' | i18next)\"></span> \
<span ng-if=\"form[idInput].$error.pattern\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.required\" ng-bind=\"'javascripts.locales.m7' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.url\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.date\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.datetimelocal\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.time\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.week\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.month\" ng-bind=\"'javascripts.locales.m6' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.copiesForbidden\" ng-bind=\"'javascripts.locales.m10' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.notSignedHandForbidden\" ng-bind=\"'javascripts.locales.m11' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.notSignedStampsForbidden\" ng-bind=\"'javascripts.locales.m11' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.notSignedForbidden\" ng-bind=\"'javascripts.locales.m24' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.frameSizeBiggerThanImageSize\" ng-bind=\"'javascripts.locales.m12' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.cgvNotChecked\" ng-bind=\"'javascripts.locales.m13' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.textTooLong\" ng-bind=\"'javascripts.locales.m14' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.maxlength\" ng-bind=\"'javascripts.locales.m14' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.startAuctionPriceUsd\" ng-bind=\"'javascripts.locales.m16' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.startAuctionPriceEur\" ng-bind=\"'javascripts.locales.m15' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.startAuctionPriceGbp\" ng-bind=\"'javascripts.locales.m17' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.weightGreaterThanZero\" ng-bind=\"'javascripts.locales.m18' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.offerLessThanCurrentOffer\" ng-bind=\"'javascripts.locales.m21' | i18next | strReplace:'%currentOffer%':replaceBy\"></span> \
<span ng-if=\"form[idInput].$error.usernameAvailable\" ng-bind=\"'javascripts.locales.m25' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.dateDisabled\" ng-bind=\"'javascripts.locales.m27' | i18next\"></span> \
<span ng-if=\"form[idInput].$error.passwordMismatch\" ng-bind=\"'api.wallet.customer_controller.m3' | i18next\"></span> \
</span>`,

  scope: {
    form: '=',
    idInput: '@',
    replaceBy: '@',
    min: '=',
    max: '=',
  },
}));
