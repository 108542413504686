/* eslint-disable */

window.artprice_app.controller('MarketplaceListCtrl', [
  '$http',
  '$scope',
  ($http, $scope) =>
    ($scope.deleteClassified = function (idclassified, confirmationMessage, redirectTo) {
      if (confirm(confirmationMessage)) {
        return $http.get('/api/wallet/classified/delete/' + idclassified).then(function (response) {
          if (response.data.status === 'Ok') {
            if (redirectTo) {
              return (window.location = redirectTo);
            } else {
              return window.location.reload();
            }
          }
        });
      }
    }),
]);
