import uiRouter from 'angular-ui-router';
import angular from 'angular';
import 'i18next';
import ngI18next from 'ng-i18next';
import angularCache from 'angular-cache';
import 'textangular';

import baseApplication from './baseApplication';

/* eslint-disable angular/window-service, angular/module-setter */
angular.module('shopApplication', [
  'ngResource',
  'ngStorage',
  'ngAnimate',
  'ngCookies',
  'angularFileUpload',
  'textAngular',
  'ui.bootstrap.carousel',
  'common_app',
  uiRouter,
  baseApplication,
  ngI18next,
  angularCache,
]);

require('../components/artmarketinsights');
require('../components/inputs');
require('../components/lots');
require('../components/reports');
require('../components/search');
