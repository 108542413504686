import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($document, $element, $timeout, $sce) {
    this.$document = $document;
    this.visible = false;
    this.localCss = localCss;
    this.$sce = $sce;

    this.handleDocumentClick = event => {
      if (this.visible) {
        const isClickedElementChildOfPopup = $element.find(event.target).length > 0;
        if (isClickedElementChildOfPopup) {
          return;
        }
        $timeout(this.ngClickOutside);
      } else {
        // si la dropdown n'est pas visible lors du click c'est un "bug" (qui provient du fait qu'on peut modifier le model visible depuis le parent sans passer par toggleDropdown)
        this.$document.unbind('click', this.handleDocumentClick);
      }
    };
  }

  $onInit() {
    this.trustedLabelXs = this.$sce.trustAsHtml(this.labelXs);
    this.trustedLabel = this.$sce.trustAsHtml(this.label);
  }

  $onChanges(changes) {
    if (changes.visible) {
      if (this.visible) {
        this.$document.bind('click', this.handleDocumentClick);
      } else {
        this.$document.unbind('click', this.handleDocumentClick);
      }
    }
  }
}

const Dropdown = {
  template,
  controller: ['$document', '$element', '$timeout', '$sce', Ctrl],
  transclude: true,
  bindings: {
    label: '@',
    labelXs: '@',
    side: '@',
    ngClickTitle: '&',
    ngClickOutside: '&',
    visible: '<?',
  },
};

angular.module('common_app').component('artpInputsDropdown', Dropdown);
