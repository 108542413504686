/* eslint-disable */

import xRequestIdManager from '../../../../javascript/utils/xRequestIdManager';

angular.module('common_app').config([
  '$httpProvider',
  function ($httpProvider) {
    if (!xRequestIdManager.isEmpty()) {
      $httpProvider.defaults.headers.common['X-Trace-Request-Id'] = xRequestIdManager.get();
    }
  },
]);
