import angular from 'angular';
import { each, some, reduce } from 'lodash';

angular.module('common_app').factory('Physicproduct', [
  '$window',
  '$resource',
  '$q',
  ($window, $resource, $q) => {
    const cart = {
      items: null,
      realItems: {},
      totalItems() {
        return reduce(cart.items, (memo, item) => memo + item.quantity, 0);
      },
      totalPrices() {
        return reduce(
          cart.realItems,
          (memo, item) => ({
            eur: memo.eur + item.prices.eur * item.quantity,
            usd: memo.usd + item.prices.usd * item.quantity,
          }),
          { eur: 0, usd: 0 },
        );
      },
      hasItem(item) {
        // eslint-disable-next-line angular/definedundefined
        return cart.items[item.id] !== undefined;
      },
    };

    const physicproduct = $resource(
      '/api/wallet/physicproduct/:action/:id/',
      {
        method: 'get',
        format: 'json',
      },
      {
        cart: {
          params: { action: 'cart' },
        },
        list: {
          method: 'get',
          params: { action: 'list' },
        },
        select_platform: {
          method: 'get',
          params: { action: 'select_platform' },
        },
        show: {
          method: 'get',
          params: { action: 'show' },
        },
        listEstimates: {
          method: 'get',
          params: { action: 'list_estimates' },
        },
        platforms: {
          method: 'get',
          params: { action: 'platforms' },
        },
        shop: {
          method: 'get',
          params: { action: 'shop' },
          isArray: true,
        },
      },
    );

    physicproduct.computeCart = function (newCartItems) {
      if (Object.keys(newCartItems).length > 0) {
        const params = { type: null, promocode: null, image: null };
        each(newCartItems, item => (params[`idphysicproducts[${item.id}]`] = item.quantity));
        return physicproduct.cart(params, data => {
          const realItems = {};
          each(
            data.main_products,
            product =>
              (realItems[product.product.id] = {
                id: product.product.id,
                title: product.product.title,
                prices: product.product.prices,
                quantity: product.product_quantity,
                shipping: some(product.component_products, component_product => component_product.type === 'shipping'),
              }),
          );
          // delete
          each(cart.realItems, (value, key) => {
            if (!realItems[key]) {
              return delete cart.realItems[key];
            }
          });
          // add / update
          each(realItems, (value, key) => (cart.realItems[key] = realItems[key]));
          cart.items = newCartItems;
          return $window.sessionStorage.setItem('cart', angular.toJson(cart.items));
        }).$promise;
      }
      return $q((resolve, _reject) => {
        each(cart.realItems, (value, key) => delete cart.realItems[key]);
        cart.items = newCartItems;
        $window.sessionStorage.setItem('cart', angular.toJson(cart.items));
        return resolve({ $resolved: true, main_products: [] });
      });
    };

    physicproduct.getCart = function () {
      if (cart.items === null) {
        const sessionStorageCart = $window.sessionStorage.getItem('cart');
        if (!sessionStorageCart) {
          cart.items = {};
        } else {
          cart.items = angular.fromJson(sessionStorageCart);
          physicproduct.computeCart(cart.items);
        }
      }
      return cart;
    };

    physicproduct.addToCart = function (item) {
      if (cart === null) {
        physicproduct.getCart();
      }

      cart.loading = item.id;
      const newCartItems = angular.merge({}, cart.items);
      // eslint-disable-next-line angular/definedundefined
      if (newCartItems[item.id] === undefined) {
        // eslint-disable-next-line angular/no-private-call
        newCartItems[item.id] = { id: item.id, title: item.title.$$unwrapTrustedValue(), quantity: 1, prices: angular.extend({}, item.prices) };
      } else {
        newCartItems[item.id].quantity += 1;
      }
      return physicproduct.computeCart(newCartItems);
    };

    physicproduct.removeFromCart = function (item) {
      if (cart === null) {
        physicproduct.getCart();
      }

      const newCartItems = angular.merge({}, cart.items);
      // eslint-disable-next-line angular/definedundefined
      if (newCartItems[item.id] !== undefined) {
        if (newCartItems[item.id].quantity > 1) {
          newCartItems[item.id].quantity -= 1;
        } else {
          newCartItems[item.id] = null;
          delete newCartItems[item.id];
        }
      }
      return physicproduct.computeCart(newCartItems);
    };

    return physicproduct;
  },
]);
