/* eslint-disable */

import i18next from 'i18next';
import * as Sentry from '@sentry/react';
import { noop } from 'lodash';
import { addMinutes, addMonths, addDays, isBefore, isAfter } from 'date-fns';

window.artprice_app.controller('MarketplaceFormCtrl', [
  '$sce',
  '$http',
  '$rootScope',
  '$scope',
  '$filter',
  '$timeout',
  '$localStorage',
  '$anchorScroll',
  'Classified',
  '$sanitize',
  '$sessionStorage',
  '$window',
  '$artpHttpErrorHandler',
  function ($sce, $http, $rootScope, $scope, $filter, $timeout, $localStorage, $anchorScroll, Classified, $sanitize, $sessionStorage, $window, $artpHttpErrorHandler) {
    const vm = this;

    $scope.addDays = (dt, nb) => addDays(new Date(dt), nb);

    $scope.stepCount = 3;
    $scope.currentStep = 1;
    $scope.loading = false;
    $scope.max_images = 5;
    $scope.max_sortorder = 0;
    $scope.image_to_retouch;
    $scope.classified = {};
    $scope.classifiedSkeleton = {
      images: [],
      idclassifiedshipmentmode: 2,
      idclassifiedclass: 1,
      idclassifiedauctionmode: 2,
      idclassifieddimensionunit: 1,
      idcurrency: 48,
      idestimatecurrency: 48,
      cost_of_transport_idcurrency: 48,
      idclassifieddimensionunitwithframe: 1,
      idclassifiedweightunit: 1,
    };
    $scope.toolbarOption = [
      ['bold', 'italics', 'underline'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
    ];
    $scope.isCatalogSaleOptions = [];

    $scope.retrieveCurrenciesWithoutCrypto = function () {
      if (!$scope.retrieved) {
        $scope.retrieved = {};
      }
      if (!$scope.retrieved['currency_without_crypto_to_json']) {
        $scope.retrieved['currency_without_crypto_to_json'] = [];
        Classified.retrieve(
          { helper: 'currency_to_json' },
          data => {
            angular.extend(
              $scope.retrieved['currency_without_crypto_to_json'],
              data.filter(c => ![176, 178].includes(parseInt(c.id, 10))),
            );
          },
          $artpHttpErrorHandler,
        );
      }
      return $scope.retrieved['currency_without_crypto_to_json'];
    };

    $scope.retrieve = function (helper) {
      if (!$scope.retrieved) {
        $scope.retrieved = {};
      }
      if (!$scope.retrieved[helper]) {
        $scope.retrieved[helper] = [];
        Classified.retrieve({ helper }, data => angular.extend($scope.retrieved[helper], data), $artpHttpErrorHandler);
      }
      return $scope.retrieved[helper];
    };

    $scope.estimateThisArtwork = function () {
      const estimate = {
        title: $scope.classified.title,
        idcategory: $scope.classified.idcategory,
        idtechnique: $scope.classified.idtechnique,
        idsignatureposition: $scope.classified.idsignatureposition,
        height: $scope.classified.height,
        width: $scope.classified.width,
        length: $scope.classified.length,
        year: $scope.classified.year,
        images: $scope.classified.images,
        hascertif: $scope.classified.hascertif,
        comment: $scope.classified.comment,
      };
      $sessionStorage.estimate = angular.extend({}, estimate);
      if ($scope.classified.artist) {
        $window.open(`/estimate/new?init_from_storage=true&estimate[idartist]=${$scope.classified.artist.id}`, '_blank');
      } else {
        if ($scope.classified.artist) {
          $window.open('/estimate/new?init_from_storage=true', '_blank');
        }
      }
    };

    $scope.toggleHelp = section => ($scope.help = section);

    const initValidators = function () {
      $scope.form_classified['classified[comment]'].$validators.textTooLong = (modelValue, viewValue) => String(viewValue).replace(/<[^>]+>/gm, '').length <= 1000;

      $scope.form_classified['checkcgv'].$validators.cgvNotChecked = (modelValue, viewValue) => viewValue === true;

      $scope.form_classified['classified[price]'].$validators.startAuctionPriceEur = function (modelValue, viewValue) {
        if (parseInt($scope.form_classified['classified[idclassifiedauctionmode]'].$viewValue) === 1 && parseInt($scope.classified.idcurrency) === 48 && parseInt(viewValue) < 100) {
          return false;
        }
        return true;
      };

      $scope.form_classified['classified[price]'].$validators.startAuctionPriceUsd = function (modelValue, viewValue) {
        if (parseInt($scope.form_classified['classified[idclassifiedauctionmode]'].$viewValue) === 1 && parseInt($scope.classified.idcurrency) === 154 && parseInt(viewValue) < 100) {
          return false;
        }
        return true;
      };

      $scope.form_classified['classified[price]'].$validators.startAuctionPriceGbp = function (modelValue, viewValue) {
        if (parseInt($scope.form_classified['classified[idclassifiedauctionmode]'].$viewValue) === 1 && parseInt($scope.classified.idcurrency) === 52 && parseInt(viewValue) < 100) {
          return false;
        }
        return true;
      };

      $scope.form_classified['classified[idclassifiedoriginaltype]'].$validators.copiesForbidden = function (modelValue, viewValue) {
        if (viewValue === 3) {
          return false;
        }
        return true;
      };

      $scope.form_classified['classified[idsignatureposition]'].$validators.notSignedHandForbidden = function (modelValue, viewValue) {
        if (parseInt(viewValue) === 7) {
          return false;
        }
        return true;
      };

      $scope.form_classified['classified[idsignatureposition]'].$validators.notSignedStampsForbidden = function (modelValue, viewValue) {
        if (parseInt(viewValue) === 0 && $scope.form_classified['classified[idcategory]'].$viewValue === 2) {
          return false;
        }
        return true;
      };

      $scope.form_classified['classified[idsignatureposition]'].$validators.notSignedForbidden = function (modelValue, viewValue) {
        if (parseInt(viewValue) === 0 && $scope.form_classified['classified[idcategory]'].$viewValue !== 2 && $scope.form_classified['classified[idclassifiedoriginaltype]'].$viewValue === 2) {
          return false;
        }
        return true;
      };

      if ($scope.form_classified['classified[height]']) {
        $scope.form_classified['classified[height]'].$validators.frameSizeBiggerThanImageSize = function (modelValue, viewValue) {
          if (
            $scope.form_classified['classified[heightwithframe]'] &&
            $scope.form_classified['classified[heightwithframe]'].$viewValue &&
            viewValue &&
            parseInt($scope.form_classified['classified[heightwithframe]'].$viewValue) <= parseInt(viewValue)
          ) {
            return false;
          }
          return true;
        };
      }

      if ($scope.form_classified['classified[heightwithframe]']) {
        $scope.form_classified['classified[heightwithframe]'].$validators.frameSizeBiggerThanImageSize = function (modelValue, viewValue) {
          if (
            $scope.form_classified['classified[height]'] &&
            $scope.form_classified['classified[height]'].$viewValue &&
            viewValue &&
            parseInt($scope.form_classified['classified[height]'].$viewValue) >= parseInt(viewValue)
          ) {
            return false;
          }
          return true;
        };
      }

      if ($scope.form_classified['classified[width]']) {
        $scope.form_classified['classified[width]'].$validators.frameSizeBiggerThanImageSize = function (modelValue, viewValue) {
          if (
            $scope.form_classified['classified[widthwithframe]'] &&
            $scope.form_classified['classified[widthwithframe]'].$viewValue &&
            viewValue &&
            parseInt($scope.form_classified['classified[widthwithframe]'].$viewValue) <= parseInt(viewValue)
          ) {
            return false;
          }
          return true;
        };
      }

      if ($scope.form_classified['classified[widthwithframe]']) {
        $scope.form_classified['classified[widthwithframe]'].$validators.frameSizeBiggerThanImageSize = function (modelValue, viewValue) {
          if (
            $scope.form_classified['classified[width]'] &&
            $scope.form_classified['classified[width]'].$viewValue &&
            viewValue &&
            parseInt($scope.form_classified['classified[width]'].$viewValue) >= parseInt(viewValue)
          ) {
            return false;
          }
          return true;
        };
      }

      if ($scope.form_classified['classified[length]']) {
        $scope.form_classified['classified[length]'].$validators.frameSizeBiggerThanImageSize = function (modelValue, viewValue) {
          if (
            $scope.form_classified['classified[lengthwithframe]'] &&
            $scope.form_classified['classified[lengthwithframe]'].$viewValue &&
            viewValue &&
            parseInt($scope.form_classified['classified[lengthwithframe]'].$viewValue) <= parseInt(viewValue)
          ) {
            return false;
          }
          return true;
        };
      }

      if ($scope.form_classified['classified[lengthwithframe]']) {
        return ($scope.form_classified['classified[lengthwithframe]'].$validators.frameSizeBiggerThanImageSize = function (modelValue, viewValue) {
          if (
            $scope.form_classified['classified[length]'] &&
            $scope.form_classified['classified[length]'].$viewValue &&
            viewValue &&
            parseInt($scope.form_classified['classified[length]'].$viewValue) >= parseInt(viewValue)
          ) {
            return false;
          }
          return true;
        });
      }
    };

    $timeout(initValidators, 1000);

    $scope.updateClasssifiedauctionmode = function (classified, lang) {
      $scope.init_categories(lang, classified.idclassifiedauctionmode);
      if (classified.idclassifiedauctionmode === 1) {
        $timeout(function () {
          $scope.form_classified['classified[weight]'].$validators.weightGreaterThanZero = (modelValue, viewValue) => parseFloat(viewValue.replace(',', '.')) > 0;
          $scope.form_classified['classified[shipment_info]'].$validators.textTooLong = (modelValue, viewValue) => String(viewValue).replace(/<[^>]+>/gm, '').length <= 1000;
        });
      } else {
        $scope.form_classified['classified[weight]'].$validators.weightGreaterThanZero = undefined;
        $scope.form_classified['classified[shipment_info]'].$validators.textTooLong = undefined;
      }
      $timeout(function () {
        initValidators();
        // force validation of price
        $scope.form_classified['classified[price]'].$setDirty();
        $scope.form_classified['classified[price]'].$validate();
      }, 1000);
    };

    $scope.initializeOriginalType = function (classified) {
      if (classified.idclassifiedclass === 2) {
        return ($scope.classifiedOriginalType = $scope.retrieve('original_type_decorative_to_json'));
      } else if (classified.idclassifiedclass === 3) {
        return ($scope.classifiedOriginalType = $scope.retrieve('original_type_design_to_json'));
      } else {
        return ($scope.classifiedOriginalType = $scope.retrieve('original_type_fineart_to_json'));
      }
    };

    $scope.initializeSignaturePosition = function (classified) {
      if (classified.idcategory !== 2) {
        return ($scope.signaturePosition = $scope.retrieve('signatureposition_to_json'));
      } else {
        return ($scope.signaturePosition = $scope.retrieve('signatureposition_for_print_to_json'));
      }
    };

    $scope.setWeightUnit = function (classified, weightUnit) {
      classified.idclassifiedweightunit = weightUnit.id;
      return ($scope.weightUnit = weightUnit.label);
    };

    $scope.setCurrencies = function (classified, currency) {
      classified.cost_of_transport_idcurrency = currency.id;
      classified.idestimatecurrency = currency.id;
      classified.idcurrency = currency.id;
      return ($scope.currency = currency.label);
    };

    $scope.setDimensionUnit = function (classified, dimensionUnit) {
      classified.idclassifieddimensionunit = dimensionUnit.id;
      classified.idclassifieddimensionunitwithframe = dimensionUnit.id;
      return ($scope.dimensionUnit = dimensionUnit.label);
    };

    $scope.validImagesCount = function () {
      let count = 0;
      $scope.classified.images.forEach(function (image) {
        if (!image.deleted) {
          return ++count;
        }
      });
      return count;
    };

    $rootScope.$on('all_uploads_finished', function (event, args) {
      $scope.images_uploading = false;
      return $scope.$apply();
    });

    $rootScope.$on('starting_upload', function (event, args) {
      $scope.images_uploading = true;
      return $scope.$apply();
    });

    $scope.validImagesSize = function () {
      let result = 0;
      if ($scope.classified && $scope.classified.images) {
        for (let image of Array.from($scope.classified.images)) {
          if (!image.deleted) {
            result = result + 1;
          }
        }
      }
      return result;
    };

    const next_sortorder = function () {
      if ($scope.max_sortorder === 0 && $scope.classified && $scope.classified.images) {
        for (let image of Array.from($scope.classified.images)) {
          if (image.sortorder > $scope.max_sortorder) {
            $scope.max_sortorder = image.sortorder;
          }
        }
      }

      $scope.max_sortorder = $scope.max_sortorder + 1;
      return $scope.max_sortorder;
    };

    const get_classified_id_or_empty_string = function () {
      if ($scope.classified && $scope.classified.id) {
        return $scope.classified.id;
      }
      return '';
    };

    $scope.delete_image = file_to_delete => (file_to_delete.deleted = true);

    const image_count_control = function (file) {
      if ($scope.validImagesSize() + $scope.file_uploader.queue.length >= 5) {
        file.error = i18next.t('javascripts.locales.m5');
        return false;
      }
      return true;
    };

    const file_upload_success_callback = function (item) {
      if ($scope.classified.images.length === 0) {
        item.main = true;
      }
      return $scope.classified.images.push(item);
    };

    $scope.init_image_uploader = function () {
      $scope.file_uploader = $scope.init_new_file_uploader(file_upload_success_callback, `/api/wallet/classified/create_image/${get_classified_id_or_empty_string()}`, [image_count_control], $scope);
      $scope.file_uploader.bind('beforeupload', (event, item) => item.formData.push({ sortorder: next_sortorder() }));
    };

    $scope.isForbidden = function () {
      if ($scope.classified) {
        if (parseInt($scope.classified.idsignatureposition) === 7) {
          return true;
        }
        if ($scope.classified.idclassifiedoriginaltype === 3) {
          return true;
        }
      }
      return false;
    };

    const get_normalised_id = function (id) {
      if (!id || id === '' || id <= 0) {
        return '';
      }
      return id;
    };

    const has_draft = () =>
      $localStorage.classified &&
      ($localStorage.classified.images.length !== 0 ||
        $localStorage.classified.idclassifiedshipmentmode !== $scope.classifiedSkeleton.idclassifiedshipmentmode ||
        $localStorage.classified.idclassifiedclass !== $scope.classifiedSkeleton.idclassifiedclass ||
        $localStorage.classified.idclassifiedauctionmode !== $scope.classifiedSkeleton.idclassifiedauctionmode ||
        $localStorage.classified.idclassifieddimensionunit !== $scope.classifiedSkeleton.idclassifieddimensionunit ||
        $localStorage.classified.idcurrency !== $scope.classifiedSkeleton.idcurrency ||
        $localStorage.classified.idestimatecurrency !== $scope.classifiedSkeleton.idestimatecurrency ||
        $localStorage.classified.cost_of_transport_idcurrency !== $scope.classifiedSkeleton.cost_of_transport_idcurrency ||
        $localStorage.classified.idclassifieddimensionunitwithframe !== $scope.classifiedSkeleton.idclassifieddimensionunitwithframe ||
        $localStorage.classified.idclassifiedweightunit !== $scope.classifiedSkeleton.idclassifiedweightunit ||
        $localStorage.classified.saledate !== undefined ||
        $localStorage.classified.saletitle !== undefined ||
        $localStorage.classified.isCatalogSale !== false);

    const askForDraft = function () {
      const get_draft = confirm(i18next.t('javascripts.locales.m19'));
      if (get_draft) {
        $scope.classified = $localStorage.classified;
        if ($scope.classified.idclassifiedshipmentmode === 0 && $scope.classified.idclassifiedauctionmode === 1) {
          return ($scope.classified.idclassifiedshipmentmode = 1);
        }
      } else {
        $scope.classified = $scope.classifiedSkeleton;
        return ($localStorage.classified = $scope.classified);
      }
    };

    $scope.initIsCatalogSale = classified =>
      Classified.retrieve(
        { helper: 'is_catalog_sale_options_to_json' },
        function (data) {
          classified.isCatalogSale = false;
          $scope.isCatalogSaleOptions = data;
          return (() => {
            const result = [];
            for (let isCatalogSaleOption of Array.from($scope.isCatalogSaleOptions)) {
              if (isCatalogSaleOption.id === true) {
                result.push((classified.isCatalogSale = true));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })();
        },
        $artpHttpErrorHandler,
      );

    $scope.initializeCatalogSaleInfoFromStorage = function (classified) {
      if ($sessionStorage.classifiedCatalogSaleInfo) {
        classified.saletitle = $sessionStorage.classifiedCatalogSaleInfo.saletitle;
        classified.saledate = $sessionStorage.classifiedCatalogSaleInfo.saledate;
        if ($sessionStorage.classifiedCatalogSaleInfo.isCatalogSale) {
          classified.idclassifiedauctionmode = $sessionStorage.classifiedCatalogSaleInfo.idclassifiedauctionmode;
        }
      }
      return ($sessionStorage.classifiedCatalogSaleInfo = classified);
    };

    $scope.initializeFormWithClassified = function (classified) {
      $scope.initializeUnits();
      $scope.initializeOriginalType(classified);
      $scope.initializeSignaturePosition(classified);
      $scope.initIsCatalogSale(classified);
      return $scope.initializeCatalogSaleInfoFromStorage(classified);
    };

    $scope.initializeClassified = function (classified_id, check_draft, mode) {
      if (mode == null) {
        mode = 2;
      }
      $scope.loading = true;
      if (check_draft) {
        if (has_draft()) {
          return $timeout(function () {
            askForDraft();
            $scope.initializeFormWithClassified($scope.classified);
            return ($scope.loading = false);
          });
        } else {
          $scope.classified = $scope.classifiedSkeleton;
          $scope.classified.idclassifiedauctionmode = mode;
          $localStorage.classified = $scope.classified;
          $scope.initializeFormWithClassified($scope.classified);
          return ($scope.loading = false);
        }
      } else {
        if (classified_id > 0) {
          return Classified.show(
            { id: classified_id },
            function (classified) {
              $scope.classified = $.extend($scope.classified, classified);
              $scope.classified.images = [];
              if ($scope.classified.idclassifiedshipmentmode === 0 && $scope.classified.idclassifiedauctionmode === 1) {
                $scope.classified.idclassifiedshipmentmode = 1;
              }
              $localStorage.classified = $scope.classified;
              $scope.initializeFormWithClassified($scope.classified);
              return ($scope.loading = false);
            },
            $artpHttpErrorHandler,
          );
        } else {
          $scope.classified = $scope.classifiedSkeleton;
          $scope.classified.idclassifiedauctionmode = mode;
          return ($scope.loading = false);
        }
      }
    };

    $scope.initializeUnits = function () {
      // On initialise à cm et € par defaut
      // Si les retrive plante, on a quand même des valeurs
      $scope.currency = '€';
      $scope.dimensionUnit = 'cm';
      $scope.weightUnit = 'kg';
      Classified.retrieve(
        { helper: 'classifieddimensionunit_to_json' },
        classifieddimensionunitJson =>
          (() => {
            const result = [];
            for (let classifieddimensionunit of Array.from(classifieddimensionunitJson)) {
              if (parseInt(classifieddimensionunit.id) === parseInt($scope.classified.idclassifieddimensionunit)) {
                result.push(($scope.dimensionUnit = classifieddimensionunit.label));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })(),
        $artpHttpErrorHandler,
      );
      Classified.retrieve(
        { helper: 'currency_to_json' },
        currencyJson =>
          (() => {
            const result = [];
            for (let currency of Array.from(currencyJson)) {
              if (parseInt(currency.id) === parseInt($scope.classified.idcurrency)) {
                result.push(($scope.currency = currency.label));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })(),
        $artpHttpErrorHandler,
      );
      return Classified.retrieve(
        { helper: 'classifiedweightunit_to_json' },
        classifiedweightunitJson =>
          (() => {
            const result = [];
            for (let classifiedweightunit of Array.from(classifiedweightunitJson)) {
              if (parseInt(classifiedweightunit.id) === parseInt($scope.classified.idclassifiedweightunit)) {
                result.push(($scope.weightUnit = classifiedweightunit.label));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })(),
        $artpHttpErrorHandler,
      );
    };

    $scope.minAuctionStartDate = addDays(new Date(), 4);

    $scope.maxAuctionStartDate = addMonths(new Date(), 1);

    $scope.today = new Date();

    $scope.initDates = (dt, field) => {
      // We have to offset the TZ (in minutes) because uib-datepicker doesn't support timezone
      // See https://github.com/angular-ui/bootstrap/issues/6146
      const localTZDate = new Date(dt);
      const offsettedDate = addMinutes(localTZDate, localTZDate.getTimezoneOffset());

      $scope.classified[field] = offsettedDate;
    };

    $scope.change_start_date = function () {
      $scope.three_days_fromstart = addDays(new Date($scope.classified.start_auction_date), 3);
      $scope.thirteen_days_fromstart = addDays(new Date($scope.classified.start_auction_date), 13);
      if (isBefore(new Date($scope.classified.end_auction_date), $scope.three_days_fromstart)) {
        $scope.classified.end_auction_date = $scope.three_days_fromstart;
      }
      if (isAfter(new Date($scope.classified.end_auction_date), $scope.thirteen_days_fromstart)) {
        return ($scope.classified.end_auction_date = $scope.thirteen_days_fromstart);
      }
    };

    $scope.delete_draft = function () {
      delete $localStorage.classified;
      return $scope.init_classified_form_lot(cparams);
    };

    $scope.get_not_fineart_classification_element_with_id_in_array_default_all = function (id, array) {
      if (!array) {
        return null;
      }
      if (!id || id === '') {
        return $scope.all_decorative_styles;
      }
      return $scope.get_children_from_item_matching_id_in_array(id, array);
    };

    $scope.get_children_from_item_matching_id_in_array = function (id, array) {
      if (!array) {
        return [];
      }
      if (!id) {
        return [];
      }
      for (let item of Array.from(array)) {
        if (item.id.toString() === id.toString()) {
          return item;
        }
        if (item.children) {
          for (let sub_item of Array.from(item.children)) {
            if (sub_item.id === id) {
              return sub_item;
            }
          }
        }
      }
    };

    $scope.display_only_error_fields = function () {
      $('#classified_form input,#classified_form select #classified_form textarea').closest('.form-group').removeClass('has-error');
      return (() => {
        const result = [];
        for (var field in $scope.errors) {
          const messages = $scope.errors[field];
          result.push(Array.from(messages).map(message => $scope.display_error($('#classified_form #classified_' + field), message)));
        }
        return result;
      })();
    };

    $scope.display_error = (field, message) => field.addClass('field_with_errors');

    $scope.init_categories = (lang, idclassifiedauctionmode) => {
      $scope.categories = Classified.categories({ l: lang, icm: idclassifiedauctionmode }, noop, $artpHttpErrorHandler);
    };

    $scope.init_saledate_with = function (sdate, format) {
      if (sdate !== '') {
        return ($scope.classified.saledate = new Date(sdate));
      }
    };

    $scope.get_categorie_by_id = function (id) {
      if (!$scope.categories) {
        turn({});
      }
      for (let categorie of Array.from($scope.categories)) {
        if (parseInt(categorie.id) === parseInt(id)) {
          return categorie;
        }
      }
    };

    $scope.emptyField = (entity, field, checked) => (entity[field] = '');

    $scope.nextStep = function (event) {
      $scope.form_classified.$setSubmitted();
      if ($scope.form_classified.$valid && $scope.classified.idclassifiedauctionmode === 1) {
        $scope.form_classified.$valid = $scope.validImagesCount() > 0;
      }

      if ($scope.form_classified.$valid && (!$scope.form_classified.checkcgv || $scope.form_classified.checkcgv.$viewValue)) {
        $scope.submitBtnDisabled = true;
        return delete $localStorage.classified;
      } else {
        event.preventDefault();

        if (!$scope.form_classified.$valid) {
          try {
            console.log({
              form: $scope.form_classified,
              errors: $scope.form_classified.$error,
            });

            const formattedErrors = {};
            Object.entries($scope.form_classified.$error).forEach(([errorType, errors]) => {
              formattedErrors[errorType] = errors.map(error => error.$name);
            });

            Sentry.captureMessage('[form_classified] Validation failed', {
              extra: {
                errors: formattedErrors,
              },
            });
          } catch (e) {
            console.error(e);
          }

          window.location.hash = 'scroll-top';
          return $anchorScroll();
        }
      }
    };

    return vm;
  },
]);
