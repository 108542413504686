/* eslint-disable no-param-reassign */
import angular from 'angular';
import paypalCheckout from 'paypal-checkout';
import _ from 'lodash';
import i18next from 'i18next';
import './stylesheet.scss';
import { FRANCE } from '../../../constants/wallet/country';
import { internalAxios } from '../../../../../javascript/utils/axios/internal';
// eslint-disable-next-line object-curly-newline
import { ARTRON_PHYSICSALEGROUPMENTTYPE_ID, PAYPAL_CLIENT_ID, PAYPAL_MODE, STRIPE_PUBLIC_API_KEY } from '../../../../../javascript/constants/rails';
import paypalCheckoutPayment from './paypalCheckoutPayment';

const template = require('./template.html');

const paypalEnv = PAYPAL_MODE === 'live' ? 'production' : PAYPAL_MODE; // merci paypal...

const paypalClient = {
  [paypalEnv]: PAYPAL_CLIENT_ID,
};

class Ctrl {
  constructor(Physicproduct, $timeout, $artpHttpErrorHandler, $http, $window, $exceptionHandler, $scope, viewport, $interval, Physicsale, $document, $sessionStorage) {
    this.$document = $document;
    this.Physicsale = Physicsale;
    this.qrCode = false;
    this.isMobile = viewport.isMobile();
    this.isDesktop = viewport.isDesktop();
    this.$interval = $interval;
    this.$artpHttpErrorHandler = $artpHttpErrorHandler;
    this.$scope = $scope;
    this.FRANCE = FRANCE;
    this.$timeout = $timeout;
    this.loading = false;
    this.Physicproduct = Physicproduct;
    this.$http = $http;
    this.$window = $window;
    this.$sessionStorage = $sessionStorage;
    this.PAYPAL_PLATFORM_ID = 'paypal';
    this.APPLE_PAY_PLATFORM_ID = 'stripe_apple_pay';
    this.STRIPE_INTENTS_PLATFORM_ID = 'stripe_intent';
    this.STRIPE_ALIPAY_PLATFORM_ID = 'stripe_alipay';
    this.STRIPE_WECHAT_PLATFORM_ID = 'stripe_wechat';
    this.ARTRON_PLATFORM_ID = 'artron';
    this.OGONE_PLATFORM_ID = 'ogone';
    this.$exceptionHandler = $exceptionHandler;
    this.paypalEnv = paypalEnv;
    this.ARTRON_PHYSICSALEGROUPMENTTYPE_ID = ARTRON_PHYSICSALEGROUPMENTTYPE_ID;
    this.selectedPaymentWay = '';
  }

  $onChanges() {
    const vm = this;

    this.iso1Currency = '€';
    if (this.currency === 'usd') {
      this.iso1Currency = '$';
    } else if (this.currency === 'gbp') {
      this.iso1Currency = '£';
    } else if (this.currency === 'cny') {
      this.iso1Currency = '￥';
    }

    if (this.onlinePlatforms?.some(platform => platform.id === this.STRIPE_INTENTS_PLATFORM_ID)) {
      // eslint-disable-next-line prefer-destructuring
      this.stripeIntentsPlatform = this.onlinePlatforms.filter(platform => platform.id === this.STRIPE_INTENTS_PLATFORM_ID)[0];

      const hasAllCards = ['cb', 'amex', 'mastercard', 'visa'].every(card => this.stripeIntentsPlatform.payment_ways.includes(card));

      this.stripeIntentsElement = {
        modalMode: !hasAllCards,
        displayed: hasAllCards,
        payment_ways: this.stripeIntentsPlatform.payment_ways,
      };

      const stripe = this.$window.Stripe(STRIPE_PUBLIC_API_KEY, { locale: i18next.language });
      const elements = stripe.elements();
      const cardElementOptions = {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
        // hidePostalCode :true, // finalement déconseillé par Stripe en cas de problème (litige, fraude) on pourrait se voir reprocher d'être hors procédure et de ne pas avoir pris toutes les info de sécurité demandées.
      };
      const clientSecret = this.stripeIntentsPlatform.parameters.intent_client_secret;

      const cardElement = elements.create('card', cardElementOptions);
      this.$timeout(() => {
        cardElement.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        cardElement.addEventListener('change', event => {
          const displayError = this.$document[0].getElementById('card-errors');
          const displayErrorContainer = this.$document[0].getElementById('card-errors-container');
          if (event.error) {
            displayErrorContainer.style.display = 'block';
            displayError.textContent = event.error.message;
          } else {
            displayErrorContainer.style.display = 'none';
            displayError.textContent = '';
          }
        });

        const cardholderName = this.$document[0].getElementById('cardholder-name');
        const cardButton = this.$document[0].getElementById('card-button');
        const cardButtonText = this.$document[0].getElementById('card-button-text');
        const processingPayment = this.$document[0].getElementById('processing-payment');

        cardButton.addEventListener('click', async () => {
          cardButton.disabled = true;
          processingPayment.style.display = 'block';
          cardButtonText.style.display = 'none';

          const displayError = this.$document[0].getElementById('card-errors');
          const displayErrorContainer = this.$document[0].getElementById('card-errors-container');
          displayErrorContainer.style.display = 'none';
          displayError.textContent = '';

          // todo reprendre handleCardPayment en confirmCardPayment
          // deprecated : https://stripe.com/docs/js/appendix/viewport_meta_requirements
          // exemple https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements
          // et ajouter spiner style ...
          const { paymentIntent, error } = await stripe.handleCardPayment(clientSecret, cardElement, {
            payment_method_data: {
              billing_details: { name: cardholderName.value },
            },
          });

          if (error) {
            displayErrorContainer.style.display = 'block';
            if (error.param === 'payment_method_data[billing_details][name]' && error.code === 'parameter_invalid_empty') {
              displayError.textContent = i18next.t('subscription.cart.m47');
            } else if (error.message) {
              displayError.textContent = error.message;
              this.$exceptionHandler(error);
            } else {
              displayError.textContent = i18next.t('subscription.cart.m48');
              this.$exceptionHandler(error);
            }

            cardButton.disabled = false;
            processingPayment.style.display = 'none';
            cardButtonText.style.display = 'block';
          } else {
            this.gtmCheckout('stripe');
            await this.validateStripePayment(paymentIntent.id, this.idpayment);
          }
        });
      });

      if (this.stripeIntentsPlatform.payment_ways.includes('apple_pay')) {
        const paymentRequest = stripe.paymentRequest({
          country: 'FR',
          currency: this.stripeIntentsPlatform.parameters.currency,
          total: {
            label: this.description,
            amount: this.stripeIntentsPlatform.parameters.amount,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
        const stripeElements = stripe.elements();
        const prButton = stripeElements.create('paymentRequestButton', {
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '35px',
            },
          },
        });

        (async () => {
          // Check the availability of the Payment Request API first.
          const result = await paymentRequest.canMakePayment();
          if (result) {
            if (result.applePay) {
              prButton.mount('#apple-pay-button');
              this.showApplePay = true;
              this.showPaymentRequest = false;
            } else {
              prButton.mount('#payment-request-button');
              this.showApplePay = false;
              this.showPaymentRequest = true;
            }
          } else {
            this.showApplePay = false;
            this.showPaymentRequest = false;
          }
          this.$scope.$digest();
        })();

        paymentRequest.on('paymentmethod', async ev => {
          const { error: confirmError, paymentIntent } = await stripe.confirmPaymentIntent(clientSecret, {
            payment_method: ev.paymentMethod.id,
          });

          if (confirmError) {
            // l'interface de chrome ou apple pay doit se charger d'afficher un message d'erreur
            this.$exceptionHandler(confirmError);
            ev.complete('fail');
          } else {
            ev.complete('success');
            await this.validateStripePayment(paymentIntent.id, this.idpayment);
          }
        });
      }
    }

    const paypalPlatform = this.onlinePlatforms && this.onlinePlatforms.find(platform => platform.id === this.PAYPAL_PLATFORM_ID);
    if (paypalPlatform) {
      const disallowedCards = [];
      this.onlinePlatforms
        .filter(platform => platform.id !== this.PAYPAL_PLATFORM_ID)
        .forEach(platform => {
          platform.payment_ways.forEach(cb => {
            if (Object.values(paypalCheckout.CARD).includes(cb)) {
              disallowedCards.push(cb);
            }
          });
        });

      this.paypal = {
        env: paypalEnv,
        client: paypalClient,
        commit: true,
        onAuthorize: data => {
          this.$http
            .post('/api/payment/validate_paypal_payment', {
              paypalPaymentID: data.paymentID,
              paypalOrderID: data.orderID,
              paypalPayerID: data.payerID,
              paypalPaymentToken: data.paymentToken,
              idphysicsale: this.idphysicsale,
            })
            .then(() => {
              this.$window.location = '/subscription/payment_ok';
            })
            .catch(error => {
              vm.$exceptionHandler(error);
              try {
                vm.$exceptionHandler(`error validate_paypal_payment (PaymentExecuteRequest) ${angular.toJson(data)}`); // eslint-disable-line
                // eslint-disable-next-line no-empty
              } catch (e) {}
              // Payment KO
              this.loading = false;
              vm.paymentKo = true;
            });
        },
        funding: {
          disallowed: disallowedCards,
        },
        onCancel: (data, actions) => {
          console.log('cancel', data, actions);
        },
        onError: params => {
          try {
            vm.$exceptionHandler(params);
          } catch (e) {
            // Ignoring
          }
          console.error('error', params);
          vm.paymentKo = true;
        },
        payment: paypalCheckoutPayment(vm),
      };

      // eslint-disable-next-line default-case
      switch (i18next.language) {
        case 'fr':
          this.paypal.locale = 'fr_FR';
          break;
        case 'en':
          this.paypal.locale = 'en_US';
          break;
        case 'de':
          this.paypal.locale = 'de_DE';
          break;
        case 'es':
          this.paypal.locale = 'es_ES';
          break;
        case 'it':
          this.paypal.locale = 'it_IT';
          break;
        case 'zh':
          this.paypal.locale = 'zh_CN';
          break;
        // pas de default : si on ne renseigne pas, le composant paypal gère de son coté (avec la local du navigateur probablement)
      }

      this.paypal.style = {
        tagline: false,
        label: 'paypal',
        color: 'blue',
        shape: 'rect',
      };
      if (paypalPlatform.payment_ways.some(paymentWay => paymentWay !== 'paypal')) {
        this.paypal.style.fundingicons = true;
        this.paypal.style.size = 'large';
      } else {
        this.paypal.style.fundingicons = false;
        this.paypal.style.size = 'responsive';
      }
    }

    this.classicPlatmforms = this.onlinePlatforms.filter(
      platform =>
        (platform.get_url && platform.id !== this.ARTRON_PLATFORM_ID && platform.id !== this.STRIPE_ALIPAY_PLATFORM_ID) ||
        (platform.post_url && platform.id !== this.PAYPAL_PLATFORM_ID) ||
        (platform.id === this.STRIPE_INTENTS_PLATFORM_ID && this.stripeIntentsElement && this.stripeIntentsElement.modalMode),
    );

    this.wechatPlatform = this.isDesktop && this.onlinePlatforms.find(platform => platform.id === this.STRIPE_WECHAT_PLATFORM_ID);
    this.alipayPlatform = this.allowAlipay && this.onlinePlatforms.find(platform => platform.id === this.STRIPE_ALIPAY_PLATFORM_ID);
  }

  async validateStripePayment(paymentIntentId, idPayment) {
    try {
      await internalAxios.post(
        '/api/payment/validate_stripe_element_payment',
        {
          stripe_payment_intent_id: paymentIntentId,
          idpayment: idPayment,
        },
        {
          headers: { 'content-type': 'application/json' },
        },
      );

      this.$timeout(() => {
        this.$window.location = '/subscription/payment_ok';
      });
    } catch (err) {
      // le post vers notre backend a echoué mais le paiement est ok coté stripe
      // on redirige le client vers la page payment_ok qui lui affichera paiement en attente
      // jusqu'à la notif de stripe (webhook)
      this.$exceptionHandler(err);
      this.$timeout(() => {
        this.$window.location = '/subscription/payment_ok';
      }, 2000); // on laisse 2 secondes a $exceptionHandler avant de rediriger
    }
  }

  filterClassicPaymentWays(paymentWays) {
    return paymentWays.filter(paymentWay => !['apple_pay'].includes(paymentWay));
  }

  artronPlatform() {
    return this.onlinePlatforms && this.onlinePlatforms.filter(platform => platform.id === this.ARTRON_PLATFORM_ID)[0];
  }

  selectPlatform(platform, $event) {
    if (platform.id === this.OGONE_PLATFORM_ID) {
      switch (this.selectedPaymentWay) {
        case 'visa':
          $event.target.BRAND.value = 'VISA';
          break;
        case 'cb':
          $event.target.BRAND.value = 'CB';
          break;
        case 'mastercard':
          $event.target.BRAND.value = 'MasterCard';
          break;
        default:
          $event.target.BRAND.value = '';
          break;
      }
    }

    // eslint-disable-next-line no-param-reassign
    $event.target.action = platform.post_url;
    this.gtmCheckout(platform.id);

    // Delai de 100ms pour ne pas risquer d'interagir avec l'event
    this.$timeout(
      () =>
        this.Physicproduct.select_platform(
          {
            platform: platform.id,
            idphysicsale: this.idphysicsale,
          },
          _.noop,
          this.$artpHttpErrorHandler,
        ),
      100,
    );
    return true;
  }

  clickOnPaymentWay(paymentway) {
    this.selectedPaymentWay = paymentway;
  }

  gtmCheckout(platform) {
    try {
      this.$window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: platform,
            },
            products: [
              {
                ...this.$sessionStorage.gtmProduct,
                quantity: this.$sessionStorage.subscription_context.quantity,
              },
            ],
          },
        },
      });
    } catch (err) {
      // eslint-disable-next-line angular/log
      console.error(err);
    }
  }

  selectPlatformSync(platform) {
    this.Physicproduct.select_platform(
      {
        platform: platform.id,
        idphysicsale: this.idphysicsale,
      },
      _.noop,
      this.$artpHttpErrorHandler,
    );
    return true;
  }

  checkPayment() {
    this.checkPaymentCallsPromise = this.$interval(() => {
      this.Physicsale.checkPayment(
        { id: this.idphysicsale },
        data => {
          if (data.result === true) {
            this.cancelCheckPaymentCalls();
            this.$window.location = '/subscription/payment_ok';
          }
        },
        this.$artpHttpErrorHandler,
      );
    }, 3000);
  }

  toggleQrCode() {
    this.qrCode = !this.qrCode;
  }

  cancelCheckPaymentCalls() {
    this.$interval.cancel(this.checkPaymentCallsPromise);
  }

  $onDestroy() {
    this.cancelCheckPaymentCalls();
  }

  contactByArtron(url) {
    this.Physicsale.contactByArtron({ id: this.idphysicsale }, data => {
      if (data.result) {
        if (url) {
          this.$window.location = url;
        } else {
          this.$window.location = `/artron/contact/${this.idphysicsale}`;
        }
      }
    });
  }
}

const Component = {
  template,
  controller: ['Physicproduct', '$timeout', '$artpHttpErrorHandler', '$http', '$window', '$exceptionHandler', '$scope', 'viewport', '$interval', 'Physicsale', '$document', '$sessionStorage', Ctrl],
  bindings: {
    onlinePlatforms: '<',
    offlineAllowed: '<',
    idcountry: '<',
    prices: '<',
    currency: '<',
    idphysicsale: '<',
    idpayment: '<',
    phone: '<',
    email: '<',
    postalCode: '<',
    iso2Country: '<',
    city: '<',
    line1: '<',
    firstname: '<',
    lastname: '<',
    description: '<',
    allowAlipay: '<',
    idphysicsalegroupmenttype: '<',
  },
};

angular.module('artprice_app').component('artpSubscriptionDisplayPlatforms', Component);
