/* eslint-disable */
import _ from 'lodash';

window.artprice_app.controller('AccountMultiuserCtrl', [
  '$sce',
  '$scope',
  '$compile',
  'Customer',
  'Country',
  'Subscription',
  '$sessionStorage',
  'Artprice',
  '$timeout',
  '$artpHttpErrorHandler',
  function ($sce, $scope, $compile, Customer, Country, Subscription, $sessionStorage, Artprice, $timeout, $artpHttpErrorHandler) {
    $scope.formNewPwdErrors = [];
    $scope.formAddChildErrors = {};

    // doit être initialisé des le début pour éviter qu'il soit créé dans des sous scopes (style dans un ng-if)
    $scope.new_customer = {};

    const success = data => ($scope.customer = data);
    Customer.get({}, success, $artpHttpErrorHandler);

    $scope.init_multiusers = function (id) {
      Artprice.retrieved_from_helper_for_select({ helper: 'title_to_json' }, data => ($scope.titles = data), $artpHttpErrorHandler);

      $timeout(() => ($scope.formAddChild['email'].$validators.pattern = (modelValue, viewValue) => modelValue && String(modelValue).search(/^[a-z]+[a-z0-9-._]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,5}$/gim) >= 0));

      $scope.children = [];
      $scope.children_subscriptions = [];

      return Customer.show(
        { id },
        function (customer) {
          angular.extend($scope.customer, customer);

          $scope.computing = false;
          $scope.display = {
            list: true,
            form_child_info: false,
            form_child_pwd: false,
          };

          return Customer.children(
            {},
            function (children) {
              angular.extend($scope.children, children);

              return Customer.licences_status(
                {},
                function (data) {
                  $scope.current_subscription = data;

                  if ($scope.current_subscription && $scope.current_subscription.idparent) {
                    Subscription.get_parent_subscription({ id: $scope.current_subscription.id }, parent => ($scope.parent_subscription = parent), $artpHttpErrorHandler);
                  }

                  $scope.nb_users_on_physicsale = data.nb_users_on_physicsale;
                  $scope.nbusers_on_current_subscription_and_children = data.nbusers_on_current_subscription_and_children;
                  $scope.children_subscriptions[customer.id] = { id: $scope.current_subscription.id, nbusers: $scope.current_subscription.nbusers };
                  return Subscription.get_child_subscriptions(
                    { id: $scope.current_subscription.id },
                    child_subscriptions =>
                      Array.from(child_subscriptions).map(
                        children_subscription => ($scope.children_subscriptions[children_subscription.idcustomer] = { id: children_subscription.id, nbusers: children_subscription.nbusers }),
                      ),
                    $artpHttpErrorHandler,
                  );
                },
                $artpHttpErrorHandler,
              );
            },
            $artpHttpErrorHandler,
          );
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.increment_nbusers = (idsubscription, idcustomer, idparent) => $scope.update_nbusers('increment', idsubscription, idcustomer, idparent);

    $scope.decrement_nbusers = (idsubscription, idcustomer, idparent) => $scope.update_nbusers('decrement', idsubscription, idcustomer, idparent);

    $scope.update_nbusers = function (method, idsubscription, idcustomer, idparent) {
      $scope.computing = true;

      return Subscription[`${method}_nbusers`]({ id: idsubscription, idcustomer, ts: Math.round(Math.random() * 10000) }, function (data) {
        if (!$scope.children_subscriptions[idcustomer]) {
          $scope.children_subscriptions[idcustomer] = { id: data.id, nbusers: data.nbusers };
        } else {
          $scope.children_subscriptions[idcustomer].nbusers = data.nbusers;
        }

        Customer.licences_status({}, data_parent_subscription => ($scope.children_subscriptions[data_parent_subscription.idcustomer].nbusers = data_parent_subscription.nbusers), $artpHttpErrorHandler);
        return ($scope.computing = false);
      });
    };

    $scope.detach = function (idcustomer, idsubscription) {
      $scope.computing = true;
      $scope.children_index = _.findLastIndex($scope.children, { id: idcustomer });
      if ($scope.children_subscriptions[idcustomer]) {
        $scope.children_subscriptions[$scope.customer.id].nbusers += $scope.children_subscriptions[idcustomer].nbusers;
      }
      return Customer.detach(
        { idcustomer, idsubscription },
        function (data) {
          $scope.children.splice($scope.children_index, 1);
          return ($scope.computing = false);
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.initialize_form_info = function (id) {
      $scope.new_customer = {};
      $scope.formAddChildErrors = {};
      return Customer.show(
        { id },
        function (c) {
          angular.extend($scope.new_customer, c);
          $scope.new_customer.idparent = $scope.customer.id;
          $scope.new_customer.idactivity = $scope.customer.idactivity;
          $scope.new_customer.idaccountrole = 2;
          $scope.new_customer.lang = $scope.customer.lang;
          $scope.new_customer.creationdate = new Date();

          $scope.new_customer = new Customer($scope.new_customer);
          return $scope.show_form_child_info();
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.initialize_form_pwd = function (id) {
      $scope.formNewPwd.$setPristine();
      $scope.formNewPwdErrors = [];
      $scope.new_pwd_child = _.find($scope.children, item => item.id === id);
      if (!$scope.new_pwd_child) {
        return Customer.show(
          { id },
          function (c) {
            $scope.new_pwd_child = c;
            $scope.new_pwd_child.pwd = '';
            return $scope.show_form_child_pwd();
          },
          $artpHttpErrorHandler,
        );
      } else {
        $scope.new_pwd_child.pwd = '';
        return $scope.show_form_child_pwd();
      }
    };

    $scope.submit_form = function () {
      $scope.computing = true;
      $scope.formAddChild.$setSubmitted();

      if ($scope.formAddChild.$valid) {
        if ($scope.new_customer.id) {
          return Customer.update_or_create(
            {
              login: $scope.new_customer.email,
              email: $scope.new_customer.email,
              id: $scope.new_customer.id,
              firstname: $scope.new_customer.firstname,
              lastname: $scope.new_customer.lastname,
              idtitle: $scope.new_customer.idtitle,
            },
            function (customer) {
              $scope.formAddChildErrors = {};
              const childToModify = _.find($scope.children, item => item.id === customer.id);
              if (childToModify) {
                angular.extend(childToModify, customer);
              }
              $scope.computing = false;
              return $scope.close_form();
            },
            $artpHttpErrorHandler,
          );
        } else {
          return Customer.multiusers_add_child(
            $scope.new_customer,
            function (data) {
              if (data.errors) {
                $scope.formAddChildErrors = data.errors;
              } else {
                $scope.formAddChildErrors = {};
                $scope.children.push(data);
                $scope.increment_nbusers(null, data.id);

                Customer.licences_status({}, data_customer => ($scope.children_subscriptions[data_customer.idcustomer].nbusers = data_customer.nbusers), $artpHttpErrorHandler);
                $scope.close_form();
              }

              return ($scope.computing = false);
            },
            $artpHttpErrorHandler,
          );
        }
      } else {
        return ($scope.computing = false);
      }
    };

    $scope.set_new_password = function () {
      $scope.computing = true;
      $scope.formNewPwd.$setSubmitted();
      if ($scope.formNewPwd.$valid) {
        if ($scope.new_pwd_child.id) {
          return Customer.multiusers_change_child_password(
            { idcustomer: $scope.new_pwd_child.id, new_pwd: $scope.new_pwd_child.pwd },
            function (data) {
              if (data.errors) {
                $scope.formNewPwdErrors = data.errors;
                if (data.bad_password) {
                  $scope.formNewPwd.customer_pwd.$valid = false;
                  $scope.formNewPwd.customer_pwd.$invalid = true;
                  $scope.formNewPwd.customer_pwd.$error.pattern = true;
                }
              } else {
                $scope.formNewPwdErrors = [];
                $scope.close_form();
              }
              return ($scope.computing = false);
            },
            $artpHttpErrorHandler,
          );
        } else {
          $log('Error, no id for $scope.new_pwd_child');
          return ($scope.computing = false);
        }
      } else {
        return ($scope.computing = false);
      }
    };

    $scope.show_form_child_pwd = function () {
      $scope.display.list = false;
      $scope.display.form_child_info = false;
      return ($scope.display.form_child_pwd = true);
    };

    $scope.show_form_child_info = function () {
      $scope.display.form_child_pwd = false;
      $scope.display.list = false;
      $scope.display.form_child_info = true;
      if (!$scope.new_customer.id) {
        return $scope.formAddChild.$setPristine();
      } else {
        return $scope.formAddChild.$setSubmitted();
      }
    };

    return ($scope.close_form = function () {
      $scope.display.form_child_pwd = false;
      $scope.display.form_child_info = false;
      return ($scope.display.list = true);
    });
  },
]);
