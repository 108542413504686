/* eslint-disable */
import { noop, find } from 'lodash';
import { aapiBeacon } from '../../../../../javascript/services/analytics/aapi';

window.common_app.controller('LotCtrl', [
  '$scope',
  '$uibModal',
  'Customer',
  '$sessionStorage',
  '$http',
  '$exceptionHandler',
  '$anchorScroll',
  '$artpModalErrorHandler',
  'artpJson',
  '$artpHttpErrorHandler',
  'viewport',
  '$window',
  function ($scope, $uibModal, Customer, $sessionStorage, $http, $exceptionHandler, $anchorScroll, $artpModalErrorHandler, artpJson, $artpHttpErrorHandler, viewport, $window) {
    $scope.order_by = 'artist';

    $scope.moveUp = function () {
      if (window.location.hash !== 'top') {
        window.location.hash = 'top';
      }
      return $anchorScroll();
    };

    $scope.retreiveIndicators = function (ids) {
      $scope.lotsWithIndicators = [];
      return artpJson.post('/api/artdbweb/indicator/for', { ids }).then(data => ($scope.lotsWithIndicators = data.indicators), $artpHttpErrorHandler);
    };

    $scope.lotWithIndicator = id => $scope.lotsWithIndicators && $scope.lotsWithIndicators.includes(id);

    $scope.priceAttrTitle = function (lotCurrency, initialPrice) {
      if (lotCurrency !== $scope.currency) {
        return initialPrice;
      }
      return '';
    };

    $scope.open_reproduction = function (idlot) {
      if (viewport.isMobile()) {
        return;
      }

      $scope.modal_reproduction = $uibModal.open({
        templateUrl: `/lots/reproduction/${idlot}?index=${$scope.currentImageIndex}`,
        size: 'lg',
        scope: $scope,
        controllerAs: 'reproductionVm',
        windowClass: 'lot-image-modal',
        controller: [
          function () {
            const vm = this;
            vm.urls = [];
            vm.geometries = [];
            vm.displayedGeometry = null;
            vm.currentIndex = 0;
            vm.contained = true;
            vm.init = function (urls, geometries, currentIndex) {
              aapiBeacon('lotimage', { idlot, index: currentIndex, op: 'zoom' });
              vm.currentIndex = currentIndex;
              vm.urls = urls;
              vm.geometries = geometries;
              const $el = $('#modal-image');
              return $el.on('load', () => (vm.displayedGeometry = [$el.width(), $el.height()]));
            };

            vm.nextUrl = () => {
              const newIndex = (vm.currentIndex + 1) % vm.urls.length;
              aapiBeacon('lotimage', { idlot, index: newIndex, op: 'zoom' });
              vm.currentIndex = newIndex;
            };

            vm.prevUrl = () => {
              const newIndex = (vm.currentIndex + vm.urls.length - 1) % vm.urls.length;
              aapiBeacon('lotimage', { idlot, index: newIndex, op: 'zoom' });
              vm.currentIndex = newIndex;
            };

            vm.toggleZoom = function ($event) {
              $event.stopPropagation();
              return (vm.contained = !vm.contained);
            };

            vm.isInContainMode = function () {
              if (!Array.isArray(vm.displayedGeometry)) {
                return true;
              }
              if (!Array.isArray(vm.geometries[vm.currentIndex])) {
                return true;
              }
              return vm.displayedGeometry[0] < vm.geometries[vm.currentIndex][0] || vm.displayedGeometry[1] < vm.geometries[vm.currentIndex][1];
            };

            return vm;
          },
        ],
      });
      return $scope.modal_reproduction.result.then(noop, $artpModalErrorHandler);
    };

    $scope.retrievePrevNext = function (idlot) {
      if (document.referrer.includes('/myartprice/')) {
        return;
      }

      if ($sessionStorage.search && $sessionStorage.search.lots && $sessionStorage.search.lots[$sessionStorage.search.md5] && $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page]) {
        let params;
        $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page].forEach(function (item) {
          if (idlot !== item.id && !$scope.currentLot) {
            return ($scope.previousLot = item);
          } else if (idlot === item.id) {
            return ($scope.currentLot = item);
          } else if ($scope.currentLot && !$scope.nextLot) {
            return ($scope.nextLot = item);
          }
        });

        if (!$scope.previousLot) {
          if ($sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1]) {
            $scope.previousLot =
              $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1][$sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1].length - 1];
          } else if ($sessionStorage.search.page > 1) {
            params = angular.extend({}, $sessionStorage.search.lots[$sessionStorage.search.md5].query);
            params.p = $sessionStorage.search.page - 1;
            $http
              .get(`${window.location.protocol}//${window.location.host}${$sessionStorage.search.lots[$sessionStorage.search.md5].pathname}.json`, { params })
              .then(function (data) {
                $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1] = [];
                if (data && data.data && data.data.items) {
                  data.data.items.forEach(item =>
                    $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1].push({
                      id: item.id,
                      url: item.url,
                      page: $sessionStorage.search.page - 1,
                    }),
                  );
                }
                return ($scope.previousLot =
                  $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1][
                    $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page - 1].length - 1
                  ]);
              })
              .catch(err => $exceptionHandler(`Error while getting previous lot : ${$sessionStorage.search.lots[$sessionStorage.search.md5].pathname}.json with params ${JSON.stringify(params)}`));
          }
        }

        if (!$scope.nextLot) {
          if ($sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page + 1]) {
            return ($scope.nextLot = $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page + 1][0]);
          } else {
            params = angular.extend({}, $sessionStorage.search.lots[$sessionStorage.search.md5].query);
            params.p = $sessionStorage.search.page + 1;
            // console.log(`${$sessionStorage.search.lots[$sessionStorage.search.md5].pathname}.json`);
            return $http
              .get(`${window.location.protocol}//${window.location.host}${$sessionStorage.search.lots[$sessionStorage.search.md5].pathname}.json`, { params })
              .then(function (data) {
                $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page + 1] = [];
                if (data && data.data && data.data.items) {
                  data.data.items.forEach(item =>
                    $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page + 1].push({ id: item.id, url: item.url, page: $sessionStorage.search.page + 1 }),
                  );
                }
                return ($scope.nextLot = $sessionStorage.search.lots[$sessionStorage.search.md5].items[$sessionStorage.search.page + 1][0]);
              })
              .catch(err => $exceptionHandler(`Error while getting next lot : ${$sessionStorage.search.lots[$sessionStorage.search.md5].pathname}.json with params ${JSON.stringify(params)}`));
          }
        }
      }
    };

    $scope.storeLot = function (md5, page, query, idlot, url) {
      if (!$sessionStorage.search) {
        $sessionStorage.search = {};
      }
      $sessionStorage.search.md5 = md5;
      $sessionStorage.search.page = page;

      if (!$sessionStorage.search.lots) {
        $sessionStorage.search.lots = {};
      }
      if (!$sessionStorage.search.lots[md5]) {
        $sessionStorage.search.lots = {};
      }

      if (!$sessionStorage.search.lots[md5]) {
        $sessionStorage.search.lots[md5] = { items: {}, query: JSON.parse(query), pathname: window.location.pathname };
      }
      if (!$sessionStorage.search.lots[md5].items[page]) {
        $sessionStorage.search.lots[md5].items[page] = [];
      }
      const item = find($sessionStorage.search.lots[md5].items[page], item => item.id === idlot);
      if (!item) {
        return $sessionStorage.search.lots[md5].items[page].push({ id: idlot, url, page });
      }
    };

    $scope.initImages = function (urls) {
      $scope.urls = urls;
      return ($scope.currentImageIndex = 0);
    };

    $scope.nextImg = function (idlot) {
      if ($scope.currentImageIndex + 1 === $scope.urls.length) {
        $scope.currentImageIndex = 0;
      } else {
        $scope.currentImageIndex += 1;
      }

      aapiBeacon('lotimage', { idlot, index: $scope.currentImageIndex, op: 'detail' });
    };

    return ($scope.previousImg = function (idlot) {
      if ($scope.currentImageIndex - 1 === -1) {
        $scope.currentImageIndex = $scope.urls.length - 1;
      } else {
        $scope.currentImageIndex -= 1;
      }

      aapiBeacon('lotimage', { idlot, index: $scope.currentImageIndex, op: 'detail' });
    });
  },
]);
