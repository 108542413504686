import angular from 'angular';
import localCss from './stylesheet.module.scss';

import './Display';
import './Navigation';
import './Back';

const template = require('./template.html');

class Ctrl {
  constructor() {
    this.localCss = localCss;
  }
}

const Filters = {
  template,
  controller: Ctrl,
  bindings: {
    back: '@',
    previousLot: '<',
    nextLot: '<',
    ngModelCurrencies: '=',
    ngModelDimensions: '=',
  },
};

angular.module('artprice_app').component('artpLotsShowFilters', Filters);
