/* eslint-disable */
import angular from 'angular';
import i18next from 'i18next';
import { toPairs } from 'lodash';

const errorsToLocales = {
  max: 'javascripts.locales.m28',
  min: 'javascripts.locales.m29',
  format: 'javascripts.locales.m6',
  required: 'javascripts.locales.m7',
  pattern: 'javascripts.locales.m6',
  email: 'javascripts.locales.m8',
  compare: 'javascripts.locales.m9',
  copiesForbidden: 'javascripts.locales.m10',
  notSignedHandForbidden: 'javascripts.locales.m11',
  notSignedStampsForbidden: 'javascripts.locales.m11',
  notSignedForbidden: 'javascripts.locales.m24',
  frameSizeBiggerThanImageSize: 'javascripts.locales.m12',
  cgvNotChecked: 'javascripts.locales.m13',
  textTooLong: 'javascripts.locales.m14',
  maxlength: 'javascripts.locales.m14',
  startAuctionPriceEur: 'javascripts.locales.m15',
  startAuctionPriceUsd: 'javascripts.locales.m16',
  startAuctionPriceGbp: 'javascripts.locales.m17',
  offerLessThanCurrentOffer: 'javascripts.locales.m21',
  weightGreaterThanZero: 'javascripts.locales.m18',
  usernameAvailable: 'javascripts.locales.m25',
  number: 'javascripts.locales.m26',
  dateDisabled: 'javascripts.locales.m27',
  passwordMismatch: 'api.wallet.customer_controller.m3',
};

angular.module('common_app').directive('ngInitToValue', () => ({
  require: 'ngModel',

  link(scope, element, attrs, ngModelCtrl) {
    if (!scope.$eval(attrs.ngModel)) {
      if (attrs.ngInitToValue !== '') {
        try {
          return ngModelCtrl.$setViewValue(scope.$eval(attrs.ngInitToValue));
        } catch (e) {
          try {
            return ngModelCtrl.$setViewValue(attrs.ngInitToValue);
          } catch (error) {
            e = error;
            return ngModelCtrl.$setViewValue(element.val());
          }
        }
      } else {
        return ngModelCtrl.$setViewValue(element.val());
      }
    }
  },
}));

angular.module('common_app').directive('customValidation', () => ({
  require: 'ngModel',

  link(scope, element, attrs, ctrl) {
    return scope.$watch(attrs.customValidation, value => {
      if (value) {
        ctrl.$setValidity('validationErrorKey', true);
        return document.getElementById(element.attr('id')).setCustomValidity('');
      }
      ctrl.$setValidity('validationErrorKey', false);
      return document.getElementById(element.attr('id')).setCustomValidity(attrs.customValidationMessage);
    });
  },
}));

angular.module('common_app').directive('compile', [
  '$compile',
  $compile => (scope, element, attrs) =>
    scope.$watch(
      scope => scope.$eval(attrs.compile),
      value => {
        if (value) {
          if (attrs.compileposition === 'after') {
            element.after(value);
            element.remove();
          } else {
            element.html(value);
          }
        }

        return $compile($(`.${attrs.compileclass}`).contents())(scope);
      },
    ),
]);

angular.module('common_app').directive('hideAfter', () => ({
  link(scope, element, attrs) {
    return setTimeout(() => element.hide('blind', {}, 500), attrs.hideAfter);
  },
}));

angular.module('common_app').directive('ngCompareWith', () => ({
  require: 'ngModel',

  link(scope, element, attrs, ngModelCtrl) {
    const check_validity = function () {
      if (ngModelCtrl.$viewValue !== scope.$eval(attrs.ngCompareWith)) {
        return ngModelCtrl.$setValidity('compare', false);
      }
      return ngModelCtrl.$setValidity('compare', true);
    };
    scope.$watch(attrs.ngCompareWith, check_validity);
    return scope.$watch(attrs.ngModel, check_validity);
  },
}));

angular.module('common_app').directive('apMaxlength', () => ({
  require: 'ngModel',

  link(scope, element, attrs, ngModelCtrl) {
    const maxlength = Number(attrs.apMaxlength);
    const fromUser = function (text) {
      if (text && text.length > maxlength) {
        const transformedInput = text.substring(0, maxlength);
        ngModelCtrl.$setViewValue(transformedInput);
        ngModelCtrl.$render();
        return transformedInput;
      }
      return text;
    };
    return ngModelCtrl.$parsers.push(fromUser);
  },
}));

angular.module('common_app').directive('scrollPosition', [
  '$window',
  $window =>
    function (scope, element, attrs) {
      const windowEl = angular.element($window);
      scope[attrs.scrollPosition] = false;
      return windowEl.on('scroll', () => scope.$apply(() => (scope[attrs.scrollPosition] = $('body').innerHeight() - window.innerHeight - windowEl.scrollTop() < 200)));
    },
]);

angular.module('common_app').directive('partial', [
  '$compile',
  '$http',
  ($compile, $http) => (scope, element, attrs) =>
    $http.get(attrs.partial, attr.partial_attributes).then(data => {
      element.html(data);
      return $compile(element.contents())(scope);
    }),
]);

angular.module('common_app').directive('ngModelCopyTo', () => ({
  require: 'ngModel',
  priority: 1000,

  link(scope, element, attrs, ngModelCtrl) {
    const target = attrs.ngModelCopyTo;

    const ngmodel = element.attr('ng-model');
    return scope.$watch(ngmodel, (new_value, old_value) => {
      if (new_value && new_value !== '') {
        return scope.$eval(`${attrs.ngModelCopyTo} = '${new_value}'`);
      }
    });
  },
}));

angular.module('common_app').directive('gsCleanWrapLink', () => ({
  priority: 1000,

  link(scope, element, attrs) {
    if (element.parent().prop('tagName') === 'A') {
      return element.unwrap();
    }
  },
}));

angular.module('common_app').directive('inputValidator', () => ({
  require: 'ngModel',
  priority: 1000,

  link(scope, element, attrs, ngModelCtrl) {
    const parent = element.parents('.form-group');
    parent.addClass('has-feedback');
    const element_feedback = $('<span class="form-control-feedback" aria-hidden="true"></span>');
    element.after(element_feedback);

    if (attrs.required) {
      const label = $(`[for='${attrs.name}']`);
      if (!/\*$/.test(label.html())) {
        label.html(`${label.html()} *`);
      }
    }

    element.removeAttr('input-validator');
    element.attr('ng-model-options', '{ updateOn: "blur" }');

    return scope.$watch(
      () => ngModelCtrl.$viewValue,
      new_value => {
        if (ngModelCtrl.$dirty && element.is(':visible')) {
          if (ngModelCtrl.$valid) {
            parent.removeClass('has-error');
            element_feedback.removeClass('fa fa-exclamation-triangle');
            parent.addClass('has-success');
            element_feedback.addClass('fa fa-check');
            return element.parent().children().remove('.form-control-feedback-error');
          }
          parent.removeClass('has-success');
          element_feedback.removeClass('fa fa-check');
          parent.addClass('has-error');
          element_feedback.addClass('fa fa-exclamation-triangle');

          element.parent().children().remove('.form-control-feedback-error');

          return (() => {
            const result = [];
            for (const pair of Array.from(toPairs(ngModelCtrl.$error))) {
              const type = pair[0];
              const value = pair[1];
              if (value && errorsToLocales[type]) {
                const element_error = $(`<div class=\"form-control-feedback-error\">${i18next.t(errorsToLocales[type])}</div>`);
                result.push(element.parent().append(element_error));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })();
        }
      },
    );
  },
}));
