/* eslint-disable */

window.artprice_app.controller('AccountPasswordCtrl', [
  '$timeout',
  function ($timeout) {
    const vm = this;
    vm.isMin = false;
    vm.isMaj = false;
    vm.isNum = false;
    vm.isSized = false;
    vm.showPwdInfo = false;
    vm.inputType = 'password';

    vm.initialize = function (form) {
      vm.form = form;
      $timeout(
        () =>
          (form.customer_pwd.$validators.pattern = function (modelValue, viewValue) {
            if (viewValue) {
              return vm.isMin !== null && vm.isMaj !== null && vm.isNum !== null && vm.isSized;
            } else {
              return true;
            }
          }),
        1000,
      );
      const angularSetPristine = vm.form.$setPristine.bind(vm.form);
      return (vm.form.$setPristine = function () {
        vm.showPwdInfo = false;
        vm.inputType = 'password';
        angularSetPristine();
        if (!form.customer_pwd.$validators.pattern) {
          return (form.customer_pwd.$validators.pattern = function (modelValue, viewValue) {
            if (viewValue) {
              return vm.isMin !== null && vm.isMaj !== null && vm.isNum !== null && vm.isSized;
            } else {
              return true;
            }
          });
        }
      });
    };

    vm.passwordInfo = visible => (vm.showPwdInfo = visible);

    vm.validatePwd = function () {
      const pwd = vm.form.customer_pwd.$viewValue;

      if (pwd) {
        vm.isMin = pwd.match(new RegExp(/[a-z]+/));
        vm.isMaj = pwd.match(new RegExp(/[A-Z]+/));
        vm.isNum = pwd.match(new RegExp(/\d+/));
        return (vm.isSized = pwd.length >= 8 && pwd.length <= 30);
      } else {
        vm.isMin = null;
        vm.isMaj = null;
        vm.isNum = null;
        return (vm.isSized = false);
      }
    };

    return vm;
  },
]);
