/* eslint-disable */
import _ from 'lodash';

window.artprice_app.controller('MarketplaceMessageCtrl', [
  '$sce',
  '$http',
  '$rootScope',
  '$scope',
  '$filter',
  'Classifiedmessage',
  'Classified',
  '$artpHttpErrorHandler',
  function ($sce, $http, $rootScope, $scope, $filter, Classifiedmessage, Classified, $artpHttpErrorHandler) {
    $scope.messages = [];
    $scope.displayUUID = '';
    $scope.displayToolbarUUID = '';
    $scope.displayReplyUUID = '';
    $scope.toolbarOption = [
      ['bold', 'italics', 'underline'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
    ];

    $scope.conversationForClassified = function (idclassified) {
      $scope.loadingConversations = true;
      return Classifiedmessage.messagesSummary(
        { idclassified },
        function (data) {
          if (data.conversations) {
            $scope.conversation = data.conversations[0];
          }
          return ($scope.loadingConversations = false);
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.retrieveMessages = function (page, idclassifedmessagestatute, idclassified) {
      $scope.loadingMessages = true;
      return Classifiedmessage.messagesSummary(
        { page, idclassifedmessagestatute, idclassified },
        function (data) {
          $scope.loadingMessages = false;
          return angular.extend($scope.messages, data);
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.toggleConversationDisplay = function (conversation, $event) {
      if ($event) {
        $event.stopPropagation();
      }
      if (conversation.uuid === $scope.displayUUID) {
        return ($scope.displayUUID = '');
      } else {
        $scope.displayUUID = conversation.uuid;
        return $scope.markConversationAsRead(conversation);
      }
    };

    $scope.toggleToolbar = uuid => ($scope.displayToolbarUUID = uuid);

    $scope.toggleReply = function (uuid, $event) {
      if ($event) {
        $event.stopPropagation();
      }
      if (uuid === $scope.displayReplyUUID) {
        return ($scope.displayReplyUUID = '');
      } else {
        return ($scope.displayReplyUUID = uuid);
      }
    };

    $scope.markConversationAsRead = function (conversation) {
      conversation.unread = 0;
      const messageIds = [];
      _.each(conversation.messages, function (message) {
        if (!message.read) {
          $rootScope.messagesUnreadCount -= 1;
          return messageIds.push(message.id);
        }
      });
      if (_.size(messageIds) > 0) {
        return Classifiedmessage.mark_as_read({ ids: messageIds }, _.noop, $artpHttpErrorHandler);
      }
    };

    $scope.deleteConversation = function (conversation, label_confirm_delete) {
      if (confirm(label_confirm_delete)) {
        return Classifiedmessage.deleteConversation(
          { idcustomer_interlocuteur: conversation.idinterlocutor, idclassified: conversation.idclassified },
          () => ($scope.messages.conversations = _.without($scope.messages.conversations, conversation)),
          $artpHttpErrorHandler,
        );
      }
    };

    $scope.createMessage = function (conversation, body) {
      if (body) {
        return Classifiedmessage.createMessage(
          {
            idcustomer_interlocutor: conversation.idinterlocutor,
            idclassified: conversation.idclassified,
            text: body,
          },
          function (message) {
            conversation.messages.unshift(message);
            return $scope.toggleReply(conversation.uuid);
          },
          $artpHttpErrorHandler,
        );
      }
    };

    return ($scope.createMessageFromShowCatalogue = function (idclassified, idinterlocutor, body) {
      $scope.loadingConversations = true;
      Classifiedmessage.createMessage(
        {
          idcustomer_interlocutor: idinterlocutor,
          idclassified,
          text: body,
        },
        (
          message, // On recharge les conversations
        ) => $scope.conversationForClassified(idclassified),
        $artpHttpErrorHandler,
      );
      return ($scope.sended = true);
    });
  },
]);
