import angular from 'angular';

class Ctrl {
  constructor($window, $sessionStorage) {
    this.$window = $window;
    this.$sessionStorage = $sessionStorage;
  }

  $onInit() {
    this.$window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: this.transId,
            affiliation: 'Artprice.com',
            revenue: this.revenue,
            tax: this.tax,
            shipping: this.shipping,
            coupon: this.coupon,
          },
          products: [
            {
              ...this.$sessionStorage.gtmProduct,
              coupon: this.coupon,
            },
          ],
        },
      },
    });
  }
}

const Component = {
  template: '<span></span>',
  controller: ['$window', '$sessionStorage', Ctrl],
  bindings: {
    coupon: '@',
    revenue: '@',
    tax: '@',
    shipping: '@',
    transId: '@',
  },
};

angular.module('artprice_app').component('artpSubscriptionPaymentOk', Component);
