import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import i18next from 'i18next';
import ngI18next from 'ng-i18next';
import angularCache from 'angular-cache';
import 'ngstorage';
import ngResource from 'angular-resource';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import ngCookies from 'angular-cookies';
import ngTouch from 'angular-touch';
import 'angular-file-upload';

// eslint-disable-next-line
window.i18next = i18next;

/* eslint-disable angular/window-service, angular/module-setter */
window.common_app = angular.module('common_app', [ngResource, 'ngStorage', ngSanitize, ngAnimate, uiBootstrap, ngCookies, 'angularFileUpload', ngI18next, angularCache, ngTouch]);

export default 'common_app';
