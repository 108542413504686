/* global ARTPRICE_VERSION */
import angular from 'angular';
import '../baseApplication';

const CACHE_KEY_PAGE = `artpJsonCachePage-${ARTPRICE_VERSION}`;

const CACHE_KEY_TEN_MINUTES = `artpJsonCacheTenMinutes-${ARTPRICE_VERSION}`;

const CACHE_KEY_SESSION = `artpJsonCacheSession-${ARTPRICE_VERSION}`;

angular.module('common_app').factory('artpJson', [
  '$http',
  '$q',
  'CacheFactory',
  ($http, $q, CacheFactory) => {
    CacheFactory(CACHE_KEY_PAGE, {
      storageMode: 'memory', // On stocke en mémoire
      deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
    });

    CacheFactory(CACHE_KEY_SESSION, {
      storageMode: 'sessionStorage', // On stocke en sessionStorage
      cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
      deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
    });

    CacheFactory(CACHE_KEY_TEN_MINUTES, {
      maxAge: 10 * 60 * 1000, // 10 minutes
      storageMode: 'localStorage', // On stocke en localStorage
      cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
      deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
    });

    return {
      /**
       * Effectue une requete vers artprice qui sera cachée pendant 10 minutes
       *
       * @param url
       * @param params
       * @param timeout : le canceller
       */
      queryWithTenMinutesCache: (url, params, timeout) =>
        $q((resolve, reject) => {
          $http
            .get(url, { timeout, cache: CacheFactory.get(CACHE_KEY_TEN_MINUTES), params: { format: 'json', ...params } })
            .then(xhrResult => {
              resolve(xhrResult.data);
            })
            .catch(error => {
              reject(error);
            });
        }),
      /**
       * Effectue une requete vers artprice qui sera cachée de facon
       *
       * @param url
       * @param params
       * @param timeout : le canceller
       */
      queryWithClassicCache: (url, params, timeout) =>
        $q((resolve, reject) => {
          $http
            .get(url, { timeout, cache: true, params: { format: 'json', ...params } })
            .then(xhrResult => {
              resolve(xhrResult.data);
            })
            .catch(error => {
              reject(error);
            });
        }),
      /**
       * Effectue une requete vers artprice qui sera cachée le temps de la vie de la SPA
       *
       * @param url
       * @param params
       * @param timeout : le canceller
       */
      queryWithPageCache: (url, params, timeout) =>
        $q((resolve, reject) => {
          $http
            .get(url, { timeout, cache: CacheFactory.get(CACHE_KEY_PAGE), params: { format: 'json', ...params } })
            .then(xhrResult => {
              resolve(xhrResult.data);
            })
            .catch(error => {
              reject(error);
            });
        }),
      /**
       * Effectue une requete vers artprice qui sera cachée le temps de la vie de la session browser
       *
       * @param url
       * @param params
       * @param timeout : le canceller
       */
      queryWithSessionCache: (url, params, timeout) =>
        $q((resolve, reject) => {
          $http
            .get(url, { timeout, cache: CacheFactory.get(CACHE_KEY_SESSION), params: { format: 'json', ...params } })
            .then(xhrResult => {
              resolve(xhrResult.data);
            })
            .catch(error => {
              reject(error);
            });
        }),
      query: (url, params, timeout) =>
        $q((resolve, reject) => {
          $http
            .get(url, { timeout, params: { format: 'json', ...params } })
            .then(xhrResult => {
              resolve(xhrResult.data);
            })
            .catch(error => {
              reject(error);
            });
        }),
      post: (url, params) =>
        $q((resolve, reject) => {
          $http
            .post(url, { format: 'json', ...params })
            .then(xhrResult => {
              resolve(xhrResult.data);
            })
            .catch(error => {
              reject(error);
            });
        }),
    };
  },
]);
