import angular from 'angular';

function factory($sessionStorage, $document) {
  this.$sessionStorage = $sessionStorage;
  if (!this.$sessionStorage.supportedInputTypes) {
    this.$sessionStorage.supportedInputTypes = {};
  }

  this.checkSupport = inputType => {
    if (!Object.keys(this.$sessionStorage.supportedInputTypes).includes([inputType])) {
      try {
        const input = $document[0].createElement('input');
        input.type = inputType;

        if (input.type === inputType) {
          this.$sessionStorage.supportedInputTypes[inputType] = true;
        } else {
          this.$sessionStorage.supportedInputTypes[inputType] = false;
        }
      } catch (e) {
        this.$sessionStorage.supportedInputTypes[inputType] = false;
      }
    }
    return this.$sessionStorage.supportedInputTypes[inputType];
  };

  return {
    checkSupport: this.checkSupport,
  };
}

angular.module('common_app').factory('html5InputsSupport', ['$sessionStorage', '$document', factory]);
