/* eslint-disable */
import { reduce, filter, uniqBy, each, map, find } from 'lodash';

if (angular.module('shopApplication')) {
  angular.module('shopApplication').config([
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    ($stateProvider, $urlRouterProvider, $locationProvider) => {
      $urlRouterProvider.otherwise('/shop/list');

      return $stateProvider
        .state('shop', {
          url: '/shop',
          abstract: true,
          templateUrl: 'shop',
          resolve: {
            cart: ['Physicproduct', Physicproduct => Physicproduct.getCart()],
          },
          controllerAs: 'abstractShopVm',
          controller: [
            'cart',
            '$rootScope',
            'Physicproduct',
            '$exceptionHandler',
            '$window',
            function (cart, $rootScope, Physicproduct, $exceptionHandler, $window) {
              let oldInnerWidth = 0;
              const updateTop = function () {
                const currentInnerWidth = $window.innerWidth;
                if (oldInnerWidth !== currentInnerWidth) {
                  oldInnerWidth = currentInnerWidth;
                  $window.scrollTo(0, 0);
                  const boxInitialTop = $('#shop-top').offset().top;
                  return ($window.onscroll = function () {
                    if ($(window).scrollTop() > boxInitialTop) {
                      return $('#shop-top').css({
                        position: 'fixed',
                        marginLeft: 0,
                      });
                    }
                    return $('#shop-top').css({
                      position: 'absolute',
                      marginLeft: '',
                    });
                  });
                }
              };

              const vm = this;
              vm.itemLoading = -1;
              vm.cart = cart.realItems;
              vm.totalItems = cart.totalItems;
              vm.totalPrices = cart.totalPrices;
              vm.loading = false;
              vm.addToCart = function (event, product) {
                event.preventDefault();
                event.stopImmediatePropagation();
                vm.itemLoading = product.id;
                Physicproduct.addToCart(product)
                  .then(() => (vm.itemLoading = -1))
                  .catch(err => {
                    vm.itemLoading = -1;
                    return $exceptionHandler(`Error n°1 on shop while adding product ${product.id} to cart : ${err.status} ${err.statusText}`);
                  });
              };
              vm.removeFromCart = function (event, product) {
                event.preventDefault();
                event.stopImmediatePropagation();
                vm.itemLoading = product.id;
                Physicproduct.removeFromCart(product)
                  .then(data => {
                    if (data.main_products.length < 1) {
                      $('#cart-dropdown-toggle').dropdown('toggle');
                    }
                    return (vm.itemLoading = -1);
                  })
                  .catch(err => {
                    vm.itemLoading = -1;
                    return $exceptionHandler(`Error n°2 on shop while removing product ${product.id} to cart : ${err.status} ${err.statusText}`);
                  });
              };
              vm.display_cart = () =>
                (window.location = `/payments/informations?from_shop=1&type=book${reduce(
                  vm.cart,
                  (memo, item) => {
                    if (item.shipping) {
                      return memo;
                    }
                    return `${memo}&idphysicproducts[${item.id}]=${item.quantity}`;
                  },
                  '',
                )}`);
              $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => (vm.loading = true));
              $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => (vm.loading = false));
              $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams) => (vm.loading = false));

              $window.onresize = e => updateTop();
              updateTop();

              return vm;
            },
          ],
        })
        .state('shop.list', {
          url: '/list',
          resolve: {
            products: ['$q', 'Physicproduct', ($q, Physicproduct) => $q(resolve => Physicproduct.shop({}, data => resolve(data)))],
            cart: ['Physicproduct', Physicproduct => Physicproduct.getCart()],
          },
          views: {
            main: {
              templateUrl: 'shop/list',
              controllerAs: 'shopListVm',
              controller: [
                '$scope',
                'products',
                '$sce',
                'Physicproduct',
                '$state',
                '$exceptionHandler',
                function ($scope, products, $sce, Physicproduct, $state, $exceptionHandler) {
                  const vm = this;
                  vm.products = products;
                  each(vm.products, (value, key, list) => {
                    value.title = $sce.trustAsHtml(value.title);
                    return (value.shortdesc = $sce.trustAsHtml(value.shortdesc));
                  });
                  vm.search = { idtheme: '!|' };
                  vm.others = false;
                  vm.searchProducts = filter(
                    uniqBy(
                      map(vm.products, o => {
                        if (o.idtheme) {
                          return { id: String(o.idtheme), label: o.themelabel };
                        }
                        return { id: '!', label: '-' };
                      }),
                      item => item.id,
                    ),
                    h => {
                      if (h.id === '!') {
                        const others = true;
                      }
                      return h.id !== '!';
                    },
                  );
                  vm.compareProducts = function (actual, expected) {
                    if (expected === 'others') {
                      return !actual;
                    }
                    return actual === parseInt(expected);
                  };
                  vm.addToCart = function (event, product) {
                    event.currentTarget.blur();
                    if (product.available_for_order === 0) {
                      $exceptionHandler(`Error n°6 on shop while adding product ${product.id} to cart : product is sold out !`);
                    } else {
                      $scope.abstractShopVm.itemLoading = product.id;
                      Physicproduct.addToCart(product)
                        .then(() => ($scope.abstractShopVm.itemLoading = -1))
                        .catch(err => {
                          $scope.abstractShopVm.itemLoading = -1;
                          return $exceptionHandler(`Error n°3 on shop while adding product ${product.id} to cart : ${err.status} ${err.statusText}`);
                        });
                    }
                  };
                  vm.specialProduct = find(vm.products, { id: 14 });
                  return vm;
                },
              ],
            },
          },
        })
        .state('shop.show', {
          url: '/show/:id',
          resolve: {
            product: ['$stateParams', '$q', 'Physicproduct', ($stateParams, $q, Physicproduct) => $q(resolve => Physicproduct.shop({ 'idphysicproducts[]': [$stateParams.id] }, data => resolve(data[0])))],
            cart: ['Physicproduct', Physicproduct => Physicproduct.getCart()],
          },
          views: {
            main: {
              templateUrl: 'shop/show',
              controllerAs: 'shopShowVm',
              controller: [
                '$scope',
                'product',
                '$sce',
                'Physicproduct',
                '$window',
                '$exceptionHandler',
                function ($scope, product, $sce, Physicproduct, $window, $exceptionHandler) {
                  const vm = this;
                  vm.showTechnicalDetailsLg = true;
                  vm.showTechnicalDetailsXs = false;
                  vm.product = product;
                  vm.product.title = $sce.trustAsHtml(vm.product.title);
                  vm.product.shortdesc = $sce.trustAsHtml(vm.product.shortdesc);
                  vm.product.longdesc = $sce.trustAsHtml(vm.product.longdesc);
                  vm.product.editioncomment = $sce.trustAsHtml(vm.product.editioncomment);
                  vm.product.technicaldetails = $sce.trustAsHtml(vm.product.technicaldetails);
                  vm.addToCart = function (event) {
                    event.currentTarget.blur();
                    if (vm.product.available_for_order === 0) {
                      $exceptionHandler(`Error n°5 on shop while adding product ${product.id} to cart : product is sold out !`);
                    } else {
                      $scope.abstractShopVm.itemLoading = vm.product.id;
                      Physicproduct.addToCart(vm.product)
                        .then(() => ($scope.abstractShopVm.itemLoading = -1))
                        .catch(err => {
                          $scope.abstractShopVm.itemLoading = -1;
                          return $exceptionHandler(`Error n°4 on shop while adding product ${product.id} to cart : ${err.status} ${err.statusText}`);
                        });
                    }
                  };
                  return vm;
                },
              ],
            },
            backlink: {
              templateUrl: 'shop/backlink',
            },
          },
        });
    },
  ]);
}
