import angular from 'angular';

function provider() {
  return {
    managedLocales: () => ['en', 'zh', 'fr', 'it', 'de', 'es'],
    flagsForLocales: () => ({
      en: 'gb',
      zh: 'cn',
      fr: 'fr',
      it: 'it',
      de: 'de',
      es: 'es',
    }),
    hostnamePrefixForLocales: () => ({
      en: 'www',
      zh: 'zh',
      fr: 'fr',
      it: 'it',
      de: 'de',
      es: 'es',
    }),
  };
}

angular.module('common_app').factory('artpLocales', provider);
