/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('Address', [
  '$resource',
  $resource => {
    return $resource(
      '/api/wallet/address/:action/:id/',
      {
        format: 'json',
      },
      {
        update_or_create: {
          method: 'POST',
          params: { action: 'update_or_create' },
        },
        delete: {
          method: 'DELETE',
          params: { action: 'delete' },
        },
        tvaformatregex: {
          method: 'GET',
          isArray: false,
          cache: true,
          params: { action: 'tvaformatregex' },
        },
        get_customer_addresses: {
          method: 'GET',
          isArray: false,
          params: { action: 'get_customer_addresses' },
        },
      },
    );
  },
]);
