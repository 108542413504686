/* eslint-disable */

import i18next from 'i18next';
import { filter, reject } from 'lodash'
const Cropper = require('cropperjs').default;

window.common_app.controller('ImageUploaderCtrl', [
  '$sce',
  '$scope',
  '$rootScope',
  '$http',
  'FileUploader',
  '$timeout',
  function ($sce, $scope, $rootScope, $http, FileUploader, $timeout) {
    $scope.max_sortorder = 0;

    $scope.imagesNotDeleted = images => filter(images, image => !image.deleted);

    $scope.send_crop = image =>
      execute_action(() =>
        $http.post(`/api/image/crop_image/${image.parent_id}?sortorder=${image.sortorder}&base_directory=${image.base_directory}`, {
          format: 'json',
          sortorder: $scope.image_to_retouch.sortorder,
          x: $scope.cropDetail.x,
          y: $scope.cropDetail.y,
          new_width: $scope.cropDetail.width,
          new_height: $scope.cropDetail.height,
          old_width: $scope.widthBeforeCrop,
          old_height: $scope.heightBeforeCrop,
          sha1: image.id,
        }),
      );

    var execute_action = function (action) {
      $scope.image_modification_running = true;
      return action.call().then(
        function (xhrResult) {
          const success_data = xhrResult.data;
          angular.extend($scope.image_to_retouch, success_data);
          return $scope.start_retouch($scope.image_to_retouch);
        },
        function (xhrResult) {
          const error = xhrResult.data;
          $scope.image_retouch_error = $sce.trustAsHtml(error);
          return $scope.start_retouch($scope.image_to_retouch);
        },
      );
    };

    $scope.rotate_right = image =>
      execute_action(() =>
        $http.post(`/api/image/rotate_right/${image.parent_id}?sortorder=${image.sortorder}&base_directory=${image.base_directory}`, {
          format: 'json',
          sortorder: $scope.image_to_retouch.sortorder,
          sha1: image.id,
        }),
      );

    $scope.rotate_left = image =>
      execute_action(() =>
        $http.post(`/api/image/rotate_left/${image.parent_id}?sortorder=${image.sortorder}&base_directory=${image.base_directory}`, {
          format: 'json',
          sortorder: $scope.image_to_retouch.sortorder,
          sha1: image.id,
        }),
      );

    $scope.deleteError = item => ($scope.file_uploader.error_queue = reject($scope.file_uploader.error_queue, el => el.name === item.name));

    $scope.delete_image = function (image) {
      if ($scope.delete_image_url) {
        $http.post(`${$scope.delete_image_url}/${image.id}`, {
          format: 'json',
        });
      }
      return (image.deleted = true);
    };

    $scope.start_crop = function () {
      const img_to_crop = document.getElementById('jcrop_target');
      $scope.crop_started = true;
      // Le timeout est nécessaire pour gérer les ngsrc & co
      return $timeout(
        () =>
          ($scope.jcrop_image = new Cropper(img_to_crop, {
            checkCrossOrigin: false,
            crop(e) {
              $scope.widthBeforeCrop = img_to_crop.width;
              $scope.heightBeforeCrop = img_to_crop.height;
              return ($scope.cropDetail = e.detail);
            },
          })),
      );
    };

    $scope.start_retouch = function (image) {
      if ($scope.jcrop_image) {
        $scope.jcrop_image.destroy();
      }
      delete $scope.jcrop_image;
      $scope.image_modification_running = false;
      $scope.image_to_retouch = image;
      $scope.crop_started = false;
      $('#jcrop_target').css('height', 'auto');
    };

    $scope.init_image_uploader = function (cparams, images_list, parent_id, images, base_directory, max) {
      let { create_image_url } = cparams;
      if (!create_image_url) {
        create_image_url = `/api/image/create_image/${parent_id}?base_directory=${base_directory}`;
      }
      $scope.delete_image_url = cparams.delete_image_url;
      angular.extend(images_list, images);
      $scope.images_list = images_list;
      $scope.max_images = max;
      $scope.file_uploader = $scope.init_new_file_uploader(create_image_url, [], $scope);
      $scope.file_uploader.onBeforeUploadItem = item => item.formData.push({ sortorder: next_sortorder() });
    };

    var next_sortorder = function () {
      if ($scope.max_sortorder === 0 && $scope.images_list) {
        for (let image of Array.from($scope.images_list)) {
          if (image.sortorder > $scope.max_sortorder) {
            $scope.max_sortorder = image.sortorder;
          }
        }
      }

      $scope.max_sortorder = $scope.max_sortorder + 1;
      return $scope.max_sortorder;
    };

    const file_size_control = function (file) {
      if (file.size > 7000000) {
        file.error = i18next.t('javascripts.locales.m22');
        return false;
      }
      return true;
    };

    const image_count_control = function (file) {
      if ($scope.max_images === -1) {
        return true;
      }
      if ($scope.validImagesSize() + $scope.file_uploader.queue.length >= $scope.max_images) {
        file.error = `Already ${$scope.max_images} images`;
        return false;
      }
      return true;
    };

    const image_only_control = function (file) {
      let type;
      if (FileUploader.isHTML5) {
        ({ type } = file);
      } else {
        type = '/' + file.value.slice(file.value.lastIndexOf('.') + 1);
      }
      type = '|' + type.toLowerCase().slice(type.lastIndexOf('/') + 1) + '|';
      const result = '|jpg|png|jpeg|bmp|'.indexOf(type) !== -1;
      if (!result) {
        file.error = i18next.t('javascripts.locales.m4');
      }
      return result;
    };

    $scope.hasImage = function (list) {
      let listHasImage = false;
      list.forEach(image => {
        if (image && !image.deleted) {
          return (listHasImage = true);
        }
      });
      return listHasImage;
    };

    $scope.hasGreatDimensions = function (image) {
      if (
        image &&
        !image.deleted &&
        (image.image_height === undefined || image.image_width === undefined || (image.image_height && parseInt(image.image_height, 10) >= 512) || (image.image_width && parseInt(image.image_width, 10) >= 512))
      ) {
        return true;
      }

      return false;
    };

    $scope.hasAnImageWithGreatDimensions = function (list) {
      let anImageHasGreatDimensions = false;
      list.forEach(image => {
        return (anImageHasGreatDimensions = anImageHasGreatDimensions || $scope.hasGreatDimensions(image));
      });

      return anImageHasGreatDimensions;
    };

    $scope.select_as_main = function (image) {
      for (let image_idx of Array.from($scope.images_list)) {
        if (image_idx.sortorder === 1) {
          image_idx.sortorder = image.sortorder;
        }
      }
      $http.post(`/api/image/switch_images/${image.parent_id}`, { format: 'json', sortorder_1: image.sortorder, sortorder_2: 1, base_directory: image.base_directory });
      return (image.sortorder = 1);
    };

    return ($scope.init_new_file_uploader = function (url_for_create_image, additionnal_filters, scope) {
      let filter;
      if (additionnal_filters == null) {
        additionnal_filters = [];
      }
      const filters = [image_count_control, file_size_control, image_only_control];
      const file_uploader = new FileUploader({
        scope,
        url: url_for_create_image,
        autoUpload: true,
        removeAfterUpload: true,

        formData: [{ format: 'json' }],
      });

      let i = 0;
      for (filter of Array.from(additionnal_filters)) {
        file_uploader.filters.push({
          name: `filter_${i}`,
          fn: filter,
        });
        ++i;
      }
      for (filter of [image_count_control, file_size_control, image_only_control]) {
        file_uploader.filters.push({
          name: `filter_${i}`,
          fn: filter,
        });
        ++i;
      }

      file_uploader.onCompleteAll = function () {
        if (file_uploader.queue.length === 0) {
          $rootScope.$emit('all_uploads_finished');
        }
        if ($scope.callback_all_uploads_finished && file_uploader.queue.length === 1) {
          return $scope.callback_all_uploads_finished();
        }
      };

      file_uploader.onAfterAddingFile = function (item) {
        $rootScope.$emit('starting_upload');
        if ($scope.callback_when_uploading) {
          return $scope.callback_when_uploading();
        }
      };

      file_uploader.onErrorItem = function (item, response, status, headers) {
        item.file.error = status;
        if (!file_uploader.error_queue) {
          file_uploader.error_queue = [];
        }
        if (file_uploader.isHTML5) {
          file_uploader.error_queue.push({ name: item.file.name, error: item.file.error });
        }
        if (!file_uploader.isHTML5) {
          return file_uploader.error_queue.push({ name: item._input[0].name, error: item._input[0].error });
        }
      };

      file_uploader.onWhenAddingFileFailed = function (file) {
        if (!file_uploader.error_queue) {
          file_uploader.error_queue = [];
        }
        if (file_uploader.isHTML5) {
          file_uploader.error_queue.push({ name: file.name, error: file.error });
        }
        if (!file_uploader.isHTML5) {
          return file_uploader.error_queue.push({ name: item._input[0].name, error: item._input[0].error });
        }
      };

      file_uploader.onSuccessItem = function (item, response, status, headers) {
        //hack pour Safari car il ne sait pas interpreter le retour (qui est au format text a cause de IE...)
        //unless response
        //  response=JSON.parse xhr.responseText
        //Fin du hack pour safari
        item.deleted = true;
        if ($scope.images_list.length === 0) {
          response.main = true;
        }
        return $scope.images_list.push(response);
      };

      return file_uploader;
    });
  },
]);
