import angular from 'angular';

angular.module('artprice_app').filter(
  'orderObjectBy',
  () =>
    function (items, field, reverse) {
      const filtered = [];
      angular.forEach(items, item => {
        if (angular.isObject(item) && item[field]) {
          return filtered.push(item);
        }
      });

      filtered.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }

        return 0;
      });

      if (reverse) {
        filtered.reverse();
      }

      return filtered;
    },
);
