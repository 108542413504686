/* eslint-disable */
import { guessNewUrlForLocale } from '../../../../../javascript/utils/links/locale';

window.artprice_app.controller('AccountCustomerCtrl', [
  '$sce',
  '$scope',
  '$uibModal',
  '$compile',
  'Customer',
  'Address',
  'Country',
  'Subscription',
  '$sessionStorage',
  'Artprice',
  '$timeout',
  '$artpHttpErrorHandler',
  function ($sce, $scope, $uibModal, $compile, Customer, Address, Country, Subscription, $sessionStorage, Artprice, $timeout, $artpHttpErrorHandler) {
    const isBlank = function (str) {
      if (!str) {
        return true;
      }
      if (str.trim() === '') {
        return true;
      }
      return false;
    };

    $scope.init_customer = function (id) {
      $scope.customer = {};
      return Customer.show({ id }, customer => angular.extend($scope.customer, customer), $artpHttpErrorHandler);
    };

    $scope.checkEmailFormat = function (value, error) {
      if (!$scope.registrationErrors) {
        $scope.registrationErrors = {};
      }
      if ($scope.registration[value].$error.email) {
        return ($scope.registrationErrors[error] = true);
      } else {
        return delete $scope.registrationErrors[error];
      }
    };

    $scope.modify_customer = function (customer, partial) {
      $scope.customer_to_update = new Customer(customer);
      $scope.modal = $uibModal.open({
        templateUrl: '/account/customer_edition_modal?p=' + partial,
        scope: $scope,
        size: 'lg',
      });
      return $scope.modal.result.then(
        function (customer) {
          angular.extend($scope.customer, customer);
          delete $scope.customer.errors;

          if (partial === 'email') {
            location.reload();
          } else if (partial === 'lang') {
            location.href = guessNewUrlForLocale(customer.lang);
          }
        },
        () => delete $scope.customer.errors,
      );
    };

    $scope.create_customer = function (event, url, fullRegistration) {
      $scope.processing_create_customer = true;
      event.preventDefault();
      $scope.customer_to_update = new Customer($scope.customer_to_update);
      $scope.customer_to_update.email = $scope.customer_to_update.login;

      if ($scope.address) {
        $scope.customer_to_update.billaddress = {};
        angular.extend($scope.customer_to_update.billaddress, $scope.address);
      }

      return $scope.customer_to_update.$update_or_create({}, function (customer) {
        if (customer.errors) {
          $scope.processing_create_customer = false;
          return angular.extend($scope.customer, customer);
        } else if (fullRegistration) {
          if (url) {
            return (window.location = url);
          } else {
            return (window.location = '/');
          }
        } else {
          return (window.location = '/account/new');
        }
      });
    };

    $scope.init_customer_for_validate_email = id =>
      Customer.show(
        { id },
        function (customer) {
          $scope.login = customer.login;
          $scope.customer_to_update = new Customer(customer);
          if ($sessionStorage.redirect_url_after_save_customer) {
            return ($scope.redirect_url_after_save_customer = $sessionStorage.redirect_url_after_save_customer);
          } else {
            return ($scope.redirect_url_after_save_customer = '/');
          }
        },
        $artpHttpErrorHandler,
      );

    $scope.validate_email = function (event) {
      event.preventDefault();
      return $scope.customer_to_update.$validate_email({}, function (customer) {
        if (!customer.errors) {
          angular.extend($scope.customer_to_update, customer);
          $scope.redirect_url_after_save_customer = $sessionStorage.redirect_url_after_save_customer;
          delete $sessionStorage.redirect_url_after_save_customer;
          return ($scope.email_is_valid = true);
        }
      });
    };

    $scope.save_customer = function (event) {
      let newPassword, oldEmail, oldLogin, oldPassword;
      event.preventDefault();
      if ($scope.customer_to_update.pwd) {
        newPassword = $scope.customer_to_update.pwd;
      }
      if ($scope.customer_to_update.pwd_original) {
        oldPassword = $scope.customer_to_update.pwd_original;
      }
      if ($scope.customer_to_update.login) {
        oldLogin = $scope.customer_to_update.login;
      }
      if ($scope.customer_to_update.email) {
        oldEmail = $scope.customer_to_update.email;
      }

      if ($scope.registration.$valid) {
        return $scope.customer_to_update.$update_or_create({}, function (customer) {
          if (customer.errors) {
            if (customer.errors && customer.errors.customer_pwd_original) {
              $scope.registration.customer_pwd_original.$valid = false;
              $scope.registration.customer_pwd_original.$invalid = true;
              $scope.registration.customer_pwd_original.$error.passwordMismatch = true;
            }

            if (newPassword) {
              $scope.customer_to_update.pwd = newPassword;
            }
            if (oldPassword) {
              $scope.customer_to_update.pwd_original = oldPassword;
            }
            if (oldEmail) {
              $scope.customer_to_update.email = oldEmail;
            }
            if (oldLogin) {
              return ($scope.customer_to_update.login = oldLogin);
            }
          } else {
            return $scope.modal.close(customer);
          }
        });
      }
    };

    $scope.delete_tvaintra = customer => Customer.delete_tvaintra({}, () => delete customer.tvaintra, $artpHttpErrorHandler);

    $scope.init_addresses = () =>
      Address.get_customer_addresses(
        {},
        function (addresses) {
          $scope.bill_address = addresses.bill;
          return ($scope.ship_address = addresses['ship']);
        },
        $artpHttpErrorHandler,
      );

    $scope.delete_address = function (obj) {
      const address = new Address(obj);
      return address.$delete({ id: address.id }, function (data) {
        $scope.customer = data;
        return $scope.init_addresses();
      });
    };

    return ($scope.modify_address = function (address, type_address, customer) {
      $scope.type_address = type_address;
      $scope.address_original = address;
      $scope.address = new Address(address);
      $scope.customer_to_update_original = customer;
      $scope.customer_to_update = new Customer(customer);

      if ($scope.address.id === null) {
        $scope.address.lastname = customer.lastname;
        $scope.address.firstname = customer.firstname;
        $scope.address.idtitle = customer.idtitle;
      }

      $scope.modal = $uibModal.open({
        templateUrl: '/account/address_edition_modal',
        size: 'lg',
        scope: $scope,
      });

      return $scope.modal.result.then(
        function (data) {
          if (data) {
            angular.extend($scope[type_address + '_address'], data.address);
            $scope.customer.tvaintra = data.customer.tvaintra;
            return angular.extend($scope.customer[type_address + 'address'], data.address);
          }
        },
        function (error) {
          if (error === 'backdrop click' || error === 'escape key press' || error === 'cancel') {
            // On ne fait rien c'est un click hors de la modal
          } else {
            throw error;
          }
        },
      );
    });
  },
]);
