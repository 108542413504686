import angular from 'angular';

angular.module('common_app').directive('validationStates', () => ({
  replace: true,
  transclude: true,

  template:
    '<span class="form-control-feedback {{class}} " >' +
    '<i ng-show="form[idInput].$valid && form[idInput].$viewValue!=\'\' && (!form[idInput].$pristine || (form.$submitted && form[idInput].$viewValue))" class="fa fa-check"></i>' +
    '<i ng-show="form[idInput].$invalid && (!form[idInput].$pristine || form.$submitted)" class="fa fa-exclamation-triangle"></i>' +
    '</span>',

  scope: {
    form: '=',
    idInput: '@',
    class: '@',
  },

  link(scope, element) {
    const form = $(element).parents('form')[0];

    const watchElement = function (input) {
      const parent = $(element).parents('.form-group');
      if (input && input.$valid && input.$viewValue !== '' && (!input.$pristine || (form.$submitted && input.$viewValue))) {
        parent.removeClass('has-error');
        if (input.$viewValue !== '') {
          return parent.addClass('has-success');
        }
        return parent.removeClass('has-success');
      }
      if (input && input.$invalid && (!input.$pristine || form.$submitted)) {
        parent.addClass('has-error');
        return parent.removeClass('has-success');
      }
    };

    scope.$watchCollection(`[form['${scope.idInput}'].$viewValue, form['${scope.idInput}'].$valid]`, (_newVals, _oldVals) => watchElement(form[scope.idInput]));

    return scope.$watch('form.$submitted', (_newVal, _oldVal) => watchElement(form[scope.idInput]));
  },
}));
