angular.module('common_app').config([
  '$logProvider',
  $logProvider => {
    const debugEnabled = process.env.NODE_ENV === 'development';
    // eslint-disable-next-line
    window.setDebug = value => {
      $logProvider.debugEnabled(value);
    };
    // eslint-disable-next-line
    window.setDebug(debugEnabled);
  },
]);
