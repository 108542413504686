import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($window) {
    this.localCss = localCss;
    this.$window = $window;
  }
}

const Back = {
  template,
  controller: ['$window', Ctrl],
  bindings: {
    url: '@',
  },
};

angular.module('artprice_app').component('artpLotsShowFiltersBack', Back);
