import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($window) {
    this.$window = $window;
    this.localCss = localCss;
    this.inputValue = '';
    this.params = [];
  }

  onBlur() {
    if (this.inputValue > this.max) {
      this.inputValue = this.max;
    } else if (this.inputValue < this.min) {
      this.inputValue = this.min;
    }
  }

  $onInit() {
    if (angular.isUndefined(this.min)) {
      this.min = 1;
    }
    if (!this.hiddenParams) {
      this.hiddenParams = {};
    }

    Object.entries(this.hiddenParams).forEach(([k, v]) => {
      if (angular.isArray(v)) {
        v.forEach(v1 => {
          this.params.push([`${k}[]`, v1]);
        });
      } else {
        this.params.push([k, v]);
      }
    });
  }
}

const GoToPage = {
  template,
  controller: ['$window', Ctrl],
  bindings: {
    action: '@',
    hiddenParams: '<?',
    max: '<',
    min: '<?',
    placeholder: '@',
  },
};

angular.module('artprice_app').component('artpSearchGoToPage', GoToPage);
