/* eslint-disable */

window.artprice_app.controller('CatalogueCtrl', [
  '$scope',
  'Catalogue',
  '$artpHttpErrorHandler',
  function ($scope, Catalogue, $artpHttpErrorHandler) {
    $scope.pages_context = {};

    $scope.isFirstPage = () => $scope.pages_context.current_page === 1;

    $scope.keydown = function (event) {
      if (event.keyCode === 39) {
        $scope.to_next_page();
      }
      if (event.keyCode === 37) {
        return $scope.to_previous_page();
      }
    };

    $scope.islastPage = () => $scope.pages_context.current_page === $scope.pages_context.page_count;

    $scope.previous_page = function (number) {
      if (number == null) {
        number = 1;
      }
      if ($scope.pages_context.current_page <= number) {
        return {};
      }
      return $scope.images[$scope.pages_context.current_page - 1 - number];
    };

    $scope.next_page = function (number) {
      if (number == null) {
        number = 1;
      }
      if ($scope.pages_context.current_page + number > $scope.pages_context.page_count) {
        return {};
      }
      return $scope.images[$scope.pages_context.current_page - 1 + number];
    };

    $scope.current_page = () => $scope.images[$scope.pages_context.current_page - 1];

    $scope.set_page_count = page_count => ($scope.pages_context.page_count = page_count);

    $scope.set_images = images => ($scope.images = images);

    $scope.set_current_page = function (page) {
      if (page >= 1 && page <= $scope.pages_context.page_count) {
        return ($scope.pages_context.current_page = page);
      }
    };

    $scope.to_next_page = () => $scope.set_current_page($scope.pages_context.current_page + 1);

    $scope.to_previous_page = () => $scope.set_current_page($scope.pages_context.current_page - 1);

    $scope.mayer_categories = function (id) {
      $scope.mayer_categories_datas = {};
      return Catalogue.mayer_categories({ id: id }, data => angular.extend($scope.mayer_categories_datas, data), $artpHttpErrorHandler);
    };
    $scope.mayer_alpha = function (id, label, offset) {
      $scope.mayer_alpha_datas = {};
      return Catalogue.mayer_alpha(
        { id, offset },
        function (data) {
          angular.extend($scope.mayer_alpha_datas, data);
          return ($scope.category_label = label);
        },
        $artpHttpErrorHandler,
      );
    };

    $scope.set_mayer_page_from_alpha = function (page, label) {
      $scope.pages_context.current_page = page;
      return ($scope.alpha_label = label);
    };

    $scope.mireur_letters = function (id, offset) {
      $scope.mireur_letters_datas = {};
      return Catalogue.mireur_letters({ id: id, offset: offset }, data => angular.extend($scope.mireur_letters_datas, data), $artpHttpErrorHandler);
    };

    return ($scope.set_mireur_page_from_letter = function (page, letter) {
      $scope.pages_context.current_page = page;
      return ($scope.letter = letter);
    });
  },
]);
