import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor() {
    this.localCss = localCss;
  }
}

const Paginate = {
  template,
  controller: Ctrl,
  bindings: {
    ngChange: '&',
    perPage: '<',
    ngModel: '=',
    totalItems: '<',
  },
};

angular.module('artprice_app').component('artpSearchPaginate', Paginate);
