/* eslint-disable */
import { extend } from 'lodash';

window.common_app.directive('artistSearchField', [
  () => ({
    priority: 0,
    replace: true,
    transclude: true,
    restrict: 'E',
    scope: false,
    require: '^ngModel',
    controllerAs: 'artistSearchFieldCtrl',

    controller: [
      '$scope',
      '$http',
      '$attrs',
      '$q',
      '$timeout',
      '$exceptionHandler',
      function ($scope, $http, $attrs, $q, $timeout, $exceptionHandler) {
        const vm = this;
        vm.displayDropdown = false;

        if ($attrs.ngFocus) {
          $(`#input-${$attrs.globalId}`).on('focus', function () {
            $scope.$eval(`${$attrs.ngFocus}`);
            return $scope.$apply();
          });
        }

        if ($attrs.ngBlur) {
          $(`#input-${$attrs.globalId}`).on('focusout', function (event) {
            if ($(`#${$attrs.globalId}`).has(document.activeElement).length === 0) {
              $scope.$eval(`${$attrs.ngBlur}`);
              return $scope.$apply();
            }
          });
        }

        const focusout = function (event) {
          if ($(`#${$attrs.globalId}`).has(document.activeElement).length === 0) {
            return looseFocus();
          }
        };

        const stopPropagation = event => event.stopPropagation();

        const openDropdown = function () {
          vm.displayDropdown = true;
          $(`#${$attrs.globalId}`).bind('click', stopPropagation);
          return $('body').bind('click', focusout);
        };

        const closeDropdown = function () {
          $(`#${$attrs.globalId}`).unbind('click', stopPropagation);
          $('body').unbind('click', focusout);
          delete $scope.term;
          return (vm.displayDropdown = false);
        };

        const activeIndex = function () {
          let index = 0;
          vm.fetchedArtists.forEach(function (artist, _index) {
            if (artist.active) {
              return (index = _index);
            }
          });
          return index;
        };

        $scope.$watch(
          $attrs.ngModel,
          function (newValue) {
            if (newValue) {
              $scope.selectedArtist = newValue;
            }
            if (newValue === '') {
              delete $scope.selectedArtist;
            }
            if (newValue && !newValue.id) {
              return delete $scope.selectedArtist;
            }
          },
          true,
        );

        vm.selectArtist = function (artist) {
          $scope.selectedArtist = artist;
          looseFocus();
          $scope.$eval(`${$attrs.ngModel}=artist`, { artist });
          if ($attrs.ngChange) {
            return $scope.$eval(`${$attrs.ngChange}`);
          }
        };

        vm.unselectActive = () => vm.fetchedArtists.forEach(artist => (artist.active = false));

        vm.setActive = function (artist) {
          vm.unselectActive();
          return (artist.active = true);
        };

        var looseFocus = function () {
          closeDropdown();
          return $timeout(() => $scope.$apply());
        };

        const postData = function (term, page) {
          const params = {
            term,
            page,
            per_page: 10,
          };
          extend(params, searchParams());
          vm.loading = true;
          if (vm.previousCanceler) {
            vm.previousCanceler.resolve();
          }
          vm.previousCanceler = $q.defer();
          vm.previousCanceler.promise.catch(() => ({
            // On ne fait rien , c'est juste le canceller
          }));
          const promise = $http({
            params,
            method: 'GET',
            url: '/api/artdbweb/artist/json_search.json',
            cache: true,
            timeout: vm.previousCanceler.promise,
          });
          const success = function (response) {
            vm.loading = false;
            return delete vm.previousCanceler;
          };
          const error = function (error) {
            // le -1 correspond a une requete cancelled
            if (error.status !== -1) {
              $exceptionHandler(error);
            }
            delete vm.previousCanceler;
            return (vm.loading = false);
          };
          promise.then(success, error);
          return promise;
        };

        vm.more = function () {
          ++vm.currentPage;
          const promise = postData($scope.term, vm.currentPage);
          const success = function (response) {
            vm.unselectActive();
            return response.data.artists.forEach(function (artist, index) {
              vm.unselectActive();
              if (index === 0) {
                artist.active = true;
              }
              vm.fetchedArtists.push(artist);
              return (vm.hasMore = response.data.count > 10 * vm.currentPage);
            });
          };
          const error = error => $exceptionHandler(error);
          return promise.then(success, error);
        };

        const arrowKeyDown = function (direction) {
          const previousActiveIndex = activeIndex();
          if (direction === +1) {
            if (previousActiveIndex < vm.fetchedArtists.length - 1) {
              vm.fetchedArtists[previousActiveIndex + 1].active = true;
              vm.fetchedArtists[previousActiveIndex].active = false;
            } else if (vm.hasMore) {
              if (!vm.loading) {
                vm.more();
              }
              vm.fetchedArtists[previousActiveIndex].active = false;
            }
          }
          if (direction === -1) {
            if (previousActiveIndex > 0) {
              vm.fetchedArtists[previousActiveIndex - 1].active = true;
              return (vm.fetchedArtists[previousActiveIndex].active = false);
            }
          }
        };

        $scope.keyDown = function (event) {
          if (event.keyCode === 27) {
            event.preventDefault();
            looseFocus();
          }
          if (event.keyCode === 40) {
            event.preventDefault();
            arrowKeyDown(+1);
          }
          if (event.keyCode === 38) {
            event.preventDefault();
            arrowKeyDown(-1);
          }
          if (event.keyCode === 13) {
            event.preventDefault();
            return vm.selectArtist(vm.fetchedArtists[activeIndex()]);
          }
        };

        var searchParams = function () {
          if ($attrs.searchParams) {
            return $scope.$eval($attrs.searchParams);
          }
          return {};
        };

        vm.startEdition = function () {
          delete $scope.selectedArtist;
          $timeout(() => $(`#input-${$attrs.globalId}`).focus());
          delete $scope.term;
          $scope.$eval(`${$attrs.ngModel}=artist`, { artist: undefined });
        };

        vm.getArtists = function (term) {
          vm.currentPage = 1;
          $scope.term = term;
          vm.tooShortTerm = false;
          if (!term || term.length === 0) {
            return looseFocus();
          } else if (term.length >= 2) {
            const promise = postData($scope.term, vm.currentPage).then(function (response) {
              openDropdown();
              vm.fetchedArtists = response.data.artists;
              if (vm.fetchedArtists && vm.fetchedArtists[0]) {
                vm.fetchedArtists[0].active = true;
              }
              vm.hasMore = response.data.count > 10 * vm.currentPage;
              return (vm.noResult = response.data.count === 0);
            });
            const error = function (error) {
              // le -1 correspond a une requete cancelled
              if (error.status !== -1) {
                return $exceptionHandler(error);
              }
            };
            return promise.catch(error);
          } else {
            vm.fetchedArtists = [];
            vm.tooShortTerm = true;
            openDropdown();
            return (vm.noResult = false);
          }
        };
      },
    ],

    template(elem, attrs, scope) {
      attrs.globalId = Math.random().toString(36).substring(7);
      let hiddenInputOptions = '';
      if (attrs.required) {
        hiddenInputOptions = hiddenInputOptions + `required='${attrs.required}'`;
      }
      if (attrs.ngRequired) {
        hiddenInputOptions = hiddenInputOptions + ` ng-required='${attrs.ngRequired}'`;
      }
      return `\
<div id='${attrs.globalId}' ng-keydown='keyDown($event)' class='ng-cloak artist-search-directive'>
<div class='box form-control no-transition' ng-show='selectedArtist' ng-bind-html='selectedArtist.html' ng-click='artistSearchFieldCtrl.startEdition()'></div>
<input id='input-${attrs.globalId}' placeholder=\"${attrs.placeholder}\"  ng-hide='selectedArtist' ng-disabled='selectedArtist' type='search' ng-model='term' ng-change='artistSearchFieldCtrl.getArtists(term)'  class='form-control no-transition input-artist'/>
<input type='text' ng-show='false' ng-model='selectedArtist.id' name='${attrs.name}' ${hiddenInputOptions}>
<ul ng-keydown='keyDown($event)' class='artist-search-directive-dropdown-menu' ng-if='artistSearchFieldCtrl.displayDropdown'>
  <li ng-hide='artistSearchFieldCtrl.fetchedArtists.length == 0' class='help'><span ng-bind="'javascripts.locales.m1' | i18next"></span></li>
  <li class='divider' ng-hide='artistSearchFieldCtrl.noResult || artistSearchFieldCtrl.tooShortTerm'></li>
  <li ng-repeat='artist in artistSearchFieldCtrl.fetchedArtists' ng-bind-html='artist.html' ng-class='{active: artist.active}' class='sln-artist-suggestion pointer' ng-click='artistSearchFieldCtrl.selectArtist(artist)' ng-mouseover='artistSearchFieldCtrl.setActive(artist)'></li>
  <li id='spinner-${attrs.globalId}' ng-show='artistSearchFieldCtrl.loading'><i class='fa fa-spinner fa-spin fa-2x pull-right marg marg-r-10'></i></li>
  <li ng-show='!artistSearchFieldCtrl.loading && artistSearchFieldCtrl.hasMore && !artistSearchFieldCtrl.tooShortTerm' ng-click='artistSearchFieldCtrl.more()'><div class='pull-right pointer' ng-bind="'javascripts.locales.m3' | i18next"></div></li>
  <li ng-show='artistSearchFieldCtrl.noResult' class='help' ><span class='pull-right marg marg-t-25' ng-bind-html="'javascripts.locales.m20' | i18next | strReplace:'%search%':term"></span></li>
  <li ng-show='artistSearchFieldCtrl.tooShortTerm' class='help'><span class='pull-right' ng-bind="'javascripts.locales.m2' | i18next"></span></li>
</ul>
</div>`;
    },
  }),
]);
