import paypalCheckout from 'paypal-checkout';

function getBillingAddress(vm) {
  const allowedIso2 = ['fr', 'us', 'de', 'it', 'es'];
  if (!allowedIso2.includes(vm.iso2Country.toLowerCase())) {
    return undefined;
  }
  if (['fr'].includes(vm.iso2Country.toLowerCase()) && (!vm.postalCode || vm.postalCode === '')) {
    return undefined;
  }
  if (['us', 'it'].includes(vm.iso2Country.toLowerCase()) && (!vm.state || vm.state === '')) {
    return undefined;
  }
  if (!vm.city || vm.city === '') {
    return undefined;
  }
  if (!vm.line1 || vm.line1 === '') {
    return undefined;
  }
  return {
    state: vm.state,
    postal_code: vm.postalCode,
    line1: vm.line1,
    city: vm.city,
    country_code: vm.iso2Country.toUpperCase(),
  };
}

export default vm =>
  async function createPayment() {
    vm.gtmCheckout('paypal');
    const paypalPlatform = vm.onlinePlatforms && vm.onlinePlatforms.find(platform => platform.id === vm.PAYPAL_PLATFORM_ID);
    const env = vm.paypalEnv;
    // eslint-disable-next-line prefer-destructuring
    const client = this.props.client;
    const params = {
      payer: {
        payer_info: {
          email: vm.email,
          first_name: vm.firstname,
          last_name: vm.lastname,
          billing_address: getBillingAddress(vm),
        },
      },
      application_context: {
        brand_name: 'Artprice.com',
        shipping_preference: 'NO_SHIPPING',
      },
      transactions: [
        {
          invoice_number: vm.idpayment,
          description: vm.description,
          amount: { total: paypalPlatform.parameters.amount, currency: paypalPlatform.parameters.currency_code },
        },
      ],
    };
    /*
     * On essaye une première fois en poussant toute sles infos à notre dispo
     * Si ca merde, on dégage l'adresse que paypal valide avec blocage de l'iframe en cas d'adresse invalide
     */
    try {
      return await paypalCheckout.rest.payment.create(env, client, params);
    } catch (exception) {
      // eslint-disable-next-line
    console.error(exception);
      delete params.payer.payer_info.billing_address;
      try {
        return await paypalCheckout.rest.payment.create(env, client, params);
      } catch (exception2) {
        vm.$exceptionHandler(exception2);
        throw exception2;
      }
    }
  };
