import angular from 'angular';

const interceptor = [
  '$q',
  $q => ({
    responseError(rejection) {
      const { status } = rejection;
      if (status === 401) {
        if (rejection.data && rejection.data.partial) {
          window.location = `/identity/${rejection.data.partial}`;
        } else {
          window.location = '/identity';
        }
        return rejection;
      }
      if (status === 429) {
        window.location.replace('/security/are_you_human');
        return rejection;
      }
      return $q.reject(rejection);
    },
  }),
];

angular.module('common_app').config(['$httpProvider', $httpProvider => $httpProvider.interceptors.push(interceptor)]);
