/* eslint-disable */

import angular from 'angular';

angular.module('artprice_app').factory('Indicator', [
  '$resource',
  $resource => {
    return $resource(
      '/api/artdbweb/indicator/:action/:id/',
      {
        format: 'json',
      },
      {
        show: {
          cache: true,
          method: 'get',
          isArray: false,
          params: { action: 'show' },
        },
      },
    );
  },
]);
