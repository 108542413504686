/* eslint-disable */

window.artprice_app.controller('MarketplaceDecorativeCtrl', [
  '$scope',
  '$http',
  function ($scope, $http) {
    $http.get('/api/wallet/classified/decoratives_classification?format=json', {}).then(function (xhrResult) {
      const { data } = xhrResult;
      return ($scope.decorative_classification = data);
    });

    $http.get('/api/wallet/classified/not_finart_origin_tree', {}).then(function (xhrResult) {
      const { data } = xhrResult;
      return ($scope.not_finart_origin_tree = data);
    });

    $http.get('/api/wallet/classified/decorative_period_style', {}).then(function (xhrResult) {
      const { data } = xhrResult;
      $scope.decorative_period_style = data;
      $scope.all_decorative_styles = {
        children: [],
      };
      return (() => {
        const result = [];
        for (let period of Array.from($scope.decorative_period_style)) {
          if (period.children !== null) {
            result.push(Array.from(period.children).map(style => $scope.all_decorative_styles.children.push(style)));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    });

    return $http.get('/api/wallet/classified/not_finart_materials_tree', {}).then(function (xhrResult) {
      const { data } = xhrResult;
      return ($scope.not_finart_materials_tree = data);
    });
  },
]);
