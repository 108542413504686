import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor(Customer, $artpHttpErrorHandler, jwt) {
    this.Customer = Customer;
    this.localCss = localCss;
    this.userToken = jwt.decodedToken();

    this.userContext = {
      logged: this.userToken.logged,
    };
    const success = data => angular.extend(this.userContext, data);
    Customer.get_user_context({ idcustomer: this.userToken.idcustomer }, success, $artpHttpErrorHandler);
  }
}

const Component = {
  template,
  controller: ['Customer', '$artpHttpErrorHandler', 'jwt', Ctrl],
  bindings: {
    activeTab: '<',
  },
};

angular.module('artprice_app').component('artpAccountMenu', Component);
