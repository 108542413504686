/* eslint-disable */

const RandExp = require('randexp');
const angular = require('angular');

window.common_app.controller('AccountAddressCtrl', [
  '$sce',
  '$scope',
  '$compile',
  'Address',
  'Country',
  'Customer',
  '$timeout',
  'Artprice',
  '$artpHttpErrorHandler',
  function ($sce, $scope, $compile, Address, Country, Customer, $timeout, Artprice, $artpHttpErrorHandler) {
    $scope.showTvaInfo = false;
    $scope.same_ship_and_bill_address = $scope.customer_to_update.verified_at === null;

    Artprice.retrieved_from_helper_for_select({ helper: 'country_to_json' }, data => ($scope.countries = data), $artpHttpErrorHandler);

    $scope.tvaInfo = visible => ($scope.showTvaInfo = visible);

    $scope.isBlank = function (str) {
      if (!str) {
        return true;
      }
      if (str.trim() === '') {
        return true;
      }
      return false;
    };

    $scope.lockTvaIntra = function (locked) {
      $scope.lockedTvaintra = locked;
      if (locked) {
        $scope.registration.tvaintra.$viewValue;
        $scope.customer_to_update.tvaintra = '';
        $scope.registration.tvaintra.$valid = false;
        $scope.registration.tvaintra.$invalid = false;
        delete $scope.registration.$error.pattern;
        delete $scope.registration.tvaintra.$validators.pattern;
        return delete $scope.registration.tvaintra.$error.pattern;
      } else {
        const idcountry = $scope.idcountrySelected;
        $scope.idcountrySelected = 0;
        return $scope.tvaformatregexFromIdcountry(idcountry);
      }
    };

    $scope.displayVatIntraField = function () {
      if ($scope.tvaformatregex && $scope.type_address === 'bill' && !$scope.isBlank($scope.address.firm)) {
        return true;
      }
      return false;
    };

    $scope.tvaformatregexFromIdcountry = function (idcountry) {
      if ($scope.idcountrySelected !== idcountry) {
        $scope.idcountrySelected = idcountry;
        return Country.show(
          { id: idcountry },
          function (country) {
            if (country.tvaformatregex) {
              $scope.countryWithTvaIntra = true;
              $scope.tvaformatregex = country.tvaformatregex;
              $scope.sampleTva = new RandExp($scope.tvaformatregex).gen();
              return $timeout(function () {
                if ($scope.registration && $scope.registration['tvaintra']) {
                  return ($scope.registration.tvaintra.$validators.pattern = (modelValue, viewValue) => $scope.registration.tvaintra.$viewValue.match(new RegExp($scope.tvaformatregex)) !== null);
                }
              });
            }
          },
          $artpHttpErrorHandler,
        );
      }
    };

    $scope.toggle_same_ship_and_bill_address = () => ($scope.same_ship_and_bill_address = !same_ship_and_bill_address);

    return ($scope.save_address = function (event) {
      event.preventDefault();
      $scope.registration.$setSubmitted();
      return $scope.address.$update_or_create(
        { lockedTvaintra: $scope.lockedTvaintra, type_address: $scope.type_address, tvaintra: $scope.customer_to_update.tvaintra, same_ship_and_bill_address: $scope.same_ship_and_bill_address },
        function (address) {
          if (address.errors && Object.keys(address.errors).length > 0) {
            return angular.extend($scope.address, address);
          } else {
            angular.extend($scope.address_original, address);
            $scope.modal.close({ address, customer: $scope.customer_to_update });

            if ($scope.same_ship_and_bill_address) {
              const other_type_address = $scope.type_address === 'ship' ? 'bill' : 'ship';
              angular.extend($scope[other_type_address + '_address'], address);
              return angular.extend($scope.customer[other_type_address + 'address'], address);
            }
          }
        },
      );
    });
  },
]);
