import angular from 'angular';

angular.module('common_app').directive('bindHtmlUnsafe', [
  '$sce',
  $sce => ({
    scope: { bindHtmlUnsafe: '=' },
    template: "<span ng-bind-html='trustedHtml'></span>",

    link(scope) {
      scope.updateView = newValue => (scope.trustedHtml = $sce.trustAsHtml(newValue));

      return scope.$watch('bindHtmlUnsafe', newVal => scope.updateView(newVal));
    },
  }),
]);
