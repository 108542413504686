import angular from 'angular';
import jwtDecode from 'jwt-decode';

function provider($cookies) {
  return {
    token: () => $cookies.get('customer-jwt'),
    decodedToken: () => {
      const customerJwt = $cookies.get('customer-jwt');
      if (customerJwt) {
        return { ...jwtDecode(customerJwt) };
      }
      return {};
    },
  };
}

angular.module('common_app').factory('jwt', ['$cookies', provider]);
