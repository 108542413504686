import angular from 'angular';

angular.module('common_app').factory('Classifiedmessage', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/classifiedmessage/:action/:id',
      { format: 'json' },
      {
        messagesSummary: {
          method: 'GET',
          isArray: false,
          params: { action: 'messages_summary' },
        },
        deleteConversation: {
          method: 'GET',
          isArray: true,
          params: { action: 'delete_conversation' },
        },
        createMessage: {
          method: 'POST',
          params: { action: 'create_message' },
        },
        mark_as_read: {
          method: 'PUT',
          params: { action: 'mark_as_read' },
        },
      },
    ),
]);
