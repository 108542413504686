import angular from 'angular';

angular.module('common_app').directive('forceBlurBeforeSubmit', () => ({
  restrict: 'A',
  priority: 10,

  link(scope, element, _attrs) {
    return element.on('submit', () =>
      $('input[ng-model]', element).each((index, item) => {
        if (item.attributes.type.value !== 'checkbox' && item.attributes.type.value !== 'radio') {
          // Pour récupérer les valeurs auto remplies par le browser
          angular.element(item).controller('ngModel').$setViewValue($(item).val());
          // Blur sur chaque element
          return $(item).blur();
        }
      }),
    );
  },
}));
