/* eslint-disable */
const Mailcheck = require('mailcheck');
const RandExp = require('randexp');

window.artprice_app.controller('AccountRegistrationCtrl', [
  'Customer',
  'Country',
  'Artprice',
  'Address',
  '$timeout',
  '$sce',
  '$compile',
  '$artpHttpErrorHandler',
  'Activity',
  '$window',
  function (Customer, Country, Artprice, Address, $timeout, $sce, $compile, $artpHttpErrorHandler, Activity, $window) {
    const vm = this;
    vm.inputType = 'password';
    // step 0 pour le choix pro pas pro
    vm.step = 1;
    vm.processing = false;
    vm.idcountrySelected = 0;
    vm.customer = {};
    vm.buff = Math.random().toString(36).substring(7);
    vm.showTvaInfo = false;
    vm.lockedTvaintra = false;
    vm.showTvaSkip = true;
    vm.tvaFormatRegex = '';
    vm.isMin = false;
    vm.isMaj = false;
    vm.isNum = false;
    vm.isSized = false;
    vm.suggestedEmail = undefined;
    vm.errors = [];

    $timeout(function () {
      vm.formRegistration.customer_pwd.$validators.pattern = function (modelValue, viewValue) {
        if (viewValue) {
          return vm.isMin !== null && vm.isMaj !== null && vm.isNum !== null && vm.isSized;
        } else {
          return true;
        }
      };
      return (vm.formRegistration.policies.$validators.cgvNotChecked = (modelValue, viewValue) => viewValue);
    }, 1000);

    Artprice.retrieved_from_helper_for_select({ helper: 'country_to_json' }, data => (vm.countries = data), $artpHttpErrorHandler);

    Artprice.retrieved_from_helper_for_select({ helper: 'title_to_json' }, data => (vm.titles = data), $artpHttpErrorHandler);

    Artprice.retrieved_from_helper_for_select({ helper: 'activities_to_json' }, data => (vm.activities = data), $artpHttpErrorHandler);

    vm.initialize = function (returnUrl, fullRegistration, fromArtron) {
      vm.fullRegistration = fullRegistration;
      vm.fromArtron = fromArtron;
      return (vm.returnUrl = returnUrl);
    };

    vm.chooseSuggestion = function () {
      vm.customer.email = vm.suggestedEmail;
      vm.customer.login = vm.suggestedEmail;
      return delete vm.suggestedEmail;
    };

    vm.blurEmail = function () {
      if (vm.customer?.errors?.login) {
        delete vm.customer.errors.login;
      }

      vm.customer.email = vm.formRegistration.customer_login.$viewValue;
      delete vm.suggestedEmail;
      return Mailcheck.run({
        email: vm.formRegistration.customer_login.$viewValue,
        suggested(suggestion) {
          return (vm.suggestedEmail = suggestion.full);
        },
      });
    };

    vm.isBlank = function (str) {
      if (!str) {
        return true;
      }
      if (str.trim() === '') {
        return true;
      }
      return false;
    };

    vm.cleanTvaintra = function () {
      if (vm.formRegistration.tvaintra.$viewValue) {
        const cleanTvaintra = vm.formRegistration.tvaintra.$viewValue.replace(/\W+/g, '');
        vm.formRegistration.tvaintra.$setViewValue(cleanTvaintra);
        return vm.formRegistration.tvaintra.$render();
      }
    };

    vm.tvaformatregexFromIdcountry = function (idcountry) {
      if (vm.idcountrySelected !== idcountry) {
        vm.idcountrySelected = idcountry;
        return Country.show(
          { id: idcountry },
          function (country) {
            if (country.tvaformatregex) {
              vm.tvaFormatRegex = country.tvaformatregex;
              vm.sampleTva = new RandExp(vm.tvaFormatRegex).gen();
              return $timeout(function () {
                if (vm.formRegistration['tvaintra']) {
                  return (vm.formRegistration['tvaintra'].$validators.pattern = (modelValue, viewValue) => vm.formRegistration['tvaintra'].$viewValue.match(new RegExp(vm.tvaFormatRegex)) !== null);
                }
              });
            }
          },
          $artpHttpErrorHandler,
        );
      }
    };

    vm.tvaInfo = visible => (vm.showTvaInfo = visible);

    vm.displayVatIntraField = function () {
      if (vm.tvaFormatRegex && !vm.isBlank(vm.address.firm)) {
        return true;
      }
      return false;
    };

    vm.passwordInfo = visible => (vm.showPwdInfo = visible);

    vm.validatePwd = function () {
      const pwd = vm.formRegistration.customer_pwd.$viewValue;
      if (pwd) {
        vm.isMin = pwd.match(new RegExp(/[a-z]+/));
        vm.isMaj = pwd.match(new RegExp(/[A-Z]+/));
        vm.isNum = pwd.match(new RegExp(/\d+/));
        return (vm.isSized = pwd.length >= 8 && pwd.length <= 30);
      } else {
        vm.isMin = null;
        vm.isMaj = null;
        vm.isNum = null;
        return (vm.isSized = false);
      }
    };

    vm.lockTvaIntra = function () {
      if (vm.lockedTvaintra) {
        vm.showTvaInfo = false;
        vm.formRegistration.tvaintra.$viewValue = '';
        vm.customer.tvaintra = '';
        vm.formRegistration.tvaintra.$valid = false;
        vm.formRegistration.tvaintra.$invalid = false;
        delete vm.formRegistration['tvaintra'].$validators.pattern;
        return delete vm.formRegistration.$error.pattern;
      } else {
        const idcountry = vm.idcountrySelected;
        vm.idcountrySelected = 0;
        return vm.tvaformatregexFromIdcountry(idcountry);
      }
    };

    vm.gtmAccountSubscriptionForm = function (registrationType, customer) {
      return Activity.show({ id: customer.idactivity }, () =>
        $window.dataLayer.push({
          event: 'account-subscription-form',
          'registration-type': registrationType,
        }),
      );
    };

    vm.setProfessional = isPro =>
      $timeout(function () {
        vm.customer.isProfessional = isPro;
        return (vm.step = 1);
      });

    vm.checkCaptcha = event => {
      event.preventDefault();
      if (vm.policies === '0') {
        vm.formRegistration.policies.$setValidity('cgvNotChecked', false, vm);
      }

      vm.formRegistration.$setSubmitted();
      if (vm.formRegistration.$valid) {
        if ($window.grecaptcha) {
          return $window.grecaptcha.execute();
        } else {
          return vm.errors.push('grecaptcha not loaded');
        }
      }
    };

    vm.nextStep = () =>
      $timeout(function () {
        if ($window.grecaptcha) {
          vm.customer.gRecaptchaResponse = $window.grecaptcha.getResponse();
        }

        if (vm.policies === '0') {
          vm.formRegistration.policies.$setValidity('cgvNotChecked', false, vm);
        }

        if (vm.fullRegistration && vm.step === 1) {
          vm.formRegistration.$setSubmitted();
          vm.formRegistration.$setPristine(true);

          if ($window.grecaptcha) {
            setTimeout(() => $window.grecaptcha.reset(), 500);
          }

          return (vm.step = 2);
        } else {
          if (vm.step === 2) {
            vm.gtmAccountSubscriptionForm('full', vm.customer);
          } else {
            vm.gtmAccountSubscriptionForm('express', vm.customer);
          }
          if (vm.formRegistration.$valid) {
            return vm.createCustomer();
          }
        }
      });

    $window.nextStep = vm.nextStep;

    vm.createCustomer = function () {
      const { pwd } = vm.customer;
      vm.processing = true;
      vm.customer = new Customer(vm.customer);
      vm.customer.lockedTvaintra = vm.lockedTvaintra;

      if (vm.fullRegistration) {
        vm.customer.billaddress = {};
        angular.extend(vm.customer.billaddress, vm.address);
      }

      return vm.customer.$update_or_create({}, function (customer) {
        if (customer.errors) {
          angular.extend(vm.customer, customer); // On remet le mot de passe saisie car il revient vide de la validation
          vm.customer.pwd = pwd;
          setTimeout(() => $window.grecaptcha?.reset(), 500);

          if (customer.errors.login || customer.errors.pwd) {
            vm.step = 1;
          }

          return (vm.processing = false);
        } else if (vm.fullRegistration) {
          if (vm.fromArtron) {
            Customer.sourcingArtron({ id: 'artron' });
          }

          if (vm.returnUrl) {
            return (window.location = vm.returnUrl);
          } else {
            return (window.location = '/');
          }
        } else {
          return (window.location = '/account/new');
        }
      });
    };

    return vm;
  },
]);
