/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('Category', [
  '$resource',
  $resource => {
    return $resource(
      '/api/artdbweb/category/:action/:id/',
      {
        format: 'json',
      },
      {
        classified: {
          method: 'get',
          isArray: true,
          params: { action: 'classified' },
        },
        estimate: {
          method: 'get',
          params: { action: 'estimate' },
        },
        default: {
          method: 'get',
          params: { action: 'default' },
        },
      },
    );
  },
]);
