/* eslint-disable */
window.artprice_app.controller('AccountResetPasswordCtrl', [
  'Customer',
  '$window',
  '$artpHttpErrorHandler',
  function (Customer, $window, $artpHttpErrorHandler) {
    const vm = this;
    vm.success = false;

    vm.initialize = function (idcustomer, token) {
      vm.token = token;
      return (vm.idcustomer = idcustomer);
    };

    vm.submitResetPassword = function (event) {
      event.preventDefault();

      vm.formResetPassword.$setSubmitted();
      if (vm.formResetPassword.$valid) {
        return Customer.submitResetPassword(
          { id: vm.idcustomer, token: vm.token, pwd: vm.customer_pwd },
          function (data) {
            if (data.result === 'ok') {
              return ($window.location = '/');
            } else {
              return ($window.location = '/account/forgot_password');
            }
          },
          $artpHttpErrorHandler,
        );
      }
    };

    return vm;
  },
]);
