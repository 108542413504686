import angular from 'angular';

angular.module('common_app').factory('$artpModalErrorHandler', [
  '$exceptionHandler',
  'jwt',
  ($exceptionHandler, jwt) =>
    /* eslint-disable func-names */
    function (modalError) {
      if (modalError === 'backdrop click' || modalError === 'escape key press' || modalError === 'cancel' || angular.isUndefined(modalError)) {
        // On ne fait rien c'est un click hors de la modal
      } else {
        const userToken = jwt.decodedToken();
        if (userToken.idcustomer) {
          // Si ca n'est pas un vrai client, on ne fait rien, trop d'erreurs venant des moteurs la dessus
          $exceptionHandler(modalError);
        }
      }
    },
]);
