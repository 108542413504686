import angular from 'angular';

angular.module('artprice_app').filter(
  'totalInArrayOfHashes',
  () =>
    function (datas, key) {
      let total = 0;
      if (datas) {
        for (const data of Array.from(datas)) {
          let buff_data = data;
          for (const subkey of Array.from(key.split('.'))) {
            buff_data = buff_data[subkey];
          }
          total += buff_data;
        }
      }
      return total;
    },
);
