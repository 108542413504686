import angular from 'angular';
import { each } from 'lodash';

angular.module('artprice_app').factory('Physicsale', [
  '$resource',
  $resource => {
    const physicsale = $resource(
      '/api/wallet/physicsale/:action/:id/',
      {
        format: 'json',
      },
      {
        contactByArtron: {
          params: { action: 'contact_by_artron' },
        },
        checkPayment: {
          params: { action: 'check_payment_status' },
        },
      },
    );

    physicsale.get_total_price = function (cart) {
      let t = 0;
      each(cart, item => (t += item.price * item.quantity));
      return t;
    };

    return physicsale;
  },
]);
