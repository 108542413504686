/* eslint-disable */
import i18next from 'i18next';
import _ from 'lodash';
import { aapiBeacon } from '../../../../javascript/services/analytics/aapi';

window.common_app.controller('ApplicationCtrl', [
  '$http',
  '$scope',
  'FileUploader',
  '$timeout',
  '$uibModal',
  '$locale',
  '$artpHttpErrorHandler',
  'Customer',
  '$artpModalErrorHandler',
  '$log',
  function ($http, $scope, FileUploader, $timeout, $uibModal, $locale, $artpHttpErrorHandler, Customer, $artpModalErrorHandler, $log) {
    $scope.string_pattern = /.*[a-z]{1,}.*/;
    $scope.string_pattern_as_string = '.*([a-z][A-Z]){1,}.*';
    $scope.year_pattern = /^[12][0-9]{3}$/;
    $scope.year_pattern_as_string = '[12][0-9]{3}';
    $scope.integer_pattern = /^[0-9]*$/;
    $scope.integer_pattern_as_string = '[0-9]*';
    $scope.currency_pattern = /^[0-9]*([.][0-9][0-9]?)?$/;
    $scope.currency_pattern_as_string = '[0-9]*([.][0-9][0-9]?)?';
    $scope.weight_pattern = /^[0-9]+([\.,][0-9]+)?$/;
    $scope.weight_pattern_as_string = '[0-9]+([.,][0-9]+)?';

    $scope.scroll = false;

    $scope.taOptions = {
      toolbar: [['bold', 'italics', 'underline', 'ul', 'ol', 'redo', 'undo', 'clear'], ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'], ['charcount']],
    };

    $scope.alerts = [];

    $scope.$locale = $locale;
    $scope._ = _;
    $scope.window = window;

    $scope.date_options = {
      startingDay: 1,
      showWeeks: false,
    };

    $scope.date_format = $locale.DATETIME_FORMATS.shortDate;

    $scope.navigator = navigator;

    $scope.follow = enc => {
      window.location = atob(enc);
    };

    $scope.today = dt => (dt = new Date());

    $scope.toggleWeChatQRCode = function () {
      if ($scope.weChatQRCode) {
        return ($scope.weChatQRCode = false);
      } else {
        $scope.weChatQRCode = true;
        return $('.wechat-qr-code').removeClass('hidden');
      }
    };

    $scope.addAlert = function (obj) {
      $scope.alerts.push(obj);
      return $timeout(() => $scope.closeAlert($scope.alerts.indexOf(obj)), 10000);
    };

    $scope.closeAlert = index => $scope.alerts.splice(index, 1);

    $scope.open_cgv = function () {
      $scope.modal = $uibModal.open({
        templateUrl: '/block/cgv_bs',
        size: 'lg',
        scope: $scope,
      });
      return $scope.modal.result.catch($artpModalErrorHandler);
    };

    const file_size_control = function (file) {
      if (file.size > 20000000) {
        file.error = i18next.t('javascripts.locales.m22');
        return false;
      }
      return true;
    };

    $scope.redirectTo = url => (window.location.href = url);

    const image_only_control = function (file) {
      let type;
      if (FileUploader.isHTML5) {
        ({ type } = file);
      } else {
        type = `/${file.value.slice(file.value.lastIndexOf('.') + 1)}`;
      }
      type = `|${type.toLowerCase().slice(type.lastIndexOf('/') + 1)}|`;
      const result = '|jpg|png|jpeg|bmp|'.indexOf(type) !== -1;

      if (!result) {
        file.error = window.imageUploader.fileTypeForbidden;
      }
      return result;
    };

    $scope.init_new_file_uploader = function (on_success_callback, url_for_create_image, additionnal_filters, scope) {
      let filter;
      if (additionnal_filters == null) {
        additionnal_filters = [];
      }
      const file_uploader = new FileUploader({
        scope,
        url: url_for_create_image,
        autoUpload: true,
        removeAfterUpload: true,

        formData: [{ format: 'json' }],
      });

      for (filter of [file_size_control, image_only_control]) {
        file_uploader.filters.push({
          fn: filter,
          n: 'filter',
        });
      }
      for (filter of Array.from(additionnal_filters)) {
        file_uploader.filters.push({
          fn: filter,
          n: 'filter',
        });
      }

      file_uploader.onErrorItem = function (item, response, status, headers) {
        item.file.error = status;
        if (!file_uploader.error_queue) {
          file_uploader.error_queue = [];
        }
        if (file_uploader.isHTML5) {
          file_uploader.error_queue.push({ name: item.file.name, error: item.file.error });
        }
        if (!file_uploader.isHTML5) {
          return file_uploader.error_queue.push({
            name: item._input[0].name,
            error: item._input[0].error,
          });
        }
      };

      file_uploader.onWhenAddingFileFailed = function (file) {
        if (!file_uploader.error_queue) {
          file_uploader.error_queue = [];
        }
        if (file_uploader.isHTML5) {
          file_uploader.error_queue.push({ name: file.name, error: file.error });
        }
        if (!file_uploader.isHTML5) {
          return file_uploader.error_queue.push({
            name: file._input[0].name,
            error: file._input[0].error,
          });
        }
      };

      file_uploader.onSuccessItem = function (item, response, status, headers) {
        item.deleted = true;
        return on_success_callback(response);
      };

      return file_uploader;
    };

    $scope.aapiBeacon = function (type, complements) {
      return aapiBeacon(type, complements);
    };
  },
]);
