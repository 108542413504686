import angular from 'angular';

const template = require('./template.html');

class Ctrl {
  constructor(Customer, $window) {
    this.$window = $window;
    this.Customer = Customer;
  }

  currencySelect(dimensionUnit) {
    this.ngModel = dimensionUnit;
    this.Customer.update_preferred_dimensions_unit({ dimension_unit: dimensionUnit });
  }
}

const dimensions = {
  template,
  controller: ['Customer', '$window', Ctrl],
  bindings: {
    ngModel: '=',
  },
};

angular.module('artprice_app').component('artpLotsShowDisplayDimensions', dimensions);
