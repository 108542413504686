/* eslint-disable */

window.artprice_app.controller('MarketplaceAccountCtrl', [
  'Address',
  '$window',
  'Customer',
  'Artprice',
  '$artpHttpErrorHandler',
  function (Address, $window, Customer, Artprice, $artpHttpErrorHandler) {
    const vm = this;

    Artprice.retrieved_from_helper_for_select({ helper: 'title_to_json' }, data => (vm.titles = data), $artpHttpErrorHandler);

    Artprice.retrieved_from_helper_for_select({ helper: 'country_to_json' }, data => (vm.countries = data), $artpHttpErrorHandler);

    vm.initializeBillAddress = id =>
      Customer.show({ id }, function (c) {
        vm.customer = new Customer(c);
        return (vm.address = new Address({ idtitle: vm.customer.idtitle, lastname: vm.customer.lastname, firstname: vm.customer.firstname }));
      });

    vm.saveAddress = function (event) {
      event.preventDefault();
      if (vm.formAddress.$valid) {
        return vm.address.$update_or_create({ tvaintra: vm.customer.tvaintra }, function (address) {
          vm.customer.idbilladdress = address.id;
          return vm.customer.$update_or_create({}, function (customer) {
            let url;
            if ($window.location.href.indexOf('?') > 0) {
              if ($window.location.href.indexOf('hb=1') < 0) {
                url = $window.location.href + '&hb=1';
              } else {
                url = $window.location.href;
              }
            } else {
              url = $window.location.href + '?hb=1';
            }
            return ($window.location = url);
          });
        });
      }
    };

    return vm;
  },
]);
