import angular from 'angular';

angular.module('common_app').factory('Subscription', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/subscription/:action/:id/',
      {
        format: 'json',
      },
      {
        get_child_subscriptions: {
          method: 'get',
          params: { action: 'get_child_subscriptions' },
          isArray: true,
        },
        get_parent_subscription: {
          method: 'get',
          params: { action: 'get_parent_subscription' },
          isArray: false,
        },
        increment_nbusers: {
          method: 'get',
          params: { action: 'increment_nbusers' },
          isArray: false,
        },
        decrement_nbusers: {
          method: 'get',
          params: { action: 'decrement_nbusers' },
          isArray: false,
        },
      },
    ),
]);
