import angular from 'angular';
import queryString from 'query-string';
import isValid from 'date-fns/isValid';
import { debounce } from 'lodash';

function throwIfMissing(name) {
  throw new Error(`Missing parameter : ${name}`);
}

// eslint-disable-next-line import/prefer-default-export
export const serviceName = 'artdbwebSearch';

function factory($window, artpJson, $timeout, $rootScope, $log, $artpHttpErrorHandler, $q) {
  this.getValuesFromUrl =
    (integerFields = throwIfMissing('integerFields'), booleanFields = throwIfMissing('integerFields'), dateFields = throwIfMissing('dateFields'), arrayFields = throwIfMissing('arrayFields')) =>
    () => {
      const params = queryString.parse($window.location.search);

      Object.keys(params).forEach(key => (params[key] === '' || params[key] === null || params[key] === 'null' || params[key] === 'undefined') && delete params[key]);
      integerFields.forEach(field => {
        if (!Number.isNaN(parseInt(params[field], 10))) {
          params[field] = parseInt(params[field], 10);
        }
      });

      booleanFields.forEach(field => {
        if (!params[field]) {
          params[field] = 0;
        }
      });

      arrayFields.forEach(field => {
        params[field] = params[`${field}[]`];
        delete params[`${field}[]`];
      });

      dateFields.forEach(field => {
        if (params[field]) {
          try {
            const date = new Date(params[field]);
            if (isValid(date)) {
              params[field] = date;
            } else {
              delete params[field];
            }
          } catch (e) {
            delete params[field];
          }
        }
      });

      return params;
    };

  this.emit = debounce(searchChangedEvent => $rootScope.$emit(searchChangedEvent), 200);

  this.reloadFacets = ({ frozenFacetKey, searchName = throwIfMissing('searchName'), context = throwIfMissing('context'), searchChangedEvent = throwIfMissing('searchChangedEvent') }) =>
    new Promise(resolve => {
      const currentSearchContext = context;
      currentSearchContext.facetsLoading = true;
      this.emit(searchChangedEvent);
      $timeout(() => {
        this.resolvePreviousCanceller(searchName);
        $log.debug(searchName, 'reload facets for ', { ...currentSearchContext.values });
        // On ne peut pas utiliser une query avec cache a cause de :
        // https://github.com/jmdobry/angular-cache/issues/259
        const params = { ...currentSearchContext.values };
        Object.keys(params).forEach(key => {
          if (angular.isArray(params[key])) {
            params[`${key}[]`] = params[key];
            delete params[key];
          }
        });

        artpJson.queryWithClassicCache(`${$window.location.pathname}/facets`, params, this.getNewCanceller(searchName)).then(data => {
          this.deleteCanceller(searchName);
          // merging remoteFacets in this.currentSearchContext.facets
          if (data.facets) {
            Object.entries(data.facets).forEach(([remoteFacetKey, remoteFacet]) => {
              if (angular.isUndefined(frozenFacetKey) || frozenFacetKey !== remoteFacetKey) {
                currentSearchContext.facets[remoteFacetKey] = remoteFacet;
              }
            });
          }
          currentSearchContext.facetsLoading = false;
          this.emit(searchChangedEvent);
          resolve();
        }, $artpHttpErrorHandler);
      });
    });

  this.changes = ({ context, paramsToCheck }) => {
    const result = {};
    paramsToCheck.forEach(param => {
      if (context.initialValues[param] !== context.values[param]) {
        result[param] = context.values[param];
      }
    });
    return result;
  };

  this.hasChanged = ({ context, paramsToCheck }) => {
    let result = false;
    paramsToCheck.forEach(param => {
      if (context.initialValues[param] !== context.values[param]) {
        result = result || true;
      }
    });
    return result;
  };

  this.resolvePreviousCanceller = (searchName = throwIfMissing('searchName')) => {
    this[searchName] = this[searchName] || {};
    if (this[searchName].canceller) {
      this[searchName].canceller.resolve('abort');
      delete this[searchName].canceller;
    }
  };

  this.getNewCanceller = (searchName = throwIfMissing('searchName')) => {
    this[searchName] = this[searchName] || {};
    this[searchName].canceller = $q.defer();
    return this[searchName].canceller.promise;
  };

  this.deleteCanceller = (searchName = throwIfMissing('searchName')) => {
    delete this[searchName].canceller;
  };

  return {
    changes: this.changes,
    hasChanged: this.hasChanged,
    getValuesFromUrl: this.getValuesFromUrl,
    reloadfacets: this.reloadfacets,
    resolvePreviousCanceller: this.resolvePreviousCanceller,
    getNewCanceller: this.getNewCanceller,
    deleteCanceller: this.deleteCanceller,
    reloadFacets: this.reloadFacets,
  };
}

angular.module('artprice_app').factory(serviceName, ['$window', 'artpJson', '$timeout', '$rootScope', '$log', '$artpHttpErrorHandler', '$q', factory]);
