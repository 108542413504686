import angular from 'angular';

angular.module('common_app').directive('convertToNumber', () => ({
  require: '?ngModel',

  link(scope, element, attrs, ngModel) {
    ngModel.$parsers.push(val => {
      if (val) {
        return parseInt(val, 10);
      }
      return null;
    });
    return ngModel.$formatters.push(val => {
      if (val) {
        return `${val}`;
      }
      return null;
    });
  },
}));
