/* eslint-disable */

window.artprice_app.controller('MarketplaceMyspaceCtrl', [
  'Classified',
  '$scope',
  '$rootScope',
  '$sce',
  '$artpHttpErrorHandler',
  function (Classified, $scope, $rootScope, $sce, $artpHttpErrorHandler) {
    $scope.initializeMenu = activeSubMenu => ($scope.activeSubMenu = activeSubMenu);

    return ($scope.initializeNotifications = (idclassified, idclassifiedstatute) =>
      Classified.classifiedNotifications({ id: idclassified, idclassifiedstatute }, notifications => ($scope.notifications = notifications), $artpHttpErrorHandler));
  },
]);
