import angular from 'angular';

angular.module('common_app').factory('Base', () => {
  let BaseClass;

  if (!BaseClass) {
    BaseClass = class Base {
      constructor(initData) {
        angular.extend(this, initData);
      }
    };
  }

  return BaseClass;
});
