/* eslint-disable */

window.artprice_app.controller('NewsletterCtrl', [
  '$scope',
  '$uibModal',
  '$timeout',
  'Customer',
  '$artpModalErrorHandler',
  '$artpHttpErrorHandler',
  function ($scope, $uibModal, $timeout, Customer, $artpModalErrorHandler, $artpHttpErrorHandler) {
    $scope.newsletter = {
      push_sections: {},
      push_auction_sections: {},
      category: {},
      favorite: {},
      mailing_lists: {},
      favorite_category: {},
    };

    $scope.latest_success = {};

    $scope.processing = {};

    return ($scope.toggle = function (section) {
      const success_string = section;
      delete $scope.latest_success[success_string];
      $scope.processing[success_string] = true;
      const value = $scope.newsletter[section];
      return Customer.change_value_for_newsletter(
        {
          section,
          value,
        },
        function (data) {
          $scope.processing[success_string] = false;
          $scope.latest_success[success_string] = data.msg;
          return $timeout(() => delete $scope.latest_success[success_string], 10000);
        },
        $artpHttpErrorHandler,
      );
    });
  },
]);
