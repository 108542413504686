import angular from 'angular';

// from https://docs.angularjs.org/error/ngModel/numfmt
angular.module('common_app').directive('stringToNumber', () => ({
  require: 'ngModel',
  link(scope, element, attrs, ngModel) {
    ngModel.$parsers.push(value => `${value}`);
    ngModel.$formatters.push(value => parseFloat(value));
  },
}));
