import angular from 'angular';
import getConfiguredStore from '../../../../../../../../javascript/redux/store';
import { UPDATE_CURRENCY_PREFERENCE } from '../../../../../../../../javascript/redux/actions/preferences';
import { CURRENCIES } from '../../../../../../../../javascript/constants/rails';

const template = require('./template.html');

class Ctrl {
  constructor(Customer, $window) {
    this.$window = $window;
    this.Customer = Customer;
    this.currencies = CURRENCIES;
  }

  $onInit() {
    this.currentIso1 = this.currencyVisualisation(CURRENCIES[this.ngModel]);
  }

  currencySelect(currency) {
    this.ngModel = currency;
    this.Customer.update_preferred_currency({ currency });
    getConfiguredStore().dispatch({
      type: UPDATE_CURRENCY_PREFERENCE,
      currency,
    });

    this.currentIso1 = this.currencyVisualisation(CURRENCIES[this.ngModel]);
  }

  currencyVisualisation(currency) {
    if (currency.src) return `<img src="${currency.src}" style="height: 16px;" alt="${currency.iso3}" />`;

    return currency.iso1;
  }
}

const currencies = {
  template,
  controller: ['Customer', '$window', Ctrl],
  bindings: {
    ngModel: '=',
  },
};

angular.module('artprice_app').component('artpLotsShowDisplayCurrencies', currencies);
