import angular from 'angular';

angular.module('artprice_app').factory('Privatemessagetype', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/privatemessagetype/:action/:id/',
      {
        format: 'json',
      },
      {
        list: {
          method: 'get',
          params: { action: 'list' },
        },
      },
    ),
]);
