import angular from 'angular';
import textAngular from 'textangular';
import angularCache from 'angular-cache';
import paypalCheckout from 'paypal-checkout';

import baseApplication from './baseApplication';

paypalCheckout.Button.driver('angular', angular);

/* eslint-disable angular/window-service, angular/module-setter */
window.artprice_app = angular.module('artprice_app', [
  'ja.qr',
  'ngResource',
  'ngStorage',
  'ngAnimate',
  'ngCookies',
  'angularFileUpload',
  textAngular,
  'ui.bootstrap.carousel',
  'common_app',
  'paypal-button',
  angularCache,
  baseApplication,
]);

require('../components/artmarketinsights');
require('../components/inputs');
require('../components/lots');
require('../components/subscription');
require('../components/reports');
require('../components/search');
require('../components/account');
