import angular from 'angular';

angular.module('common_app').factory('Ail', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/ail/:action/:id/',
      {
        format: 'json',
      },
      {
        actives: {
          method: 'GET',
          params: { action: 'actives' },
        },
        actives_by_type: {
          method: 'GET',
          params: { action: 'actives_by_type' },
        },
      },
    ),
]);
