import angular from 'angular';
import queryString from 'query-string';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($window, artpJson, $timeout, $artpHttpErrorHandler) {
    this.$timeout = $timeout;
    this.localCss = localCss;
    this.$window = $window;
    this.artpJson = artpJson;
    this.$artpHttpErrorHandler = $artpHttpErrorHandler;

    this.initialize = true;
    this.params = queryString.parse(this.$window.location.search);
    this.artpJson.query('/api/es/reports/search', { keyword: this.params.keyword }).then(data => {
      this.initialize = false;
      this.reports = data;
      this.searching = false;
    }, this.$artpHttpErrorHandler);
  }

  changePage() {
    this.$timeout(() => {
      this.searching = true;
      this.artpJson.query('/api/es/reports/search', { keyword: this.params.keyword, page: this.reports.page }).then(data => {
        this.reports = data;
        this.searching = false;
      }, this.$artpHttpErrorHandler);
    });
  }
}

const Reports = {
  template,
  controller: ['$window', 'artpJson', '$timeout', '$artpHttpErrorHandler', Ctrl],
  bindings: {},
};

angular.module('artprice_app').component('artpReportsSearchList', Reports);
