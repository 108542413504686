import angular from 'angular';

function provider($window) {
  return {
    isMobile: () => $window.innerWidth <= 425,
    isDesktop: () => $window.innerWidth > 425,
    isXs: () => $window.innerWidth < 768,
    isSm: () => $window.innerWidth >= 768 && $window.innerWidth < 992,
    isMd: () => $window.innerWidth >= 992 && $window.innerWidth < 1200,
    isLg: () => $window.innerWidth >= 1200,
  };
}

angular.module('common_app').factory('viewport', ['$window', provider]);
