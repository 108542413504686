/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('Activity', [
  '$resource',
  $resource => {
    return $resource(
      '/api/wallet/activity/:action/:id/',
      {
        format: 'json',
      },
      {
        show: {
          method: 'GET',
          params: { action: 'show' },
        },
      },
    );
  },
]);
