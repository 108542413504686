import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($timeout) {
    this.$timeout = $timeout;
    this.localCss = localCss;
    this.dropdownVisible = false;
  }

  handleClickOutside() {
    this.dropdownVisible = false;
  }

  handleDropdownTitleClick() {
    if (this.dropdownVisible) {
      this.dropdownVisible = false;
    } else {
      this.dropdownVisible = true;
    }
  }
}

const DropdownForm = {
  template,
  controller: ['$timeout', Ctrl],
  transclude: true,
  bindings: {
    labelXs: '@',
    label: '@',
    side: '@',
  },
};

angular.module('common_app').component('artpInputsDropdownSimple', DropdownForm);
