/* eslint-disable */
import angular from 'angular';
import * as Sentry from '@sentry/react';

angular.module('common_app').provider('$exceptionHandler', {
  $get: ['errorLogService', errorLogService => errorLogService],
});

angular.module('common_app').factory('errorLogService', [
  '$log',
  '$window',
  function ($log, $window) {
    const log = function (exception, cause) {
      if (exception instanceof Error) {
        Sentry.captureException(exception);
      }
      $log.error.apply($log, arguments);
    };

    return log;
  },
]);
