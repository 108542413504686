import angular from 'angular';
import i18Next from 'i18next';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($sessionStorage, $window) {
    this.localCss = localCss;
    this.$window = $window;
    this.$sessionStorage = $sessionStorage;
  }

  $onInit() {
    if (this.direction === 'prev') {
      this.label = i18Next.t('catalogue.show.m1');
    } else if (this.direction === 'next') {
      this.label = i18Next.t('catalogue.show.m2');
    }
  }
}

const Filters = {
  template,
  controller: ['$sessionStorage', '$window', Ctrl],
  bindings: {
    lot: '<',
    direction: '@',
  },
};

angular.module('artprice_app').component('artpLotsShowFiltersNavigation', Filters);
