/* eslint-disable */

import angular from 'angular';

angular.module('artprice_app').factory('Lotartistassociationtype', [
  '$resource',
  $resource => {
    return $resource(
      '/api/artdbweb/lotartistassociationtype/:action/:id/',
      {
        format: 'json',
      },
      {
        get_for_artist: {
          method: 'get',
          params: { action: 'get_for_artist' },
        },
        get_for_sale: {
          method: 'get',
          isArray: true,
          params: { action: 'get_for_sale' },
        },
        get: {
          method: 'get',
          isArray: true,
          params: { action: 'get' },
        },
      },
    );
  },
]);
