/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('Classified', [
  '$resource',
  $resource => {
    return $resource(
      '/api/wallet/classified/:action/:id/',
      {
        format: 'json',
      },
      {
        categories: {
          cache: false,
          method: 'GET',
          isArray: true,
          params: { action: 'categories' },
        },
        carousel: {
          method: 'GET',
          isArray: true,
          params: { action: 'carousel' },
        },
        classified_to_edit: {
          method: 'GET',
          params: { action: 'classified_to_edit' },
        },
        show: {
          method: 'GET',
          isArray: false,
          params: { action: 'show' },
        },
        classifiedNotifications: {
          method: 'GET',
          isArray: true,
          params: { action: 'classified_notifications' },
        },
        retrieve: {
          method: 'GET',
          isArray: true,
          params: { action: 'retrieve' },
        },
        add_in_favorite: {
          method: 'GET',
          isArray: false,
          params: { action: 'add_in_favorite' },
        },
        remove_from_favorite: {
          method: 'GET',
          isArray: false,
          params: { action: 'remove_from_favorite' },
        },
        favorites: {
          method: 'GET',
          isArray: true,
          params: { action: 'favorites' },
        },
      },
    );
  },
]);
