import angular from 'angular';

angular.module('common_app').factory('Classifiedoffer', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/classifiedoffer/:action/:id/',
      {
        format: 'json',
      },
      {
        offer_for_customer: {
          method: 'GET',
          params: { action: 'offer_for_customer' },
        },
        make_offer: {
          method: 'POST',
          params: { action: 'make_offer' },
        },
        reject_offer: {
          method: 'POST',
          params: { action: 'reject_offer' },
          isArray: true,
        },
        reject_customer: {
          method: 'POST',
          params: { action: 'reject_customer' },
          isArray: true,
        },
        accept_offer: {
          method: 'POST',
          params: { action: 'accept_offer' },
          isArray: true,
        },
        offers_for_classified: {
          method: 'GET',
          params: { action: 'offer_for_classified' },
          isArray: true,
        },
      },
    ),
]);
