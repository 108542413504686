/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('Artprice', [
  '$resource',
  $resource => {
    return $resource(
      '/api/artprice/:action/:id/',
      {
        format: 'json',
      },
      {
        retrieved_from_helper_for_select: {
          method: 'get',
          isArray: true,
          params: { action: 'retrieved_from_helper_for_select' },
        },
      },
    );
  },
]);
