import angular from 'angular';
import localCss from './stylesheet.module.scss';

import './Dimensions';
import './Currencies';

const template = require('./template.html');

class Ctrl {
  constructor() {
    this.localCss = localCss;
  }

  handleClickOutside() {
    this.dropdownVisible = false;
  }

  handleDropdownTitleClick() {
    if (this.dropdownVisible) {
      this.dropdownVisible = false;
    } else {
      this.dropdownVisible = true;
    }
  }
}

const display = {
  template,
  controller: Ctrl,
  bindings: {
    ngModelDimensions: '=',
    ngModelCurrencies: '=',
  },
};

angular.module('artprice_app').component('artpLotsShowDisplay', display);
