import angular from 'angular';

angular.module('common_app').factory('Eventweb', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/eventweb/:action/:id/',
      {
        format: 'json',
      },
      {
        actives: {
          method: 'GET',
          isArray: true,
          params: { action: 'actives' },
        },
      },
    ),
]);
