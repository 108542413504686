import angular from 'angular';

angular.module('common_app').factory('Customersearchclassified', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/customersearchclassified/:action/:id',
      {
        format: 'json',
      },
      {
        customer_list: {
          method: 'GET',
          isArray: true,
          params: { action: 'index' },
        },
        update: {
          method: 'PUT',
          params: { action: 'update' },
        },
        delete: {
          method: 'DELETE',
          params: { action: 'destroy' },
        },
      },
    ),
]);
