/* eslint-disable */
import _ from 'lodash';

window.artprice_app.controller('EstimateCtrl', [
  '$http',
  '$rootScope',
  '$scope',
  'Category',
  'Signatureposition',
  'Estimate',
  'Physicproduct',
  'Customer',
  '$timeout',
  '$sessionStorage',
  '$artpHttpErrorHandler',
  'GoogleAnalytics',
  function ($http, $rootScope, $scope, Category, Signatureposition, Estimate, Physicproduct, Customer, $timeout, $sessionStorage, $artpHttpErrorHandler, GoogleAnalytics) {
    $scope.cgv = {
      cgvAccepted: false,
      displayCgvWarning: false,
    };

    $scope.GoogleAnalytics = GoogleAnalytics;
    $scope.showPrices = true;
    $scope.toolbarOption = [
      ['bold', 'italics', 'underline'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
    ];
    $scope.estimate = { images: [] };
    $scope.dimensionUnit = 'cm';
    $scope.categories = {};
    $scope.year_pattern = /^[12][0-9]{3}$/;
    $scope.year_pattern_as_string = '[12][0-9]{3}';

    $scope.idestimatesToBuy = [];
    $scope.estimateUnitsCount = 0;

    $scope.toggleHelp = section => ($scope.help = section);

    $scope.images_uploading = false;
    $rootScope.$on('all_uploads_finished', function (event, args) {
      $scope.images_uploading = false;
      return $scope.$apply();
    });

    $rootScope.$on('starting_upload', function (event, args) {
      $scope.images_uploading = true;
      return $scope.$apply();
    });

    const categoryEstimateSuccess = list => angular.extend($scope.categories, list);
    Category.estimate(categoryEstimateSuccess, $artpHttpErrorHandler);

    $scope.updateValidators = function () {
      if ($scope.estimate.idcategory === 5) {
        delete $scope.form_estimate['estimate[width]'].$validators.min;
        delete $scope.form_estimate['estimate[width]'].$validators.pattern;

        $scope.form_estimate['estimate[length]'].$validators.min = function (modelValue, viewValue) {
          if (parseInt($scope.form_estimate['estimate[length]'].$viewValue) < 0) {
            return false;
          }
          return true;
        };

        return ($scope.form_estimate['estimate[length]'].$validators.pattern = function (modelValue, viewValue) {
          if ($scope.form_estimate['estimate[length]'].$viewValue && $scope.form_estimate['estimate[length]'].$viewValue.match(/[^0-9\.]+/)) {
            return false;
          }
          return true;
        });
      } else {
        delete $scope.form_estimate['estimate[length]'].$validators.min;
        delete $scope.form_estimate['estimate[length]'].$validators.pattern;

        $scope.form_estimate['estimate[width]'].$validators.min = function (modelValue, viewValue) {
          if (parseInt($scope.form_estimate['estimate[width]'].$viewValue) < 0) {
            return false;
          }
          return true;
        };

        return ($scope.form_estimate['estimate[width]'].$validators.pattern = function (modelValue, viewValue) {
          if ($scope.form_estimate['estimate[width]'].$viewValue && $scope.form_estimate['estimate[width]'].$viewValue.match(/[^0-9\.]+/)) {
            return false;
          }
          return true;
        });
      }
    };

    const initializeValidators = () =>
      $timeout(function () {
        $scope.form_estimate['estimate[comment]'].$validators.textTooLong = (modelValue, viewValue) => String($scope.form_estimate['estimate[comment]'].$viewValue).replace(/<[^>]+>/gm, '').length <= 1000;

        $scope.form_estimate['estimate[height]'].$validators.min = function (modelValue, viewValue) {
          if (parseInt($scope.form_estimate['estimate[height]'].$viewValue) < 0) {
            return false;
          }
          return true;
        };

        $scope.form_estimate['estimate[height]'].$validators.pattern = function (modelValue, viewValue) {
          if ($scope.form_estimate['estimate[height]'].$viewValue && $scope.form_estimate['estimate[height]'].$viewValue.match(/[^0-9\.]+/)) {
            return false;
          }
          return true;
        };
        return $scope.updateValidators();
      });

    $scope.initializeEstimateFromStorage = function (idcustomer) {
      initializeValidators();
      if (!$sessionStorage.estimate.idcustomer) {
        $sessionStorage.estimate.idcustomer = idcustomer;
      }
      $scope.estimate = angular.extend({}, $sessionStorage.estimate);
      if (!$scope.estimate.images) {
        $scope.estimate.images = [];
      }
    };

    $scope.initializeEstimateFromId = function (id) {
      initializeValidators();
      Estimate.show(
        { id },
        function (data) {
          angular.extend($scope.estimate, data);
          return Estimate.retrieve(
            { helper: 'estimatedimensionunit_to_json' },
            estimatedimensionunitToJson =>
              (() => {
                const result = [];
                for (let estimatedimensionunit of Array.from(estimatedimensionunitToJson)) {
                  if (parseInt(estimatedimensionunit.id) === parseInt($scope.estimate.idestimatedimensionunit)) {
                    result.push(($scope.dimensionUnit = estimatedimensionunit.label));
                  } else {
                    result.push(undefined);
                  }
                }
                return result;
              })(),
            $artpHttpErrorHandler,
          );
        },
        $artpHttpErrorHandler,
      );
      return ($sessionStorage.estimate = $scope.estimate);
    };

    const initializeEstimateProductListSuccess = function (data) {
      const promises = [];
      const impressions = [];
      data.items.forEach(product =>
        promises.push(
          new Promise((resolve, reject) =>
            Physicproduct.show(
              { id: product.id },
              function (p) {
                const newEstimate = Object.assign({}, product, { details: p });
                impressions.push(GoogleAnalytics.gtmEstimatePhysicproduct(newEstimate));
                return resolve(newEstimate);
              },
              $artpHttpErrorHandler,
            ),
          ),
        ),
      );
      $scope.promotion_from_promocode = data.promotion_from_promocode;

      return Promise.all(promises).then(function (estimates) {
        $scope.estimates = estimates;
        return $scope.$apply();
      });
    };
    $scope.initializeEstimateProductList = function (promocode) {
      $scope.promocode = promocode;
      return Physicproduct.listEstimates({ promocode: $scope.promocode }, initializeEstimateProductListSuccess, $artpHttpErrorHandler);
    };

    $scope.promotion = () => $scope.promotion_from_promocode;

    $scope.initializeEstimatePreview = function (idestimatesToBuy, estimateUnitsCount) {
      if (idestimatesToBuy) {
        $scope.idestimatesToBuy = idestimatesToBuy;
      }
      if (estimateUnitsCount) {
        return ($scope.estimateUnitsCount = estimateUnitsCount);
      }
    };

    $scope.removeFromIdestimatesToBuy = idestimate => ($scope.idestimatesToBuy = _.without($scope.idestimatesToBuy, idestimate));

    $scope.addToIdestimatesToBuy = idestimate => $scope.idestimatesToBuy.push(idestimate);

    $scope.nbUnitsNeeded = function () {
      if (_.size($scope.idestimatesToBuy) > $scope.estimateUnitsCount) {
        return _.size($scope.idestimatesToBuy) - $scope.estimateUnitsCount;
      } else {
        return 0;
      }
    };

    $scope.idestimatesToBuyToUriParams = function () {
      if (_.size($scope.idestimatesToBuy) > 0) {
        return $.param({ idestimates: $scope.idestimatesToBuy });
      } else {
        return 'idestimates=0';
      }
    };

    $scope.cgvIsNeeded = () => _.size($scope.idestimatesToBuy) > 0 && $scope.nbUnitsNeeded() === 0;

    $scope.retrieve = function (helper) {
      if (!$scope.retrieved) {
        $scope.retrieved = {};
      }
      if (!$scope.retrieved[helper]) {
        $scope.retrieved[helper] = [];
        Estimate.retrieve({ helper }, data => angular.extend($scope.retrieved[helper], data), $artpHttpErrorHandler);
      }
      return $scope.retrieved[helper];
    };

    $scope.setDimensionUnit = function (estimate, dimensionUnit) {
      estimate.idestimatedimensionunit = dimensionUnit.id;
      estimate.idestimatedimensionunitwithframe = dimensionUnit.id;
      return ($scope.dimensionUnit = dimensionUnit.label);
    };

    $scope.validImagesCount = function () {
      let count = 0;
      $scope.estimate.images.forEach(function (image) {
        if (!image.deleted) {
          return ++count;
        }
      });
      return count;
    };

    $scope.validImagesSize = function () {
      let result = 0;
      if ($scope.estimate && $scope.estimate.images) {
        for (let image of Array.from($scope.estimate.images)) {
          if (!image.deleted) {
            result = result + 1;
          }
        }
      }
      return result;
    };

    $scope.validateEstimate = function (event) {
      $scope.form_estimate.$setSubmitted();
      if (!($scope.form_estimate.$valid && $scope.validImagesCount() > 0)) {
        return event.preventDefault();
      }
    };

    return ($scope.selectPack = function (product) {
      $sessionStorage.gtmProduct = GoogleAnalytics.gtmEstimatePhysicproduct(product);
      window.dataLayer.push({
        event: 'detail',
        ecommerce: {
          actionField: { list: 'Estimate' },
          products: [$sessionStorage.gtmProduct],
        },
      });
      return window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [$sessionStorage.gtmProduct],
          },
        },
      });
    });
  },
]);
