import angular from 'angular';
import { get } from 'lodash';

angular.module('common_app').factory('$artistService', [
  '$http',
  '$q',
  ($http, $q) => ({
    autocomplete: (term, seeMoreLabel, perPage = 5, page = 1) =>
      $q((resolve, reject) => {
        $http
          .post('/api/artdbweb/artist/json_search/?format=json', {
            page,
            per_page: perPage,
            term,
          })
          .then(response => {
            const list = get(response, 'data.artists', []).map(item => ({ ...item, term }));
            if (get(response, 'data.count', 0) > 5) {
              list.push({ noHighlight: true, html: "<hr title='' class='marg marg-5'/>", url: `/artists/search?search=${term}` });
              list.push({ noHighlight: false, html: `${seeMoreLabel.replace('%nb_results%', response.data.count).replace('%term%', term)}`, url: `/artists/search?search=${term}` });
            }
            resolve(list);
          })
          .catch(error => {
            reject(error);
          });
      }),
  }),
]);
