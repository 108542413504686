import angular from 'angular';

angular.module('artprice_app').factory('ArtpriceShop', [
  '$resource',
  $resource =>
    $resource(
      '/api/artprice_shop/:action/:id/',
      {
        format: 'json',
      },
      {
        available_subscriptions: {
          method: 'get',
          isArray: true,
          params: { action: 'available_subscriptions' },
        },
      },
    ),
]);
