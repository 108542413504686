import angular from 'angular';

function provider() {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'dataprod') {
    return 'https://imgpublic.artprice.com/img/';
  }

  if (process.env.NODE_ENV === 'preprod' || process.env.NODE_ENV === 'staging') {
    return 'https://images.gratt.org/img/';
  }

  return 'https://images.gratt.org/img/';
}

angular.module('common_app').factory('artpImg', provider);
