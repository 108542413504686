import angular from 'angular';

angular.module('common_app').factory('Country', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/country/:action/:id/',
      {
        method: 'get',
        format: 'json',
      },
      {
        show: {
          method: 'get',
          params: { action: 'show' },
        },
      },
    ),
]);
