/* eslint-disable */

import i18next from 'i18next';

window.artprice_app.controller('AuctioneerFormCtrl', [
  'Artprice',
  '$window',
  'Documentjson',
  '$localStorage',
  '$artpHttpErrorHandler',
  '$timeout',
  function (Artprice, $window, Documentjson, $localStorage, $artpHttpErrorHandler, $timeout) {
    const vm = this;

    vm.result = false;
    vm.errors = [];
    vm.auctioneer = {
      auctionhouses: [],
      address: {},
      buyerPremium: [{}],
      ceo: [{}],
      specialist: [{}],
      catalogues: [{}],
      marketing: [{}],
      delay: {},
    };

    $timeout(() => (vm.formAuctioneer.policies.$validators.cgvNotChecked = (modelValue, viewValue) => viewValue), 2000);

    $timeout(function () {
      if ($localStorage.auctioneer) {
        let get_draft;
        if (vm.draft) {
          get_draft = true;
        } else {
          get_draft = confirm(i18next.t('javascripts.locales.m19'));
        }

        if (get_draft) {
          return (vm.auctioneer = $localStorage.auctioneer);
        }
      }
    });

    vm.addContact = section => vm.auctioneer[section].push({ name: '', email: '', phone: '' });

    Artprice.retrieved_from_helper_for_select({ helper: 'country_to_json' }, data => (vm.countries = data), $artpHttpErrorHandler);

    Artprice.retrieved_from_helper_for_select({ helper: 'currency_all_to_json' }, data => (vm.currencies = data), $artpHttpErrorHandler);

    vm.saveAuctioneer = () => ($localStorage.auctioneer = vm.auctioneer);

    vm.addBuyerPremium = function () {
      return vm.auctioneer.buyerPremium.push({});
    };

    vm.addAuctionhouse = () => vm.auctioneer.auctionhouses.push({});

    vm.sendEmail = function () {
      vm.formAuctioneer.$setSubmitted();
      if (vm.formAuctioneer.$valid) {
        let gRecaptchaResponse, rucaptcha;
        if (vm._rucaptcha) {
          rucaptcha = vm._rucaptcha;
        } else {
          gRecaptchaResponse = $window.grecaptcha.getResponse();
        }

        return Documentjson.saveAuctioneerForm(
          { 'g-recaptcha-response': gRecaptchaResponse, _rucaptcha: rucaptcha, auctioneer: vm.auctioneer },
          function (result) {
            if (result.success === 'OK') {
              vm.result = true;
              return ($localStorage.auctioneer = null);
            } else {
              vm.errors.push('No click');
              if (vm.auctioneer.rucaptcha) {
                return ($window.location = '/auctioneer/form?draft=1&err=1');
              } else {
                return $window.grecaptcha.reset();
              }
            }
          },
          $artpHttpErrorHandler,
        );
      }
    };

    $window.sendEmail = vm.sendEmail;

    vm.checkCaptcha = function (event) {
      event.preventDefault();
      if (vm.policies === '0') {
        vm.formAuctioneer.policies.$setValidity('cgvNotChecked', false, vm);
      }

      vm.formAuctioneer.$setSubmitted();
      if (vm.formAuctioneer.$valid) {
        if (vm.rucaptcha) {
          return sendEmail();
        } else {
          if ($window.grecaptcha) {
            return $window.grecaptcha.execute();
          } else {
            return vm.errors.push('grecaptcha not loaded');
          }
        }
      }
    };

    return vm;
  },
]);
