import angular from 'angular';

angular.module('common_app').factory('Documentjson', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/documentjson/:action/:id/',
      {
        format: 'json',
      },
      {
        saveAuctioneerForm: {
          method: 'post',
          params: { action: 'save_auctioneer_form' },
        },
      },
    ),
]);
