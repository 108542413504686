/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('PrivateMessage', [
  '$resource',
  $resource => {
    const PrivateMessage = $resource(
      '/api/wallet/private_message/:action/:id',
      {
        format: 'json',
        id: '@id',
      },
      {
        delete: {
          method: 'DELETE',
          isArray: false,
          params: { action: 'delete' },
        },
        mark_read: {
          method: 'GET',
          params: { action: 'mark_read' },
        },
      },
    );

    PrivateMessage.prototype.mark_as_read = function () {
      this.readed = true;
      return this.$mark_read();
    };

    PrivateMessage.prototype.delete_message = function () {
      const { customer } = this;
      const { id } = this;
      return this.$delete().then(function () {
        if (customer) {
          for (let messages_list of [customer.inbox_messages, customer.sent_messages]) {
            if (messages_list) {
              for (let message of Array.from(messages_list.messages)) {
                if (message.id === id) {
                  messages_list.messages.splice(messages_list.messages.indexOf(message), 1);
                  return;
                }
              }
            }
          }
        }
      });
    };

    return PrivateMessage;
  },
]);

const CACHE_KEY_GET_USER_CONTEXT = 'getUserContextCache' + ARTPRICE_VERSION;

angular.module('common_app').factory('Customer', [
  '$window',
  '$resource',
  '$q',
  'PrivateMessage',
  '$artpHttpErrorHandler',
  'CacheFactory',
  '$http',
  ($window, $resource, $q, PrivateMessage, $artpHttpErrorHandler, CacheFactory, $http) => {
    let url;
    CacheFactory(CACHE_KEY_GET_USER_CONTEXT, {
      maxAge: 10 * 60 * 1000, // 10 minutes de cache
      storageMode: 'sessionStorage',
      cacheFlushInterval: 60 * 60 * 1000, // on nettoie toutes les heures
      deleteOnExpire: 'aggressive',
    });

    let cacheUserContext = null;
    // On ne cache pas sur les pages contennant les mots suivants
    let noCache = $window.location.href.match(/(account|payment|subscription|manage)/);
    // IE ne connait pas new URL ... on try catch et si ca merde, on ne cache pas
    try {
      url = new URL($window.location.href);
      // On ne cache pas sur la homepage
      noCache = noCache || url.pathname === '/';
    } catch (error) {
      noCache = true;
    }
    if (!noCache) {
      cacheUserContext = CacheFactory.get(CACHE_KEY_GET_USER_CONTEXT);
    }

    const Customer = $resource(
      '/api/wallet/customer/:action/',
      {
        format: 'json',
      },
      {
        sourcingArtron: {
          method: 'GET',
          params: { action: 'sourcing' },
        },
        acceptPolicies: {
          method: 'GET',
          params: { action: 'accept_policies' },
        },
        multiusers_change_child_password: {
          method: 'POST',
          params: { action: 'multiusers_change_child_password' },
        },
        submitResetPassword: {
          method: 'POST',
          params: { action: 'submit_reset_password' },
        },
        multiusers_delete_child: {
          method: 'POST',
          params: { action: 'multiusers_delete_child' },
        },
        multiusers_add_child: {
          method: 'POST',
          params: { action: 'multiusers_add_child' },
        },
        update_or_create: {
          method: 'POST',
          params: { action: 'update_or_create' },
        },
        validate_email: {
          method: 'POST',
          params: { action: 'validate_email' },
        },
        get: {
          method: 'GET',
          isArray: false,
          params: { action: 'get' },
        },
        detach: {
          method: 'GET',
          isArray: false,
          params: { action: 'detach' },
        },
        licences_status: {
          method: 'GET',
          isArray: false,
          params: { action: 'licences_status' },
        },
        children: {
          method: 'GET',
          isArray: true,
          params: { action: 'children' },
        },
        show: {
          method: 'GET',
          isArray: false,
          params: { action: 'show' },
        },
        get_inbox_messages: {
          method: 'GET',
          isArray: false,
          params: { action: 'inbox_messages', page: 1 },
        },
        get_sent_messages: {
          method: 'GET',
          isArray: false,
          params: { action: 'sent_messages', page: 1 },
        },
        get_deleted_messages: {
          method: 'GET',
          isArray: false,
          params: { action: 'deleted_messages', page: 1 },
        },
        change_value_for_newsletter: {
          method: 'GET',
          isArray: false,
          params: { action: 'change_value_for_newsletter' },
        },
        get_login: {
          method: 'GET',
          params: { action: 'get_login' },
        },
        login: {
          method: 'POST',
          params: { action: 'login' },
        },
        get_favorites_in_sale: {
          method: 'POST',
          isArray: false,
          params: { action: 'favorites_in_sale' },
        },
        update_preferred_dimensions_unit: {
          method: 'POST',
          params: { action: 'update_preferred_dimensions_unit' },
        },
        update_preferred_lot_view: {
          method: 'POST',
          params: { action: 'update_preferred_lot_view' },
        },
        update_preferred_currency: {
          method: 'POST',
          params: { action: 'update_preferred_currency' },
        },
        update_preferred_freshness: {
          method: 'POST',
          params: { action: 'update_preferred_freshness' },
        },
        delete_tvaintra: {
          method: 'POST',
          params: { action: 'delete_tvaintra' },
        },
        get_store: {
          method: 'get',
          params: { action: 'get_store' },
        },
      },
    );

    Customer.get_user_context = (params, resolve, reject) =>
      $q(function (innerResolve, innerReject) {
        if (!params.idcustomer) {
          const result = {
            marketplace: {
              messagesCount: 0,
              actionsStatus: {
                modificationRequiredCount: 0,
                withOffersCount: 0,
                expiredCount: 0,
                myOffersCount: 0,
                winningAuctionsCount: 0,
                waitingCount: 0,
                totalCount: 0,
              },
            },
            customer: {
              idAr: 1,
              estimateCount: 0,
              id: null,
              name: '',
              messagesCount: 0,
            },
          };
          resolve(result);
          innerResolve(result);
          return;
        }

        const httpQuery = {
          params,
          method: 'GET',
          url: '/api/wallet/customer/get_user_context',
          cache: cacheUserContext,
        };

        if (params.nocache) {
          delete httpQuery.cache;
        }

        return $http(httpQuery)
          .catch($artpHttpErrorHandler)
          .then(function (xhrResult) {
            if (xhrResult) {
              innerResolve(xhrResult.data);
              return resolve(xhrResult.data);
            }
          });
      });

    Customer.prototype.init_messages = function (messages_list, method, params, self) {
      messages_list.messages = [];
      messages_list.select_page = function (page) {
        params.page = page;
        return method(params).$promise.then(function (data) {
          messages_list.messages = [];
          messages_list.current_page = page;
          return (() => {
            const result = [];
            for (let message of Array.from(data.messages)) {
              const private_message = new PrivateMessage();
              angular.extend(private_message, message);
              private_message.customer = self;
              result.push(messages_list.messages.push(private_message));
            }
            return result;
          })();
        });
      };
      return method(params).$promise.then(function (data) {
        messages_list.count = data.count;
        messages_list.current_page = 1;
        messages_list.per_page = data.per_page;
        return (() => {
          const result = [];
          for (let message of Array.from(data.messages)) {
            const private_message = new PrivateMessage();
            angular.extend(private_message, message);
            private_message.customer = self;
            result.push(messages_list.messages.push(private_message));
          }
          return result;
        })();
      });
    };

    Customer.prototype.init_inbox_messages = function (force, sort_by, sort_order, page) {
      if (!this.inbox_messages || force) {
        this.inbox_messages = {};
        return Customer.prototype.init_messages(this.inbox_messages, Customer.get_inbox_messages, { page, sort_by, sort_order }, this);
      }
    };

    Customer.prototype.init_sent_messages = function (force, sort_by, sort_order, page) {
      if (!this.sent_messages || force) {
        this.sent_messages = {};
        return Customer.prototype.init_messages(this.sent_messages, Customer.get_sent_messages, { page, sort_by, sort_order }, this);
      }
    };

    Customer.prototype.init_deleted_messages = function (force, sort_by, sort_order, page) {
      if (!this.deleted_messages || force) {
        this.deleted_messages = {};
        return Customer.prototype.init_messages(this.deleted_messages, Customer.get_deleted_messages, { page, sort_by, sort_order }, this);
      }
    };

    return Customer;
  },
]);
