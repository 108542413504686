import angular from 'angular';
import localCss from './stylesheet.module.scss';

const template = require('./template.html');

class Ctrl {
  constructor($sce) {
    this.$sce = $sce;
    this.localCss = localCss;
  }

  $onInit() {
    if (angular.isUndefined(this.ngModel)) {
      this.ngModel = this.ngFalseValue;
    }
  }

  $onChanges() {
    this.trustedLabel = this.$sce.trustAsHtml(this.label);

    if (angular.isUndefined(this.ngTrueValue)) {
      this.ngTrueValue = '1';
    }
    if (angular.isUndefined(this.ngFalseValue)) {
      this.ngFalseValue = '0';
    }
  }

  isChecked() {
    return this.ngModel === this.ngTrueValue;
  }

  handleClick(event) {
    if (!this.ngDisabled) {
      event.preventDefault();
      if (this.isChecked()) {
        this.ngModel = this.ngFalseValue;
      } else {
        this.ngModel = this.ngTrueValue;
      }
      this.ngClick();
    }
  }
}

const Checkbox = {
  template,
  controller: ['$sce', Ctrl],
  transclude: true,
  bindings: {
    name: '@',
    id: '@',
    label: '@',
    ngModel: '=?',
    ngClick: '&',
    ngDisabled: '=?',
    small: '=',
    ngTrueValue: '<?',
    ngFalseValue: '<?',
  },
};

angular.module('artprice_app').component('artpInputsCheckbox', Checkbox);
