/* eslint-disable */

import angular from 'angular';

angular.module('artprice_app').factory('Signatureposition', [
  '$resource',
  $resource => {
    return $resource(
      '/api/artdbweb/signatureposition/:action/:id/',
      {
        format: 'json',
      },
      {
        estimate: {
          method: 'get',
          params: { action: 'estimate' },
        },
        print_estimate: {
          method: 'get',
          params: {
            action: 'estimate',
            idcategory: 2,
          },
        },
      },
    );
  },
]);
