/* eslint-disable */

window.common_app.controller('ClientMessagesCtrl', [
  '$scope',
  '$uibModal',
  'Customer',
  'Privatemessagetype',
  '$artpHttpErrorHandler',
  function ($scope, $uibModal, Customer, Privatemessagetype, $artpHttpErrorHandler) {
    $scope.messages = [];

    $scope.sort_by = 'dt';

    $scope.sort_order = 'desc';

    $scope.display = 'inbox';

    $scope.change_page = () => $scope.messages.select_page($scope.current_page);
    //$scope.init_messages_list( )

    Customer.get().$promise.then(function (data) {
      $scope.customer = data;
      return $scope.init_messages_list(1);
    });

    $scope.select_sort_order = function (sort_by) {
      if ($scope.sort_by === sort_by) {
        if ($scope.sort_order === 'asc') {
          $scope.sort_order = 'desc';
        } else {
          $scope.sort_order = 'asc';
        }
      }
      $scope.sort_by = sort_by;
      $scope.current_page = 1;
      return $scope.init_messages_list();
    };

    $scope.select = function (display) {
      $scope.display = display;
      $scope.current_page = 1;
      return $scope.init_messages_list();
    };

    $scope.init_messages_list = function () {
      if ($scope.display === 'inbox') {
        $scope.customer.init_inbox_messages(true, $scope.sort_by, $scope.sort_order, $scope.current_page);
        $scope.messages = $scope.customer.inbox_messages;
      }
      if ($scope.display === 'sent') {
        $scope.customer.init_sent_messages(true, $scope.sort_by, $scope.sort_order, $scope.current_page);
        $scope.messages = $scope.customer.sent_messages;
      }
      if ($scope.display === 'deleted') {
        $scope.customer.init_deleted_messages(true, $scope.sort_by, $scope.sort_order, $scope.current_page);
        return ($scope.messages = $scope.customer.deleted_messages);
      }
    };

    $scope.load_message = function (message) {
      $scope.selected_message = message;
      $scope.selected_message.mark_as_read();
      $scope.message_modal = $uibModal.open({
        templateUrl: '/account/message',
        scope: $scope,
        size: 'lg',
      });
      return $scope.message_modal.result.catch(function (error) {
        if (error === 'backdrop click' || error === 'escape key press' || error === 'cancel') {
          // On ne fait rien c'est un click hors de la modal
        } else {
          throw error;
        }
      });
    };

    $scope.handleSubmit = function (event) {
      if (!$scope.reply.$valid) {
        event.preventDefault();
        return $scope.reply.$setSubmitted();
      }
    };

    $scope.init_new_message = function () {
      if (!$scope.new_message) {
        $scope.new_message = {};
      }
      $scope.new_message.subject = '';
      $scope.new_message.idprivatemessagetype = '';
      $scope.new_message.idparent = '';
      $scope.message = '';
      Privatemessagetype.list(
        {},
        privatemessagetypes => {
          $scope.privatemessagetypes = privatemessagetypes.items;
          $scope.messageTypeNotification = {};
          $scope.privatemessagetypes.forEach(function (privatemessagetype) {
            if (privatemessagetype.msg) {
              $scope.messageTypeNotification[privatemessagetype.id] = privatemessagetype.msg;
            }
          });
        },
        $artpHttpErrorHandler,
      );
    };

    return ($scope.reply_to = function (message) {
      $scope.new_message.subject = 'Re: ' + message.subject;
      $scope.new_message.idprivatemessagetype = message.idprivatemessagetype;
      $scope.new_message.idparent = message.id;
      $scope.message = '';
      $scope.display = 'new';

      return Privatemessagetype.list({}, privatemessagetypes => ($scope.privatemessagetypes = privatemessagetypes.items), $artpHttpErrorHandler);
    });
  },
]);
