/* eslint-disable angular/window-service */
/* eslint-disable angular/document-service */
/* eslint-disable angular/angularelement */
/* eslint-disable angular/definedundefined */
/* eslint-disable angular/log */

if (typeof WebFont !== 'undefined') {
  WebFont.load({ google: { families: ['Tangerine', 'Francois One', 'Droid Sans', 'Droid Serif'] } });
}

// Document ready
$(document).ready(() => {
  $("input[focus='true']").focus();
  $('body').on('hidden.bs.modal', '#base-modal', function () {
    return $(this).removeData('bs.modal');
  });

  // Prevent doubleClick
  const classNameClicked = 'prevent-double-click';
  const selectorsPreventDblClick = ['.artp-pagination a'];
  selectorsPreventDblClick.forEach(selector => {
    document.querySelectorAll(selector).forEach(a => {
      a.addEventListener('click', e => {
        if (!$(a).hasClass(classNameClicked)) {
          $(a).addClass(classNameClicked);
        } else {
          e.preventDefault();
        }
      });
    });
  });

  // Call ajax vers l'incrémentation des views des bloc pub faite en ajax
  // pour ne pas prendre en compte les moteurs
  const ailImg = $('.ail_img');
  if (ailImg.length > 0) {
    let url = '';
    ailImg.each(function () {
      if (url !== '') {
        url += '-';
      }
      url += $(this).attr('data-id');
      return url;
    });

    $.ajax({
      dataType: 'script',
      url: `/ail/view/?ids=${url}`,
    });

    // Observer sur le clic des pubs toujours en ajax pour dégager les moteurs
    return ailImg.on('click', function () {
      if ($(this).is('[data-target]')) {
        if ($(this).attr('data-target') === 'none') {
          window.location.href = `/ail/click/${$(this).attr('data-id')}`;
          return;
        }
        return window.open(`/ail/click/${$(this).attr('data-id')}`, $(this).attr('data-target'));
      }
      return window.open(`/ail/click/${$(this).attr('data-id')}`, '_blank');
    });
  }
});
