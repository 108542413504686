import angular from 'angular';

angular.module('common_app').factory('$artpHttpErrorHandler', [
  '$exceptionHandler',
  '$log',
  'jwt',
  ($exceptionHandler, $log, jwt) =>
    /* eslint-disable func-names */
    function (xhrError) {
      if (xhrError.status === -1 && xhrError.xhrStatus === 'abort') {
        // On peut etre dans un cas de request cancelled, on ne fait rien pour ne pas pourrir les logs d'exception
        $log.debug('request cancelled', xhrError.config);
      } else {
        const userToken = jwt.decodedToken();
        if (userToken.idcustomer) {
          // Si ca n'est pas un vrai client, on ne fait rien, trop d'erreurs venant des moteurs la dessus
          $exceptionHandler(xhrError);
        }
      }
    },
]);
