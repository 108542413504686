import angular from 'angular';

angular.module('common_app').factory('Customercontact', [
  '$resource',
  $resource =>
    $resource(
      '/api/wallet/customercontact/:action/:id',
      {
        format: 'json',
      },
      {
        customer_list: {
          method: 'GET',
          isArray: true,
          params: { action: 'customer_list' },
        },
        show: {
          method: 'GET',
          isArray: false,
          params: { action: 'show' },
        },
        update: {
          method: 'PUT',
          params: { action: 'update' },
        },
        create: {
          method: 'POST',
          params: { action: 'create' },
        },
        delete_from_customers_id: {
          method: 'DELETE',
          params: { action: 'destroy_from_customers_id' },
        },
        delete: {
          method: 'DELETE',
          params: { action: 'destroy' },
        },
      },
    ),
]);
