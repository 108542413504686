/* eslint-disable */

import angular from 'angular';

angular.module('common_app').factory('Catalogue', [
  '$resource',
  $resource => {
    return $resource(
      '/api/artdbweb/catalogue/:action/:id/',
      {
        format: 'json',
      },
      {
        mayer_alpha: {
          method: 'get',
          params: { action: 'mayer_alpha' },
        },
        mayer_categories: {
          method: 'get',
          params: { action: 'mayer_categories' },
        },
        mireur_letters: {
          method: 'get',
          params: { action: 'mireur_letters' },
        },
      },
    );
  },
]);
