import angular from 'angular';

angular.module('common_app').factory('GoogleAnalytics', [
  () => ({
    gtmSubscriptionPhysicproduct(physicproduct, _list, quantity) {
      return {
        name: physicproduct.reference,
        id: physicproduct.idp,
        price: physicproduct.price.eur,
        brand: 'Artprice.com',
        category: physicproduct.theme,
        variant: `period-${physicproduct.nbmonths}`,
        quantity: quantity || 1,
      };
    },

    gtmEstimatePhysicproduct(estimate) {
      return {
        id: estimate.id,
        name: 'Estimate',
        price: estimate.prices.eur,
        category: 'Estimate',
        quantity: estimate.details && estimate.details.nb_units,
        variant: estimate.reference,
        list: 'Estimates',
      };
    },
  }),
]);
