import angular from 'angular';

angular.module('common_app').directive('ngTranslateTo', () => ({
  restrict: 'A',

  scope: {
    callback: '=',
  },

  priority: 1,

  link(scope, $currentElement, attrs) {
    let callback;
    let delay = parseInt(attrs.delay, 10);
    if (!delay) {
      delay = 300;
    }
    let scale = parseFloat(attrs.scale);
    if (!scale) {
      scale = 1;
    }
    let rotate = parseInt(attrs.rotate, 10);
    if (!rotate) {
      rotate = 0;
    }

    if (scope.callback) {
      callback = () => scope.callback();
    } else {
      callback = null;
    }

    // eslint-disable-next-line no-param-reassign
    return ($currentElement[0].onclick = function () {
      const $targetElement = $(attrs.ngTranslateTo);
      if ($targetElement.length > 0) {
        const currentPosition = {
          x: $(window).scrollTop() + $currentElement.offset().left + $currentElement.width() / 2,
          y: $(window).scrollTop() + $currentElement.offset().top + $currentElement.height() / 2,
        };
        const targetPosition = {
          x: $(window).scrollTop() + $targetElement.offset().left,
          y: $(window).scrollTop() + $targetElement.offset().top,
        };
        let $clonedElement = $currentElement.clone();
        $clonedElement.css({
          position: 'absolute',
          'z-index': 10,
          left: $currentElement.position().left,
          top: $currentElement.position().top,
        });
        $clonedElement.insertAfter($currentElement);
        $clonedElement.on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', () => {
          if ($clonedElement) {
            $clonedElement.remove();
            $clonedElement = null;
            if (callback) {
              return callback();
            }
          }
        });
        setTimeout(() =>
          $clonedElement.css({
            transition: `${delay}ms`,
            'transition-timing-function': 'ease-in-out',
            opacity: 0.5,
            transform: `translate(${targetPosition.x - currentPosition.x}px, ${targetPosition.y - currentPosition.y}px) rotate(${rotate}deg) scale(${scale}) `,
          }),
        );
        // security
        return setTimeout(() => {
          if ($clonedElement) {
            $clonedElement.remove();
            $clonedElement = null;
            if (callback) {
              return callback();
            }
          }
        }, delay + 50);
      }
    });
  },
}));
