import angular from 'angular';

// eslint-disable-next-line angular/no-services
angular.module('common_app').directive('exUser', [
  '$http',
  '$q',
  ($http, $q) => ({
    restrict: 'A',
    require: 'ngModel',

    link($scope, element, attributes, ngModelController) {
      return (ngModelController.$asyncValidators.usernameAvailable = username =>
        $http.get(`/api/wallet/customer/exu?q=${username}&id=${$scope.$eval(attributes.idc)}`).then(response => {
          if (response.data === 'exist') {
            return $q.reject('KO');
          }
          return true;
        }));
    },
  }),
]);
