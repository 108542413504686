/* eslint-disable */
import _ from 'lodash';

window.artprice_app.controller('SubscriptionCtrl', [
  '$window',
  '$sce',
  '$http',
  '$compile',
  '$scope',
  '$sessionStorage',
  '$uibModal',
  'Physicproduct',
  'Physicsale',
  'Customer',
  'Address',
  '$timeout',
  '$rootScope',
  '$anchorScroll',
  '$artpModalErrorHandler',
  '$artpHttpErrorHandler',
  'GoogleAnalytics',
  function (
    $window,
    $sce,
    $http,
    $compile,
    $scope,
    $sessionStorage,
    $uibModal,
    Physicproduct,
    Physicsale,
    Customer,
    Address,
    $timeout,
    $rootScope,
    $anchorScroll,
    $artpModalErrorHandler,
    $artpHttpErrorHandler,
    GoogleAnalytics,
  ) {
    $scope.chkRecurrentSubscription = false;
    $scope.isRecurrentSubscription = false;
    $scope.GoogleAnalytics = GoogleAnalytics;
    $scope.customer = {};
    $scope.cgv = false;
    $scope.bill_address = {};
    $scope.ship_address = {};

    $scope.$watch('bill_address.idcountry', function (new_value) {
      if (new_value) {
        $scope.billCurrency = angular.lowercase($scope.bill_address.country.currencyzone.iso3);
        if (new_value === 314) {
          return ($scope.billCurrency = 'cny');
        } else if (new_value === 494) {
          return ($scope.billCurrency = 'gbp');
        } else if ($scope.bill_address.country.idcurrencyzone === 2) {
          return ($scope.billCurrency = 'usd');
        } else {
          return ($scope.billCurrency = 'eur');
        }
      }
    });

    $scope.stopPropagation = function (event) {
      if (event) {
        return event.stopPropagation();
      }
    };

    $scope.initialize_cart = function (idcustomer, show_platform, billCurrency, init_cart_with) {
      if (init_cart_with.from && (init_cart_with.from === 'estimate' || init_cart_with.from === 'shop')) {
        $sessionStorage.subscription_context = { context: init_cart_with.from };
      }

      $scope.subscription_context = $sessionStorage.subscription_context;
      $scope.billCurrency = billCurrency;

      if (init_cart_with && init_cart_with.idphysicproduct) {
        if (!$sessionStorage.subscription_context) {
          $sessionStorage.subscription_context = {};
        }
        $scope.subscription_context = $sessionStorage.subscription_context;
        $scope.subscription_context.idphysicproduct = init_cart_with.idphysicproduct;
        $scope.subscription_context.quantity = init_cart_with.quantity;
        if (!$scope.subscription_context.quantity) {
          $scope.subscription_context.quantity = 1;
        }
        $scope.subscription_context.type = init_cart_with.type;
        $scope.subscription_context.idestimates = init_cart_with.idestimates;
        if (init_cart_with.image === 'true' || init_cart_with.image === 'false') {
          $scope.subscription_context.image = init_cart_with.image;
        }
        if (init_cart_with.promocode) {
          $scope.subscription_context.promocode = init_cart_with.promocode;
        }
      }

      if (!$scope.subscription_context || !$scope.subscription_context.idphysicproduct) {
        if (!$sessionStorage.subscription_context) {
          $sessionStorage.subscription_context = {};
        }
        $scope.back_to_list();
        return;
      }

      Customer.show({ id: idcustomer }, customer => angular.extend($scope.customer, customer), $artpHttpErrorHandler);

      Address.get_customer_addresses(
        {},
        function (addresses) {
          angular.extend($scope.bill_address, addresses.bill);
          return angular.extend($scope.ship_address, addresses.ship);
        },
        $artpHttpErrorHandler,
      );

      $scope.loading = true;
      $scope.show_platform = false;
      $scope.cart = {};
      $scope.total = {
        eur: 0,
        usd: 0,
        cny: 0,
        gbp: 0,
      };
      if (show_platform) {
        $scope.show_platform = true;
        get_platforms();
      }
      return $scope.compute_cart();
    };

    $scope.compute_cart = function () {
      $scope.loading = true;
      $scope.cart = {};
      const params = {
        type: $scope.subscription_context.type,
        promocode: $scope.subscription_context.promocode,
        image: $scope.subscription_context.image,
        'idestimates[]': $scope.subscription_context.idestimates,
      };
      if (Array.isArray($scope.subscription_context.idphysicproduct)) {
        _.each($scope.subscription_context.idphysicproduct, function (value) {
          if (params[`idphysicproducts[${value}]`]) {
            return (params[`idphysicproducts[${value}]`] += $scope.subscription_context.quantity);
          } else {
            return (params[`idphysicproducts[${value}]`] = $scope.subscription_context.quantity);
          }
        });
      } else if (typeof $scope.subscription_context.idphysicproduct === 'string') {
        params[`idphysicproducts[${$scope.subscription_context.idphysicproduct}]`] = $scope.subscription_context.quantity;
      } else {
        _.each($scope.subscription_context.idphysicproduct, (value, key) => (params[`idphysicproducts[${key}]`] = value));
      }

      return Physicproduct.cart(
        params,
        function (data) {
          $scope.total = {
            eur: 0,
            usd: 0,
            gbp: 0,
            cny: 0,
          };
          if (data.cart_unavailable_for_product) {
            $sessionStorage.subscription_context.cart_unavailable_for_product = true;
            return $scope.back_to_list();
          } else {
            angular.extend($scope.cart, data);
            $scope.needsShipAddress = $scope.cart.needs_ship_address;
            for (let main_product of Array.from($scope.cart.main_products)) {
              if (main_product.product.isRecurrentSubscription) {
                $scope.isRecurrentSubscription = true;
              }
              for (let product of Array.from(main_product.component_products)) {
                $scope.total.eur += product.prices.eur * product.quantity;
                $scope.total.usd += product.prices.usd * product.quantity;
                $scope.total.cny += product.prices.cny * product.quantity;
                $scope.total.gbp += product.prices.gbp * product.quantity;
              }
            }
            return ($scope.loading = false);
          }
        },
        function (cart_error) {
          $scope.cart_error = $sce.trustAsHtml(cart_error.data);
          return ($scope.loading = false);
        },
      );
    };

    $scope.get_physicproduct = function (idphysicproduct) {
      if ($scope.physicproducts && $scope.subscription_context.type && $scope.physicproducts[$scope.subscription_context.type] && $scope.physicproducts[$scope.subscription_context.type].products) {
        const themes = $scope.physicproducts[$scope.subscription_context.type].products;
        const theme_grouped_physicproducts = _.map(themes, theme => theme.physicproducts);
        const physicproducts = [].concat.apply([], theme_grouped_physicproducts);
        return _.find(physicproducts, physicproduct => physicproduct.idp === idphysicproduct);
      }
      return null;
    };

    $scope.promotion = function (type) {
      if ($scope.physicproducts && $scope.physicproducts[$scope.subscription_context.type]) {
        if ($scope.physicproducts[$scope.subscription_context.type].promotion_from_promocode && $scope.physicproducts[$scope.subscription_context.type].promotion_from_promocode.status === 1) {
          return $scope.physicproducts[$scope.subscription_context.type].promotion_from_promocode;
        }
        if ($scope.physicproducts[$scope.subscription_context.type].automatically_applied_promotion && $scope.physicproducts[$scope.subscription_context.type].automatically_applied_promotion.status === 1) {
          return $scope.physicproducts[$scope.subscription_context.type].automatically_applied_promotion;
        }
        if ($scope.physicproducts && $scope.physicproducts.displayed_promotion) {
          if (
            $scope.physicproducts.displayed_promotion.url_image_400 &&
            $scope.physicproducts.displayed_promotion.url_image_720 &&
            $scope.physicproducts.displayed_promotion.url_image_940 &&
            $scope.physicproducts.displayed_promotion.url_image_1100
          ) {
            return $scope.physicproducts.displayed_promotion;
          }
        }
      }
    };

    $scope.display_cart = function (physicproduct, gtmList) {
      if (gtmList == null) {
        gtmList = 'Subscriptions';
      }
      if ($sessionStorage.subscription_context.context === 'store' && _.map(physicproduct.module, 1)) {
        $sessionStorage.subscription_context.image = true;
      }

      // On stock dans le context le total des prix utilisé dans le cart reminder
      // et dans le detail du cart sur l'etape suivente
      $sessionStorage.subscription_context.list_prices.eur += physicproduct.prices_unit.eur;
      $sessionStorage.subscription_context.list_prices.usd += physicproduct.prices_unit.usd;
      $sessionStorage.subscription_context.list_prices.cny += physicproduct.prices_unit.cny;
      $sessionStorage.subscription_context.list_prices.gbp += physicproduct.prices_unit.gbp;

      $sessionStorage.subscription_context.promocode = $scope.subscription_context.promocode;

      // On mixe les module du produit selectionné avec ses modules aditionnels
      let product_modules = physicproduct.module;
      product_modules.push(physicproduct.module_additional);
      product_modules = _.flatten(physicproduct);

      $sessionStorage.subscription_context.idphysicproduct = physicproduct.idp;
      $sessionStorage.redirect_url_after_save_customer = `/subscription/cart?idphysicproduct=${$sessionStorage.subscription_context.idphysicproduct}&quantity=${$scope.subscription_context.quantity}&type=${$scope.subscription_context.type}&promocode=${$scope.subscription_context.promocode}`;
      $sessionStorage.gtmProduct = GoogleAnalytics.gtmSubscriptionPhysicproduct(physicproduct, gtmList, $scope.subscription_context.quantity);

      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [$sessionStorage.gtmProduct],
          },
        },
      });

      return (window.location = $sessionStorage.redirect_url_after_save_customer);
    };

    $scope.back_to_list = function () {
      window.location = '/subscription';
    };

    $scope.validate_cart = function () {
      $scope.errors = [];
      if (!$scope.cgv) {
        $scope.errors.push('cgv');
      }
      if ($scope.isRecurrentSubscription && !$scope.chkRecurrentSubscription) {
        $scope.errors.push('recurrentSubscription');
      }

      if ($scope.bill_address.id === null) {
        $scope.errors.push('address');
      } else {
        if (
          $scope.bill_address.idcountry === null ||
          $scope.bill_address.line1 === null ||
          $scope.bill_address.line1.trim() === '' ||
          $scope.bill_address.postcode === null ||
          $scope.bill_address.postcode.trim() === '' ||
          $scope.bill_address.city === null ||
          $scope.bill_address.city.trim() === ''
        ) {
          $scope.errors.push('bill_address_incomplete');
        } else if ($scope.bill_address.phone === null || $scope.bill_address.phone.trim() === '') {
          $scope.errors.push('bill_phone');
        }
      }

      if ($scope.ship_address.id && $scope.needsShipAddress) {
        if (
          $scope.ship_address.idcountry === null ||
          $scope.ship_address.line1 === null ||
          $scope.ship_address.line1.trim() === '' ||
          $scope.ship_address.postcode === null ||
          $scope.ship_address.postcode.trim() === '' ||
          $scope.ship_address.city === null ||
          $scope.ship_address.city.trim() === ''
        ) {
          $scope.errors.push('ship_address_incomplete');
        } else if ($scope.ship_address.phone === null || $scope.ship_address.phone.trim() === '') {
          $scope.errors.push('ship_phone');
        }
      }

      if ($scope.errors.length > 0) {
        return;
      }

      Customer.acceptPolicies({}, _.noop, $artpHttpErrorHandler);
      $scope.loading = true;

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: '',
            },
            products: [
              {
                ...$sessionStorage.gtmProduct,
                quantity: $sessionStorage.subscription_context.quantity,
              },
            ],
          },
        },
      });

      // if ($scope.subscription_context.idestimates || Array.isArray($scope.subscription_context.idphysicproduct) || isNaN(parseInt($scope.subscription_context.idphysicproduct, 10))) {
      //   return get_platforms();
      // } else {
      const url = new URL(window.location.href);
      url.searchParams.set('promocode', $scope.subscription_context.promocode || '');
      url.pathname = '/payments/platforms';
      window.location = url.href;
      // }
    };

    var get_platforms = function () {
      const params = {
        type: $scope.subscription_context.type,
        promocode: $scope.subscription_context.promocode,
        image: $scope.subscription_context.image,
        'idestimates[]': $scope.subscription_context.idestimates,
      };
      if (Array.isArray($scope.subscription_context.idphysicproduct)) {
        _.each($scope.subscription_context.idphysicproduct, function (value) {
          if (params['idphysicproducts[' + value + ']']) {
            return (params['idphysicproducts[' + value + ']'] += $scope.subscription_context.quantity);
          } else {
            return (params['idphysicproducts[' + value + ']'] = $scope.subscription_context.quantity);
          }
        });
      } else if (typeof $scope.subscription_context.idphysicproduct === 'string') {
        params['idphysicproducts[' + $scope.subscription_context.idphysicproduct + ']'] = $scope.subscription_context.quantity;
      } else {
        _.each($scope.subscription_context.idphysicproduct, (value, key) => (params['idphysicproducts[' + key + ']'] = value));
      }

      Physicproduct.platforms(
        params,
        function (data) {
          $window.dispatchEvent(new CustomEvent('getPlatforms', { detail: data }));

          $scope.online_platforms = data.platforms;
          $scope.loading = false;
          $scope.show_platform = true;
          $scope.offline_allowed = data.offline_allowed;
          $scope.idpayment = data.idpayment;
          $scope.idphysicsale = data.idphysicsale;
          return ($scope.idphysicsalegroupmenttype = data.idphysicsalegroupmenttype);
        },
        $artpHttpErrorHandler,
      );
    };

    return ($scope.physicproducts_for_theme = (theme, currency_iso3) => theme.physicproducts);
  },
]);
